import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box } from '@mui/material';

const StyledRating = styled(Rating)(({ theme }) => ({
  width: '100%', // Ensures the component takes full width
  display: 'flex', // Allows flexbox layout
  justifyContent: 'space-between', // Distributes stars evenly
  '& .MuiRating-icon': {
    flex: '1', // Ensures equal spacing for each icon
    display: 'flex', // Centers each icon within its area
    justifyContent: 'center', // Centers the icon within its space
  },
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FF203A' }} />,
    label: 'Extremely Satisfied',
  },
  2: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FF543B' }} />,
    label: 'Very Satisfied',
  },
  3: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FF8139' }} />,
    label: 'Mostly Satisfied',
  },
  4: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FFA638' }} />,
    label: 'Satisfied',
  },
  5: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FFC61F' }} />,
    label: 'Slightly Satisfied',
  },
  6: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#E3CB24' }} />,
    label: 'Neutral',
  },
  7: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#B7D229' }} />,
    label: 'Slightly Dissatisfied',
  },
  8: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#8CD733' }} />,
    label: 'Dissatisfied',
  },
  9: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#08CA0A' }} />,
    label: 'Mostly Dissatisfied',
  },
  10: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#00964E' }} />,
    label: 'Very Dissatisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function StarRating({
  handleAnswerChange,
  questionId,
  masseuseId,
}) {
  return (
    <div style={{ margin: '0px 0px 5px 5px' }}>
      <StyledRating
        defaultValue={0}
        max={10}
        IconContainerComponent={IconContainer}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly={false}
        sx={{
          fontSize: {
            xs: '1.65rem',
            sm: '2.5rem',
            md: '3.8rem',
          },
        }}
        onChange={(event, newValue) => {
          handleAnswerChange(questionId, newValue, masseuseId);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: '300',
          marginTop: '5px',
          width: '100%',
          fontSize: {
            xs: '.6rem',
            sm: '1rem',
          },
        }}
      >
        {[
          'Very Dissatisfied',
          'Dissatisfied',
          'Neutral',
          'Satisfied',
          'Very Satisfied',
        ].map((label) => (
          <div
            key={label}
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'left', // Center the text horizontally
              textAlign: 'left', // Ensures consistent alignment
            }}
          >
            <p>{label}</p>
          </div>
        ))}
      </Box>
    </div>
  );
}
