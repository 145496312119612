import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setAlert } from '../actions/alertActions';
import { rateMasseuseEmail } from '../actions/masseuseActions';
import Message from '../components/Message';
import StarRating from '../components/StarRating';
import { surveyQuestions } from '../data/surveyQuestions';
import logo_dynasty from '../img/logo_dynasty.png';

const EmailSurveyScreen = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const masseuse1_id = queryParams.get('masseuse1_id');
  const masseuse1_name = queryParams.get('masseuse1_name');

  const masseuse2_id = queryParams.get('masseuse2_id');
  const masseuse2_name = queryParams.get('masseuse2_name');

  const masseuse3_id = queryParams.get('masseuse3_id');
  const masseuse3_name = queryParams.get('masseuse3_name');

  const masseuse4_id = queryParams.get('masseuse4_id');
  const masseuse4_name = queryParams.get('masseuse4_name');

  const sessionId = queryParams.get('session');

  const token = queryParams.get('token');

  const masseuses = [];

  if (masseuse1_id) {
    masseuses.push({
      masseuse_id: masseuse1_id,
      masseuse_name: masseuse1_name,
    });
  }

  if (masseuse2_id) {
    masseuses.push({
      masseuse_id: masseuse2_id,
      masseuse_name: masseuse2_name,
    });
  }

  if (masseuse3_id) {
    masseuses.push({
      masseuse_id: masseuse3_id,
      masseuse_name: masseuse3_name,
    });
  }

  if (masseuse4_id) {
    masseuses.push({
      masseuse_id: masseuse4_id,
      masseuse_name: masseuse4_name,
    });
  }

  const [masseuseRatings, setMasseuseRatings] = useState([]);
  const [answers, setAnswers] = useState([]);

  function handleMasseuseRatingChange(questionId, newValue, masseuseId) {
    // Check if the newStateValue already exists in the current state array
    const existingIndex = masseuseRatings.findIndex(
      (obj) => obj.masseuse === masseuseId
    );

    if (existingIndex !== -1) {
      // If the value already exists, update it
      const newArray = [...masseuseRatings];
      newArray[existingIndex] = {
        question: questionId,
        masseuse: masseuseId,
        value: newValue,
      };
      setMasseuseRatings(newArray);
      // return newArray;
    } else {
      // If the value does not exist, add it to the end of the array
      setMasseuseRatings([
        ...masseuseRatings,
        { question: questionId, masseuse: masseuseId, value: newValue },
      ]);
      // return [...currentStateArray, newStateValue];
    }
  }

  function handleAnswerChange(questionId, value) {
    // Check if the newStateValue already exists in the current state array
    const existingIndex = answers.findIndex(
      (obj) => obj.question === questionId
    );

    if (existingIndex !== -1) {
      // If the value already exists, update it
      const newArray = [...answers];
      newArray[existingIndex] = { question: questionId, value: value };
      setAnswers(newArray);
      // return newArray;
    } else {
      // If the value does not exist, add it to the end of the array
      setAnswers([...answers, { question: questionId, value: value }]);
      // return [...currentStateArray, newStateValue];
    }
  }

  function getValueByQuestion(question) {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].question === question) {
        return answers[i].value;
      }
    }
    return null; // Return null if the question is not found in the array
  }

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const masseuseRating = useSelector((state) => state.masseuseRating);
  const { success: successSurveySubmit, loading: loadingSurveySubmit } =
    masseuseRating;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    let timer = 1000;
    if (successSurveySubmit) {
      setTimeout(() => {
        navigate('/survey/thank-you/');
      }, timer);
    }
  }, [successSurveySubmit]);

  const handleSubmit = () => {
    const missingMasseuseRatings = masseuseRatings.filter(
      (answer) => answer.value === 0 || answer.value === null
    );

    if (
      missingMasseuseRatings.length > 0 ||
      masseuseRatings.length !== masseuses.length
    ) {
      dispatch(
        setAlert('Please rate all the masseuses before submitting', 'error')
      );
      return;
    }
    const missingAnswers = answers.filter(
      (answer) => answer.value === 0 || answer.value === null
    );

    console.log(missingAnswers);
    console.log(answers);

    if (
      answers.length < surveyQuestions.length - 1 ||
      missingAnswers.length > 0
    ) {
      dispatch(
        setAlert('Please answer all questions before submitting', 'error')
      );
      return;
    } else {
      forEach(masseuseRatings, (rating) => {
        dispatch(
          rateMasseuseEmail({
            token: token,
            session_id: sessionId,
            payload: {
              answers: [
                { question: rating.question, value: rating.value },
                ...answers,
              ],
              masseuse: rating.masseuse,
            },
          })
        );
      });
    }
  };

  const YesNoButtons = [
    <Button key="1">Yes</Button>,
    <Button key="2">No</Button>,
  ];

  return (
    <div
      style={{
        backgroundColor: 'var(--primary-color)',
        minHeight: '100vh',
      }}
    >
      <Container
        sx={{
          height: '100%',
          paddingTop: userInfo ? '100px' : '20px',
          paddingBottom: '50px',
          marginBottom: userInfo ? '-50px' : '0',
          paddingLeft: {
            xs: '0',
            sm: '2rem',
          },
          paddingRight: {
            xs: '0',
            sm: '2rem',
          },
        }}
      >
        <Card
          variant="outlined"
          sx={{
            backgroundColor: `rgba(245, 243, 239, 0.9)`,
            border: 'none',
            marginBottom: '40px',
            xs: {
              padding: '0',
            },
            sm: {
              padding: '1.5rem',
            },
          }}
        >
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo_dynasty}
              alt=""
              style={{ width: '250px', marginBottom: '20px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: '0.8rem',
                  sm: '1.2rem',
                },
                textAlign: 'center',
              }}
            >
              We hope you enjoyed your recent session at{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                Club Dynasty
              </span>
              . Your feedback is incredibly important to us, and we’d love to
              hear about your experience with{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                {masseuse1_name}
                {masseuse2_name && `, ${masseuse2_name}`}
                {masseuse3_name && `, ${masseuse3_name}`}
                {masseuse4_name && `, ${masseuse4_name}`}
              </span>{' '}
              and our spa. <br />
              <br />
              Please take a moment to complete this short survey, it helps us
              improve and ensures we continue to provide the best service
              possible. You are the reason we do what we do!
            </Typography>
          </CardContent>
        </Card>

        {masseuses.map((masseuse, index) => (
          <Card
            key={index}
            masseuse={masseuse}
            variant="outlined"
            sx={{
              backgroundColor: `rgba(245, 243, 239, 0.9)`,
              border: 'none',
              marginBottom: '40px',
              padding: { xs: '0.5rem', sm: '1.5rem' },
            }}
          >
            <Box sx={{ padding: '10px' }}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: '500',
                  lineHeight: '1.2',
                  color: 'text.secondary',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '2rem',
                  },
                }}
              >
                {surveyQuestions[0].statement}{' '}
                <span
                  style={{
                    color: 'var(--primary-color)',
                    fontWeight: 'bold',
                  }}
                >
                  {masseuse.masseuse_name.toUpperCase()}
                </span>
              </Typography>
            </Box>{' '}
            <StarRating
              handleAnswerChange={handleMasseuseRatingChange}
              questionId={surveyQuestions[0].id}
              masseuseId={masseuse.masseuse_id}
            />
          </Card>
        ))}

        {surveyQuestions.map(
          (question) =>
            question.id !== 1 && (
              <Card
                key={question.id}
                variant="outlined"
                sx={{
                  backgroundColor: `rgba(245, 243, 239, 0.9)`,
                  border: 'none',
                  marginBottom: '40px',
                  padding: { xs: '0.5rem', sm: '1.5rem' },
                }}
              >
                <Box sx={{ padding: '10px' }}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: '500',
                      lineHeight: '1.2',
                      color: 'text.secondary',
                      fontSize: {
                        xs: '1rem',
                        sm: '1.8rem',
                      },
                    }}
                  >
                    {question.statement}
                  </Typography>
                </Box>{' '}
                {/* Survey changes are done. Each question now has a 'kind' field that denotes the type of question. There are 5 types of questions allowed - rating 1-10, 3 choices 1-3, 5 choices: 1-5, Yes/No: 1-2, text: text. The wording for choices can be anything that the UI needs - only the value is stored (integer).  */}
                {question.kind === 1 ? (
                  <StarRating
                    handleAnswerChange={handleAnswerChange}
                    questionId={question.id}
                  />
                ) : question.kind === 2 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      '& > *': {
                        m: 1,
                      },
                    }}
                  >
                    <ButtonGroup size={isMobile ? 'small' : 'large'}>
                      <Button
                        key="1"
                        onClick={() => handleAnswerChange(question.id, 1)}
                        variant={
                          getValueByQuestion(question.id) === 1
                            ? 'contained'
                            : 'outlined'
                        }
                        disableElevation
                      >
                        Yes
                      </Button>
                      <Button
                        key="2"
                        onClick={() => handleAnswerChange(question.id, 2)}
                        variant={
                          getValueByQuestion(question.id) === 2
                            ? 'contained'
                            : 'outlined'
                        }
                        disableElevation
                      >
                        No
                      </Button>
                    </ButtonGroup>
                  </Box>
                ) : question.kind === 3 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      '& > *': {
                        m: 1,
                      },
                    }}
                  >
                    <ButtonGroup size={isMobile ? 'small' : 'large'}>
                      <Button
                        key="1"
                        onClick={() => handleAnswerChange(question.id, 1)}
                        variant={
                          getValueByQuestion(question.id) === 1
                            ? 'contained'
                            : 'outlined'
                        }
                        disableElevation
                      >
                        1-5 KM
                      </Button>
                      <Button
                        key="2"
                        onClick={() => handleAnswerChange(question.id, 2)}
                        variant={
                          getValueByQuestion(question.id) === 2
                            ? 'contained'
                            : 'outlined'
                        }
                        disableElevation
                      >
                        6-20 KM
                      </Button>
                      <Button
                        key="3"
                        onClick={() => handleAnswerChange(question.id, 3)}
                        variant={
                          getValueByQuestion(question.id) === 3
                            ? 'contained'
                            : 'outlined'
                        }
                        disableElevation
                      >
                        Over 20 KM
                      </Button>
                    </ButtonGroup>
                  </Box>
                ) : question.kind === 4 ? (
                  <p>Kind 4</p>
                ) : question.kind === 5 ? (
                  <p>Kind 5</p>
                ) : null}
              </Card>
            )
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '40px',
            marginBottom: '40px',
            gap: '20px',
          }}
        >
          <Link to={'/login'}>
            <Button variant="outlined" size="large" color="white">
              Cancel
            </Button>
          </Link>
          <Button
            size="large"
            variant="contained"
            disableElevation
            disabled={loadingSurveySubmit}
            onClick={handleSubmit}
            color="white"
          >
            {loadingSurveySubmit ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
        {msg?.length > 0 && <Message />}
      </Container>
    </div>
  );
};

export default EmailSurveyScreen;
