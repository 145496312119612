import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URI } from '../constants/appConstants';

function useSessionReceiptDetails(sessionId, token) {
  const fetchSessionReceiptDetails = async () => {
    const endpoint = `${API_URI}/sessions/${sessionId}/receipt/`;
    const headers = {
      Authorization: token,
    };

    try {
      const response = await axios.get(endpoint, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Session receipt details not found');
    }
  };

  const queryKey = ['sessionReceiptDetails', sessionId];

  const { data, error, isLoading } = useQuery(
    queryKey,
    fetchSessionReceiptDetails,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    sessionReceiptDetails: data,
    isLoading,
    error,
  };
}

export default useSessionReceiptDetails;
