import FavoriteIcon from '@mui/icons-material/Favorite';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StarIcon from '@mui/icons-material/Star';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Rating,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { createFeedFavorite, deleteFeedFavorite } from '../actions/feedActions';
import { getMasseuseDetails } from '../actions/masseuseActions';
import FeedMasseuse from '../components/FeedMasseuse';
import FeedbackSlider from '../components/FeedbackSlider';
import Gallery from '../components/Gallery';
import Message from '../components/Message';
import VideoCarousel from '../components/VideoCarousel';
import Sensual90 from '@mui/icons-material/HistoryToggleOff';
import { REMOVE_ALERT } from '../constants/alertConstants';

const MasseuseDetailsScreen = () => {
  const dispatch = useDispatch();
  const [availableToday, setAvailableToday] = useState(false);
  const [bonusImages, setBonusImages] = useState([]);
  const [bonusVideos, setBonusVideos] = useState([]);

  let { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');

  const masseuseDetails = useSelector((state) => state.masseuseDetails);
  const { masseuse, loading: loadingDetails } = masseuseDetails;

  const feedFavoriteCreate = useSelector((state) => state.feedFavoriteCreate);
  const { favoriteCreate, loading: loadingFeedFavoriteCreate } =
    feedFavoriteCreate;

  const feedFavoriteDelete = useSelector((state) => state.feedFavoriteDelete);
  const { favoriteDelete, loading: loadingFeedFavoriteDelete } =
    feedFavoriteDelete;

  const [masseuseFavorite, setMasseuseFavorite] = useState(null);

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getMasseuseDetails(id));
    dispatch({ type: REMOVE_ALERT });
  }, []);

  useEffect(() => {
    if (masseuse) {
      setMasseuseFavorite(masseuse.favorite);
    }
  }, [masseuse]);

  // const {
  //   appointments,
  //   isLoading: isAppointmentsLoading,
  //   error: appointmentsError,
  // } = useAppointments(userInfo.token, id);

  // function getAppointmentsByMasseuseId(appointments, masseuseId) {
  //   return appointments.filter((appointment) =>
  //     appointment.masseuses.some((masseuse) => masseuse.id === masseuseId)
  //   );
  // }

  // const filteredAppointments = getAppointmentsByMasseuseId(
  //   appointments,
  //   Number(id)
  // );

  useEffect(() => {
    if (masseuse) {
      for (let i = 0; i < masseuse.schedule.length; i++) {
        if (
          format(new Date(masseuse.schedule[i].start), 'eeee') ===
          format(new Date(), 'eeee')
        ) {
          setAvailableToday(true);
          break;
        }
        setAvailableToday(false);
      }

      if (masseuse.ma_images && masseuse.ma_images.length > 0) {
        let { ma_images } = masseuse;
        let bonus_images = [];
        let bonus_videos = [];

        for (let i = 0; i < ma_images.length; i++) {
          const splittedArray = ma_images[i].split('.');
          if (splittedArray[splittedArray.length - 1] === 'webp') {
            bonus_images.push(ma_images[i]);
          } else if (splittedArray[splittedArray.length - 1] === 'mp4') {
            bonus_videos.push(ma_images[i]);
          }
        }

        setBonusImages(...bonusImages, bonus_images);
        setBonusVideos(...bonusVideos, bonus_videos);
      }
    }
  }, [masseuse]);

  const favoriteMasseuse = (masseuse) => {
    if (!masseuseFavorite) {
      dispatch(createFeedFavorite(masseuse, setMasseuseFavorite));
    } else if (masseuseFavorite) {
      dispatch(
        deleteFeedFavorite(
          masseuseFavorite.id,
          setMasseuseFavorite,
          masseuse,
          false
        )
      );
    }
  };

  return (
    <Container style={{ marginTop: '100px', maxWidth: '1006px' }}>
      {loadingDetails ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined">
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Skeleton variant="circular" width={150} height={150} />
                  <Skeleton height={50} width="50%" />
                  <Skeleton height={50} width="25%" />
                </div>

                <Skeleton variant="rectangular" height={100} />
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="75%" />
                <Skeleton height={50} width="50%" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card
              variant="outlined"
              style={{ width: '100%', marginTop: '15px' }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={300} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                    <Skeleton height={50} width="100%" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : masseuse ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card variant="outlined">
              <CardContent>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Avatar
                    alt={masseuse?.name}
                    src={masseuse?.image}
                    sx={{ width: 150, height: 150 }}
                    style={{ textAlign: 'center' }}
                  />
                </div>

                <Typography
                  variant="h4"
                  component="div"
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#c3892b',
                    marginBottom: '10px',
                  }}
                >
                  {masseuse.name}{' '}
                  {masseuse.new && (
                    <Tooltip title="New masseuse">
                      <img
                        src="https://clubdynasty.ca/wp-content/uploads/2022/01/new-purple-animation.gif"
                        width="25"
                        alt="New Masseuse"
                      ></img>
                    </Tooltip>
                  )}{' '}
                  {masseuse.foot_massage && (
                    <Tooltip title="Available for foot massages">
                      <img
                        src="https://clubdynasty.ca/wp-content/uploads/2022/11/foot_massage.gif"
                        width="25"
                        alt="Foot Massage"
                      ></img>
                    </Tooltip>
                  )}
                  {masseuse.allow_lingam && (
                    <Tooltip title="Available for lingam massages">
                      <img
                        src="https://clubdynasty.ca/wp-content/uploads/2024/07/lingam_icon.gif"
                        width="30"
                        alt="Foot Massage"
                      ></img>
                    </Tooltip>
                  )}
                  {masseuse.sensual90 && (
                    <Tooltip title="Available for 90-minute massages">
                      <Sensual90 sx={{ color: 'rgb(255, 140, 26)' }} />
                    </Tooltip>
                  )}
                </Typography>
                {masseuse.sessionRating && (
                  <Box mb={2}>
                    <Box
                      sx={{
                        width: 200,
                        display: 'flex',
                      }}
                    >
                      <Rating
                        name="text-feedback"
                        value={masseuse.survey_average}
                        readOnly
                        precision={0.5}
                        max={10}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                        size="small"
                      />
                      <Box sx={{ ml: 1, fontSize: '14px' }}>
                        (
                        {Number.isInteger(masseuse.survey_average)
                          ? masseuse.survey_average
                          : masseuse.survey_average.toFixed(2)}
                        /10)
                      </Box>
                    </Box>
                  </Box>
                )}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '25px',
                  }}
                >
                  {availableToday ? (
                    <Chip
                      label="Available Today"
                      color="success"
                      variant="outlined"
                      size="small"
                      icon={<FiberManualRecordIcon />}
                    />
                  ) : (
                    <Chip
                      label="Unavailable Today"
                      variant="outlined"
                      size="small"
                      icon={<FiberManualRecordIcon />}
                    />
                  )}
                  <Tooltip
                    title={`Favorite ${masseuse.name} to receive notifications whenever she posts or is available`}
                  >
                    <IconButton
                      aria-label="settings"
                      onClick={() => favoriteMasseuse(masseuse)}
                      disabled={
                        loadingFeedFavoriteCreate || loadingFeedFavoriteDelete
                      }
                    >
                      <FavoriteIcon
                        color={masseuseFavorite ? 'error' : 'default'}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
                <Divider />

                <Typography
                  variant="body2"
                  style={{
                    textAlign: 'justify',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  {masseuse.bio}
                </Typography>
                <div style={{ marginTop: '15px', marginBottom: '10px' }}>
                  <Divider />
                </div>
                <Typography
                  variant="h6"
                  component="div"
                  style={{
                    color: '#c3892b',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Interests
                </Typography>
                <div
                  style={{
                    marginBottom: '20px',
                  }}
                >
                  {masseuse.interests && masseuse.interests.length > 0 ? (
                    masseuse.interests
                      .split(',')
                      .map((m, i) => (
                        <Chip
                          color="primary"
                          label={m}
                          variant="outlined"
                          style={{ marginRight: '5px', marginBottom: '5px' }}
                          key={i}
                        />
                      ))
                  ) : (
                    <Chip
                      color="primary"
                      label={'N/A'}
                      variant="outlined"
                      style={{ marginRight: '5px', marginBottom: '5px' }}
                    />
                  )}
                </div>
                <div style={{ marginTop: '15px', marginBottom: '10px' }}>
                  <Divider />
                </div>
                <Typography
                  variant="h6"
                  component="div"
                  style={{
                    color: '#c3892b',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Availabilities
                </Typography>
                <List
                  component="nav"
                  style={{ fontSize: '14px', marginBottom: '20px' }}
                >
                  {masseuse.schedule.map((s, i) => (
                    <ListItem
                      divider={
                        i + 1 === masseuse.schedule.length ? false : true
                      }
                      key={i}
                    >
                      {format(new Date(s.start), 'eeee') ===
                      format(new Date(), 'eeee')
                        ? 'Today'
                        : format(new Date(s.start), 'eeee')}{' '}
                      - {format(new Date(s.start), 'p')}-
                      {format(new Date(s.end), 'p')}
                    </ListItem>
                  ))}
                </List>
                {/*<Typography
                  variant="h6"
                  component="div"
                  style={{
                    color: '#c3892b',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Scheduled Appointments
                </Typography>
                 <List
                  component="nav"
                  style={{ fontSize: '14px', marginBottom: '20px' }}
                >
                  {!isAppointmentsLoading &&
                  appointments &&
                  filteredAppointments.length === 0 ? (
                    <p>No scheduled appointment with {masseuse.name}</p>
                  ) : (
                    filteredAppointments.map((s, i) => (
                      <ListItem
                        divider={
                          i + 1 === masseuse.schedule.length ? false : true
                        }
                        key={i}
                      >
                        {format(new Date(s.start_time), 'eeee') ===
                        format(new Date(), 'eeee')
                          ? 'Today'
                          : format(new Date(s.start_time), 'eeee')}{' '}
                        - {format(new Date(s.start_time), 'p')}-
                        {format(new Date(s.end_time), 'p')}
                      </ListItem>
                    ))
                  )}
                </List> */}
              </CardContent>
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <Link to={source ? `/${source}` : `/`}>
                  <Button size="small" variant="contained" disableElevation>
                    Go Back
                  </Button>
                </Link>

                {/* <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={handleBookingPopupOpen}
                  disabled={appointmentAvailabilities?.length === 0}
                >
                  Book Appointment
                </Button> */}
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Gallery
              images={[masseuse.image, ...masseuse.images]}
              thumbnails={[masseuse.image, ...masseuse.thumbnails]}
            />

            <Card
              variant="outlined"
              style={{ width: '100%', marginTop: '15px' }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{
                        color: '#c3892b',
                        fontWeight: 'bold',
                        marginBottom: '-20px',
                      }}
                    >
                      Get to know her
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <List
                      component="nav"
                      style={{ fontSize: '14px', marginBottom: '-20px' }}
                    >
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Age:</span>&nbsp;
                        {masseuse.age}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Background:</span>
                        &nbsp;
                        {masseuse.background}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Language:</span>
                        &nbsp;
                        {masseuse.language}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Technique:</span>
                        &nbsp;
                        {masseuse.technique}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Tattoos:</span>
                        &nbsp;
                        {masseuse.tattoos ? 'Yes' : 'No'}
                      </ListItem>
                      <ListItem>
                        <span style={{ color: '#c3892b' }}>Bust:</span>
                        &nbsp;
                        {masseuse.bust_enhanced ? 'Enhanced' : 'Natural'}
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <List
                      component="nav"
                      style={{ fontSize: '14px', marginBottom: '-20px' }}
                    >
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Measurements:</span>
                        &nbsp;
                        {masseuse.measurements}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Hair Color:</span>
                        &nbsp;
                        {masseuse.hair_color}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Foot Massage:</span>
                        &nbsp;
                        {masseuse.foot_massage ? 'Yes' : 'No'}
                      </ListItem>
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Clientele:</span>
                        &nbsp;
                        {masseuse.services}
                      </ListItem>{' '}
                      <ListItem divider>
                        <span style={{ color: '#c3892b' }}>Height:</span>
                        &nbsp;
                        {masseuse.height}
                      </ListItem>
                      <ListItem>
                        <span style={{ color: '#c3892b' }}>New:</span>
                        &nbsp;
                        {masseuse.new ? 'Yes' : 'No'}
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <FeedbackSlider masseuseId={masseuse.id} />
                  </Grid>
                  {/* <Grid item xs={12}>
                    {userInfo && (
                      <AppointmentBookingAccordion
                        masseuseId={id}
                        token={userInfo.token}
                      />
                    )}
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>

            {(bonusImages.length > 0 || bonusVideos.length > 0) && (
              <Card
                variant="outlined"
                style={{
                  marginTop: '15px',
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{
                      color: '#c3892b',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                    }}
                  >
                    Bonus Content
                  </Typography>
                  {bonusImages.length > 0 && (
                    <>
                      <Gallery images={bonusImages} thumbnails={bonusImages} />{' '}
                      <br />
                    </>
                  )}

                  {bonusVideos.length > 0 && (
                    <VideoCarousel videos={bonusVideos} />
                  )}
                </CardContent>
              </Card>
            )}
            <FeedMasseuse masseuseId={id} />
          </Grid>
        </Grid>
      ) : null}
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default MasseuseDetailsScreen;
