import BlockIcon from '@mui/icons-material/Block';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import { Avatar, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import ConversationInterface from './Conversation/ConversationInterface';
import ConversationInfo from './ConversationInfo';
import ConversationList from './ConversationList';
import ConversationOnline from './ConversationOnline';
import SearchField from './SearchField';

const drawerWidth = 300;

const infoDrawerWidth = 300;

function ConversationsSource(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const [mobileInfoOpen, setMobileInfoOpen] = React.useState(false);
  const [isInfoClosing, setIsInfoClosing] = React.useState(false);

  const [selectedConversation, setSelectedConversation] = React.useState(null);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleInfoDrawerClose = () => {
    setIsInfoClosing(true);
    setMobileInfoOpen(false);
  };

  const handleInfoDrawerTransitionEnd = () => {
    setIsInfoClosing(false);
  };

  const handleInfoDrawerToggle = () => {
    if (!isClosing) {
      setMobileInfoOpen(!mobileOpen);
    }
  };

  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const drawer = (
    <div>
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          width: '100%',
        }}
      >
        <Typography
          variant="p"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: 'left',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Chat with the girls
        </Typography>
      </Toolbar>
      <Divider />
      <SearchField />
      <Divider />
      <Typography color={'primary'} fontWeight={'bold'} m={1}>
        Active now
      </Typography>
      <ConversationOnline />
      <Divider />
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        fullWidth
        size="small"
      >
        <ToggleButton
          value="chats"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MessageIcon />
          Chats
        </ToggleButton>
        <ToggleButton
          value="favorites"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FavoriteIcon />
          Favorites
        </ToggleButton>
        <ToggleButton
          value="blocks"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BlockIcon />
          <p>Blocks</p>
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider />
      <Typography color={'primary'} fontWeight={'bold'} m={1}>
        Recent Chats
      </Typography>
      <ConversationList
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
      />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        position: 'relative',
      }}
    >
      <CssBaseline />
      <AppBar
        position="absolute"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Left Icon Button */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          {/* Centered Typography */}
          <Box>
            <Typography
              variant="p"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: 'left',
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              {selectedConversation?.name}
            </Typography>
          </Box>

          {/* Right Icon Button */}
          <IconButton
            color="inherit"
            aria-label="open info drawer"
            edge="end"
            onClick={handleInfoDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            {selectedConversation ? (
              <Avatar
                alt={selectedConversation?.name}
                src={selectedConversation?.image}
              />
            ) : (
              <MenuIcon />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          position: 'relative',
          height: '95%',
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          PaperProps={{
            style: {
              position: 'absolute',
              top: '62px',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          PaperProps={{
            style: {
              position: 'absolute',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          pr: 0,
          pt: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
        }}
      >
        <Box
          sx={{
            flex: {
              sm: 2,
            },
            flexGrow: {
              xs: 1,
            },
          }}
        >
          <Toolbar />
          <ConversationInterface />
        </Box>

        <Box
          sx={{
            flex: 1,
          }}
        >
          <ConversationInfo
            mobileInfoOpen={mobileInfoOpen}
            handleInfoDrawerClose={handleInfoDrawerClose}
            handleInfoDrawerTransitionEnd={handleInfoDrawerTransitionEnd}
            selectedConversation={selectedConversation}
          />
        </Box>
      </Box>
    </Box>
  );
}

ConversationsSource.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ConversationsSource;
