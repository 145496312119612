export const surveyQuestions = [
  {
    id: 1,
    statement: 'Please rate your overall experience with',
    position: 1,
    kind: 1,
    purpose: 1,
  },
  {
    id: 2,
    statement:
      'How would you rate your overall experience today at Club Dynasty spa?',
    position: 2,
    kind: 1,
    purpose: 0,
  },
  {
    id: 3,
    statement:
      'Did the masseuse meet your expectations on looks and sensuality?',
    position: 3,
    kind: 1,
    purpose: 0,
  },
  {
    id: 4,
    statement:
      'How would you rate your interactions with other staff around the spa?',
    position: 4,
    kind: 1,
    purpose: 0,
  },
  {
    id: 5,
    statement:
      'Which distance properly represents how far you drive to come to Club Dynasty?',
    position: 5,
    kind: 3,
    purpose: 0,
  },
  {
    id: 6,
    statement:
      'If there was a second location in Toronto, would you likely attend Club Dynasty more often?',
    position: 6,
    kind: 2,
    purpose: 0,
  },
  {
    id: 7,
    statement: 'How likely are you to repeat with the same masseuse again?',
    position: 7,
    kind: 1,
    purpose: 0,
  },
  {
    id: 8,
    statement: 'How would you rate the overall cleanliness of the spa?',
    position: 8,
    kind: 1,
    purpose: 0,
  },
  {
    id: 9,
    statement: 'Do our prices properly reflect the value delivered?',
    position: 9,
    kind: 1,
    purpose: 0,
  },
];
