import React from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Tooltip,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {
  differenceInHours,
  format,
  formatDistanceToNow,
  parseISO,
  sub,
} from 'date-fns';

// Chat Bubble Component
const ChatBubble = ({ message, isUser, timestamp }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: isUser ? 'flex-end' : 'flex-start',
      marginY: 1,
    }}
  >
    {!isUser && <Avatar sx={{ marginRight: 1 }}>V</Avatar>}
    <Box
      sx={{
        maxWidth: '60%',
        backgroundColor: isUser ? '#f0f0f0' : '#D9A566',
        color: isUser ? '#000' : '#fff',
        padding: '8px 12px',
        borderRadius: '12px',
        borderTopLeftRadius: isUser ? '12px' : '0',
        borderTopRightRadius: isUser ? '0' : '12px',
      }}
    >
      <Tooltip title={format(new Date(timestamp), 'PPp')} placement="top">
        <Typography variant="body2">
          {message}{' '}
          <span style={{ fontSize: '0.75rem', color: 'gray' }}>
            {formatDistanceToNow(new Date(timestamp), {
              addSuffix: false,
            }).replace('about ', '')}
          </span>
        </Typography>
      </Tooltip>
    </Box>
    {isUser && <Avatar sx={{ marginLeft: 1 }}>U</Avatar>}
  </Box>
);
// Timestamp Display Component
const Timestamp = ({ current }) => {
  const currentTime = parseISO(current);

  const now = new Date();

  let formattedTime = '';

  if (differenceInHours(now, currentTime) >= 10) {
    // If the message is 1 day or older
    formattedTime = format(currentTime, 'd MMMM yyyy, h:mm a');
  } else if (differenceInHours(now, currentTime) >= 2) {
    // If the message is less than 1 day old
    formattedTime = format(currentTime, 'h:mm a');
  }
  return (
    <Box sx={{ textAlign: 'center', marginY: '8px' }}>
      <Typography variant="caption" color="text.secondary">
        {formattedTime}
      </Typography>
    </Box>
  );
};

// Start time for the first message
let currentTime = new Date();

// Function to increment time with a random gap (1–60 minutes)
const getNextTimestamp = () => {
  const randomGap = Math.floor(Math.random() * 60) + 1; // Random gap in minutes (1–60)
  currentTime = sub(currentTime, { minutes: randomGap });
  return currentTime.toISOString();
};

// Chat Interface Component
const ChatInterface = () => {
  const [messages, setMessages] = React.useState([
    {
      id: 1,
      text: 'Duis consequat dui nec nisi volutpat eleifend. Donec ut dolor. Morbi vel lectus in quam fringilla rhoncus.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 2,
      text: 'Morbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 3,
      text: 'Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 4,
      text: 'In hac habitasse platea dictumst. Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante. Nulla justo.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 5,
      text: 'Fusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 6,
      text: 'Proin interdum mauris non ligula pellentesque ultrices. Phasellus id sapien in sapien iaculis congue. Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 7,
      text: 'Phasellus in felis. Donec semper sapien a libero. Nam dui.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 8,
      text: 'Etiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 9,
      text: 'Proin leo odio, porttitor id, consequat in, consequat ut, nulla. Sed accumsan felis. Ut at dolor quis odio consequat varius.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 10,
      text: 'Fusce consequat. Nulla nisl. Nunc nisl.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 11,
      text: 'Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 12,
      text: 'Etiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 13,
      text: 'Phasellus in felis. Donec semper sapien a libero. Nam dui.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 14,
      text: 'Proin leo odio, porttitor id, consequat in, consequat ut, nulla. Sed accumsan felis. Ut at dolor quis odio consequat varius.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 15,
      text: 'Quisque porta volutpat erat. Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla. Nunc purus.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 16,
      text: 'Quisque id justo sit amet sapien dignissim vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla dapibus dolor vel est. Donec odio justo, sollicitudin ut, suscipit a, feugiat et, eros.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 17,
      text: 'Phasellus in felis. Donec semper sapien a libero. Nam dui.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 18,
      text: 'Vestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue. Aliquam erat volutpat.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 19,
      text: 'Fusce consequat. Nulla nisl. Nunc nisl.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 20,
      text: 'Proin eu mi. Nulla ac enim. In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 21,
      text: 'Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat. Vestibulum sed magna at nunc commodo placerat.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 22,
      text: 'Aenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 23,
      text: 'Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 24,
      text: 'Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit ac nulla. Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 25,
      text: 'In hac habitasse platea dictumst. Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante. Nulla justo.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 26,
      text: 'In congue. Etiam justo. Etiam pretium iaculis justo.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
    {
      id: 27,
      text: 'Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit ac nulla. Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 28,
      text: 'Morbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 29,
      text: 'Phasellus in felis. Donec semper sapien a libero. Nam dui.',
      isUser: false,
      timestamp: getNextTimestamp(),
    },
    {
      id: 30,
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.',
      isUser: true,
      timestamp: getNextTimestamp(),
    },
  ]);
  const [input, setInput] = React.useState('');

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, isUser: true }]);
      setInput('');
    }
  };

  let lastTimestamp = null;

  return (
    <Box
      sx={{
        // width: 'calc(100% - 600px)',
        height: '85vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      {/* Chat Messages */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {messages.map((msg, index) => {
          const currentTimestamp = parseISO(msg.timestamp);
          let showTimestamp = false;

          if (!lastTimestamp) {
            // Always show the first timestamp
            showTimestamp = true;
          } else {
            const hoursDifference = differenceInHours(
              lastTimestamp,
              currentTimestamp
            );
            if (hoursDifference >= 1) {
              showTimestamp = true;
            }
          }

          if (showTimestamp) {
            lastTimestamp = currentTimestamp;
          }

          return (
            <React.Fragment key={index}>
              {showTimestamp && <Timestamp current={msg.timestamp} />}
              <ChatBubble
                key={index}
                message={msg.text}
                isUser={msg.isUser}
                timestamp={msg.timestamp}
              />
            </React.Fragment>
          );
        })}
      </Box>

      {/* Chat Input */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '12px',
          borderTop: '1px solid #ccc',
          backgroundColor: '#fff',
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder="Type a message"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{ flex: 1, marginRight: '8px' }}
        />
        <IconButton onClick={handleSendMessage} color="primary">
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatInterface;
