import {
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import styles from '../styles/ScheduleScreen.module.css';
import useMasseuseSchedule from '../hooks/useMasseuseSchedule';
import { useSelector } from 'react-redux';
import ScheduleTableLoader from '../components/ScheduleTableLoader';
import { format, parseISO } from 'date-fns';
import ScheduleMasseuseData from '../components/ScheduleMasseuseData';
import Sensual90 from '@mui/icons-material/HistoryToggleOff';

const ScheduleScreen = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { groupedSchedule, groupedScheduleLoading, error } =
    useMasseuseSchedule(userInfo.token);

  function formatDate(dateString) {
    // Parse the date string into a Date object
    const date = parseISO(dateString);

    // Format the date to "Saturday | March 23" format
    return format(date, 'EEEE | MMMM d');
  }

  // console.log(groupedSchedule);

  return (
    <Container maxWidth="xl">
      <Typography
        variant="h2"
        style={{
          marginTop: '80px',
        }}
        gutterBottom
      >
        Masseuse Schedule
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={3}>
          <List
            sx={{
              width: '100%',
              borderRadius: '50px',
              border: '1px solid #c3892b',
              backgroundColor: '#f9f3ea',
            }}
            dense
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt="foot massage icon"
                  src="https://clubdynasty.ca/wp-content/uploads/2022/11/foot_massage.gif"
                  sx={{
                    backgroundColor: '#f3e7d5',
                    padding: '5px',
                  }}
                />
              </ListItemAvatar>
              <ListItemText primary="Masseuse is available for foot massages!" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={3}>
          <List
            sx={{
              width: '100%',
              borderRadius: '50px',
              border: '1px solid #c3892b',
              backgroundColor: '#f9f3ea',
            }}
            dense
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt="foot massage icon"
                  src="https://clubdynasty.ca/wp-content/uploads/2024/07/lingam_icon.gif"
                  sx={{
                    backgroundColor: '#f3e7d5',
                    padding: '5px',
                  }}
                />
              </ListItemAvatar>
              <ListItemText primary="Masseuse is available for lingam massages!" />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={3}>
          <List
            sx={{
              width: '100%',
              borderRadius: '50px',
              border: '1px solid #c3892b',
              backgroundColor: '#f9f3ea',
            }}
            dense
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt="foot massage icon"
                  src="https://clubdynasty.ca/wp-content/uploads/2022/01/new-purple-animation.webp"
                  sx={{
                    backgroundColor: '#f3e7d5',
                    padding: '5px',
                  }}
                />
              </ListItemAvatar>
              <ListItemText primary="Masseuse is new to Club Dynasty!" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={3}>
          <List
            sx={{
              width: '100%',
              borderRadius: '50px',
              border: '1px solid #c3892b',
              backgroundColor: '#f9f3ea',
            }}
            dense
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt="Masseuse is available for 90-minute massages"
                  sx={{
                    backgroundColor: '#f3e7d5',
                    padding: '5px',
                  }}
                >
                  <Sensual90 sx={{ color: 'rgb(255, 140, 26)' }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Masseuse is available for 90-minute massages" />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      {groupedScheduleLoading ? (
        <ScheduleTableLoader />
      ) : (
        <div className={styles.scheduleTableContainer}>
          <table className={styles.tableOuter}>
            <thead>
              <tr className={styles.tableRow}>
                {groupedSchedule?.map((day, i) => (
                  <th className={styles.tableColumn} key={i}>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {formatDate(day.date)}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tableRow}>
                {groupedSchedule?.map((day, i) => (
                  <td className={styles.tableColumn} key={i}>
                    <table>
                      <tbody>
                        {day.schedules.map((schedule, i) => (
                          <ScheduleMasseuseData key={i} schedule={schedule} />
                        ))}
                      </tbody>
                    </table>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Container>
  );
};

export default ScheduleScreen;
