export default function truncateString(content, maxLength, addEllipsis = true) {
  if (content.length > maxLength) {
    // Find the last space before the maxLength to avoid cutting in the middle of a word
    let end = content.lastIndexOf(' ', maxLength);
    // If no space is found, it will slice at maxLength (if truncating mid-word is acceptable)
    if (end === -1) end = maxLength;
    // Return the truncated string with or without an ellipsis based on the addEllipsis flag
    return content.slice(0, end) + (addEllipsis ? '...' : '');
  }
  return content;
}
