import { Skeleton } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense, lazy } from 'react';
import { Carousel } from 'react-bootstrap';
import { isVideo } from '../utils/isVideo';
import './VideoCarousel.css';

const VideoJsPlayer = lazy(() => import('../components/VideoJsPlayer'));
const FeedImage = lazy(() => import('./FeedImage'));

const ImageAndVideoCarousel = ({
  media,
  handleAvatarDialogOpen,
  imageRefs,
  token,
}) => {
  return (
    <Carousel
      style={{
        marginBottom: '-15px',
        marginTop: '15px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {media.map((m, i) => {
        return (
          <Carousel.Item key={i} interval={5000}>
            {isVideo(m.hls ? m.hls : m.file) ? (
              <Suspense
                fallback={
                  <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                  />
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <VideoJsPlayer media={m} height={'300px'} width={'auto'} />
                </div>
              </Suspense>
            ) : (
              <Suspense
                fallback={
                  <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                  />
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FeedImage
                    imageRefs={imageRefs}
                    file={m.file}
                    id={m.id}
                    handleAvatarDialogOpen={handleAvatarDialogOpen}
                    fromCarousel={true}
                  />
                </div>
              </Suspense>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default ImageAndVideoCarousel;
