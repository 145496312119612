import React from 'react';
import useMasseuses from '../hooks/useMasseuses';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { masseusesList } from '../data/masseusesList';
import truncateString from '../utils/truncateString';
import { formatDistanceToNow, sub } from 'date-fns';

const ConversationList = ({
  selectedConversation,
  setSelectedConversation,
}) => {
  // const { data, isLoading, error } = useMasseuses({
  //   page: 1,
  //   page_size: 1000,
  //   profile_visible: true,
  //   active: true,
  //   technique: 'Sensual',
  // });

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  return (
    <List>
      {masseusesList.map(
        (masseuse, index) =>
          masseuse.technique !== 'Shiatsu' && (
            <React.Fragment key={index}>
              <ListItem
                key={index}
                disablePadding
                onClick={() => setSelectedConversation(masseuse)}
                sx={{
                  backgroundColor:
                    selectedConversation?.id === masseuse.id && '#f5f5f5',
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar
                      alt={masseuse.name}
                      src={masseuse.image}
                      sx={{
                        bgcolor: stringToColor(masseuse.name),
                      }}
                    >
                      {masseuse.name[0]}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="p" fontWeight={'bold'}>
                          {masseuse.name}
                        </Typography>
                        <Typography variant="caption">
                          {formatDistanceToNow(
                            sub(new Date(), {
                              hours: Math.floor(Math.random() * 72) + 1,
                            }),
                            { addSuffix: false }
                          ).replace('about ', '')}
                        </Typography>
                      </div>
                    }
                    secondary={truncateString(masseuse.bio, 55)}
                  />
                </ListItemButton>
              </ListItem>
              {index < masseusesList.length - 1 && <Divider />}
            </React.Fragment>
          )
      )}
    </List>
  );
};

export default ConversationList;
