import {
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useTransactions from '../../hooks/useTransactions';
import calculateExpiryDate from '../../utils/calculateExpiryDate';

const PointsEarningHistoryTable = ({ transactions, totalPoints }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { data, isFetching, error, fetchNextPage } = useTransactions(
    userInfo.token
  );

  return (
    <TableContainer component={Paper} variant="outlined">
      {data?.pages.length > 0 ? (
        <InfiniteScroll
          dataLength={data?.pages?.length ?? 0}
          next={fetchNextPage}
          hasMore={
            !!data?.pages &&
            data.pages.slice(-1)[0].total > data.pages.slice(-1)[0].current
          }
          loader={
            <div>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '0.8rem',
                  padding: '0.5rem',
                }}
              >
                Keep scrolling down to load more
              </p>
              {isFetching && <LinearProgress />}
            </div>
          }
          endMessage={
            <p
              style={{
                textAlign: 'center',
                fontSize: '0.8rem',
                padding: '0.5rem',
              }}
            >
              You have reached the end of the list
            </p>
          }
          scrollableTarget="scrollableDiv"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Session</TableCell>
                <TableCell>Masseuse</TableCell>
                <TableCell align="right">Points</TableCell>
                <TableCell align="right">Balance</TableCell>
                <TableCell align="right">Points Expiry</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.pages.map((page, index) => (
                <React.Fragment key={index}>
                  {page.results.map((transaction, index) => {
                    let pointsExpired =
                      new Date() >
                      new Date(calculateExpiryDate(transaction.start_time))
                        ? true
                        : false;

                    // shouldShowBalance is false if transaction.start_time is earlier than 2024-10-01
                    let shouldShowBalance =
                      new Date(transaction.start_time) >=
                      new Date('2024-10-01');

                    return (
                      <TableRow
                        key={transaction.session + '-' + index}
                        style={{
                          backgroundColor: pointsExpired ? '#f5f5f5' : 'white',
                        }}
                      >
                        <TableCell>
                          {format(new Date(transaction.start_time), 'PP')}
                        </TableCell>
                        <TableCell>{transaction.session_type}</TableCell>
                        <TableCell>
                          {transaction.masseuses.length > 0
                            ? transaction.masseuses.map((masseuse, index) => (
                                <span key={masseuse.id + '-' + index}>
                                  <Link to={`/masseuse/${masseuse?.id}`}>
                                    {masseuse.name}
                                  </Link>
                                  {index < transaction.masseuses.length - 1
                                    ? ', '
                                    : ''}
                                </span>
                              ))
                            : '---'}
                        </TableCell>

                        <TableCell align="right">
                          {transaction.points > 0 ? (
                            <span
                              style={{
                                color: '#36c610',
                              }}
                            >
                              + {transaction.points}
                            </span>
                          ) : transaction.redemption > 0 ? (
                            <span
                              style={{
                                color: '#f02c15',
                              }}
                            >
                              - {transaction.redemption}
                            </span>
                          ) : (
                            '---'
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <span
                            style={{
                              color: !pointsExpired ? '#c3892b' : '#A6A6A6',
                              fontWeight: 'bold',
                              textDecoration: pointsExpired
                                ? 'line-through'
                                : 'normal',
                            }}
                          >
                            {shouldShowBalance
                              ? transaction.balance_points
                              : 'N/A'}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          {format(
                            new Date(
                              calculateExpiryDate(transaction.start_time)
                            ),
                            'PP'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      ) : (
        <p
          style={{
            textAlign: 'center',
            fontSize: '0.8rem',
            padding: '0.5rem',
          }}
        >
          No data available
        </p>
      )}
    </TableContainer>
  );
};

export default PointsEarningHistoryTable;
