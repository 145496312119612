import React, { useRef, useEffect } from 'react';

const Starfield = () => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);

  const ctxRef = useRef(null);
  const dimensionsRef = useRef({
    width: 0,
    height: 0,
    centerX: 0,
    centerY: 0,
  });

  const starsRef = useRef([]);

  const NUM_STARS = 600;
  const STAR_BASE_SPEED = 0.01;
  const STAR_SIZE_FACTOR = 0.002;
  const STAR_COLOR = '#ffffff';

  const initStars = () => {
    const stars = [];
    for (let i = 0; i < NUM_STARS; i++) {
      stars.push({
        x: Math.random() * 2 - 1,
        y: Math.random() * 2 - 1,
        z: Math.random(),
      });
    }
    starsRef.current = stars;
  };

  const draw = () => {
    const { width, height, centerX, centerY } = dimensionsRef.current;
    const ctx = ctxRef.current;

    // Semi-transparent fill for trails
    ctx.fillStyle = 'rgba(0,0,0,0.2)';
    ctx.fillRect(0, 0, width, height);

    for (let i = 0; i < NUM_STARS; i++) {
      let star = starsRef.current[i];
      star.z -= STAR_BASE_SPEED;
      if (star.z <= 0) {
        star.x = Math.random() * 2 - 1;
        star.y = Math.random() * 2 - 1;
        star.z = 1;
      }

      const sx = (star.x / star.z) * (width / 2) + centerX;
      const sy = (star.y / star.z) * (height / 2) + centerY;
      const size = (1 - star.z) * (width * STAR_SIZE_FACTOR);

      ctx.fillStyle = STAR_COLOR;
      ctx.beginPath();
      ctx.arc(sx, sy, size, 0, 2 * Math.PI);
      ctx.fill();
    }

    // Draw text in Orbitron font
    ctx.fillStyle = 'white';
    ctx.font = '48px "Orbitron", sans-serif';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText('MORPHING...', centerX, centerY);
  };

  const animate = () => {
    draw();
    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    ctxRef.current = canvas.getContext('2d');

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      dimensionsRef.current.width = canvas.width;
      dimensionsRef.current.height = canvas.height;
      dimensionsRef.current.centerX = canvas.width / 2;
      dimensionsRef.current.centerY = canvas.height / 2;
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    initStars();
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationRef.current);
    };
    // No dependencies needed because we control everything in refs
  }, []);

  return (
    <canvas ref={canvasRef} style={{ display: 'block', background: 'black' }} />
  );
};

export default Starfield;
