import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ServicesRatesScreen = () => {
  const [iframeHeight, setIframeHeight] = useState('5000px');

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.iframeHeight) {
        setIframeHeight(`${event.data.iframeHeight}px`);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Box mt={8}>
      <iframe
        src="https://clubdynasty.ca/services-rates/"
        width="100%"
        height={iframeHeight}
        style={{ border: 'none', overflow: 'hidden' }}
        title="Services & Rates"
      />
    </Box>
  );
};

export default ServicesRatesScreen;
