import axios from 'axios';
import { API_URI } from '../constants/appConstants';
import {
  ALL_MASSEUSES_LIST_FAIL,
  ALL_MASSEUSES_LIST_REQUEST,
  ALL_MASSEUSES_LIST_SUCCESS,
  AVAILABLE_MASSEUSES_LIST_FAIL,
  AVAILABLE_MASSEUSES_LIST_REQUEST,
  AVAILABLE_MASSEUSES_LIST_SUCCESS,
  MASSEUSE_RATE_REQUEST,
  MASSEUSE_RATE_SUCCESS,
  MASSEUSE_RATE_FAIL,
  ALL_MASSEUSES_PUBLIC_LIST_SUCCESS,
  ALL_MASSEUSES_PUBLIC_LIST_FAIL,
  ALL_MASSEUSES_PUBLIC_LIST_REQUEST,
  MASSEUSE_DETAILS_REQUEST,
  MASSEUSE_DETAILS_SUCCESS,
  MASSEUSE_DETAILS_FAIL,
} from '../constants/masseusesConstants';
import shuffle from '../utils/shuffle';
import { setAlert } from './alertActions';
import * as Sentry from '@sentry/react';
import errorsToArrayString from '../utils/errorsToArrayString';

export const getAvailableMasseuses = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: AVAILABLE_MASSEUSES_LIST_REQUEST,
    });

    const { userInfo } = getState().userLogin;

    const config = {
      headers: {
        Authorization: userInfo.token,
      },
    };

    let { data } = await axios.get(`${API_URI}/masseuses/available/`, config);
    const schedule = await axios.get(`${API_URI}/schedule/`, config);

    const ratings = await axios.get(`${API_URI}/ratings/`, config);
    const ownRatings = await axios.get(`${API_URI}/members/ratings/`, config);

    data = data.filter((masseuse) => masseuse.profile_visible === true);

    data.forEach((masseuse) => {
      masseuse.schedule = schedule.data.filter(
        (schedule) =>
          schedule.name === masseuse.name &&
          schedule.background === masseuse.background
      );
      masseuse.rating = ratings.data.filter(
        (rating) => rating.masseuse === masseuse.id
      )[0];

      let setOwnRating = ownRatings.data.filter(
        (rating) => rating.masseuse === masseuse.id
      );

      if (setOwnRating.length) {
        masseuse.ownRating = setOwnRating[0];
      }
    });

    dispatch({
      type: AVAILABLE_MASSEUSES_LIST_SUCCESS,
      payload: shuffle(data),
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setAlert(error.response.data.detail, 'error'));

    dispatch({
      type: AVAILABLE_MASSEUSES_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.response.data,
    });
  }
};

export const getAllMasseuses =
  (
    page = '1',
    page_size = '10',
    bust_enhanced = false,
    services = '',
    newMasseuse = '',
    language = '',
    tattoos = '',
    available_on = '',
    hair_color = ''
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ALL_MASSEUSES_LIST_REQUEST,
      });

      const { userInfo } = getState().userLogin;

      const config = {
        headers: {
          Authorization: userInfo.token,
        },
      };

      let { data } = await axios.get(
        `${API_URI}/masseuses/?active=true&page=${page}&page_size=${page_size}&bust_enhanced=${bust_enhanced}&services=${services}&new=${newMasseuse}&language=${language}&tattoos=${tattoos}&hair_color=${hair_color}&available_on=${available_on}&active=true`,
        config
      );
      const schedule = await axios.get(`${API_URI}/schedule/`, config);
      const ratings = await axios.get(`${API_URI}/ratings/`, config);
      const ownRatings = await axios.get(`${API_URI}/members/ratings/`, config);

      let newMasseuses = data.results.filter(
        (masseuse) => masseuse.profile_visible === true && masseuse.new === true
      );
      let oldMasseuses = data.results.filter(
        (masseuse) =>
          masseuse.profile_visible === true && masseuse.new === false
      );
      data.results = [...shuffle(newMasseuses), ...shuffle(oldMasseuses)];

      data.results.forEach((masseuse) => {
        masseuse.schedule = schedule.data.filter(
          (schedule) =>
            schedule.name === masseuse.name &&
            schedule.background === masseuse.background
        );
        masseuse.rating = ratings.data.filter(
          (rating) => rating.masseuse === masseuse.id
        )[0];

        let setOwnRating = ownRatings.data.filter(
          (rating) => rating.masseuse === masseuse.id
        );

        if (setOwnRating.length) {
          masseuse.ownRating = setOwnRating[0];
        }
      });

      dispatch({
        type: ALL_MASSEUSES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch({
        type: ALL_MASSEUSES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllMasseusesPublic = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_MASSEUSES_PUBLIC_LIST_REQUEST,
    });

    let { data } = await axios.get(`https://mydynasty.ca/cd/api/users/`);

    let available = await axios.get(`https://mydynasty.ca/cd/api/available/`);

    available.data = available.data.filter(
      (masseuse) => masseuse.technique !== 'Shiatsu'
    );

    available.data = [...shuffle(available.data)];

    const schedule = await axios.get(
      `https://mydynasty.ca/cd/api/v1/schedule/`
    );

    data.forEach((masseuse, index1) => {
      for (let i = 0; i < available.data.length; i++) {
        if (masseuse.id === available.data[i].id) {
          data.splice(index1, 1);
          break;
        }
      }
    });

    let newMasseuses = data.filter(
      (masseuse) => masseuse.profile_visible === true && masseuse.new === true
    );
    let oldMasseuses = data.filter(
      (masseuse) => masseuse.profile_visible === true && masseuse.new === false
    );
    data = [...shuffle(newMasseuses), ...shuffle(oldMasseuses)];

    data.forEach((masseuse) => {
      masseuse.schedule = schedule.data.filter(
        (schedule) =>
          schedule.name === masseuse.name &&
          schedule.background === masseuse.background
      );
    });

    available.data.forEach((masseuse) => {
      masseuse.schedule = schedule.data.filter(
        (schedule) =>
          schedule.name === masseuse.name &&
          schedule.background === masseuse.background
      );
    });

    dispatch({
      type: ALL_MASSEUSES_PUBLIC_LIST_SUCCESS,
      payload: { data, available: available.data },
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: ALL_MASSEUSES_PUBLIC_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getMasseuseDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MASSEUSE_DETAILS_REQUEST,
    });

    let { data } = await axios.get(`${API_URI}/masseuses/${id}/`);
    const schedule = await axios.get(`${API_URI}/schedule/`);
    const sessionRatings = await axios.get(`${API_URI}/members/ratings/`);
    const favoriteMasseuses = await axios.get(
      `${API_URI}/feed_favorites/?page=${1}&page_size=${100}`
    );

    data.schedule = schedule.data.filter(
      (schedule) =>
        schedule.name === data.name && schedule.background === data.background
    );

    data.sessionRating = sessionRatings.data.filter(
      (rating) => rating.id === data.id
    )[0];

    favoriteMasseuses.data.results.forEach((result) => {
      if (result.masseuse === data.id) {
        data.favorite = result;
        return;
      }
    });

    dispatch({
      type: MASSEUSE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({
      type: MASSEUSE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const rateMasseuse = (values) => async (dispatch) => {
  try {
    dispatch({
      type: MASSEUSE_RATE_REQUEST,
      payload: values.masseuse,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
      },
    };

    // const { data } = await axios.post(
    //   `${API_URI}/members/ratings/`,
    //   values,
    //   config
    // );

    const { data } = await axios.post(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/submit/${values.session_id}/`,
      values.payload,
      config
    );

    dispatch({
      type: MASSEUSE_RATE_SUCCESS,
      payload: data,
    });

    dispatch(setAlert('Masseuse rated successfully!', 'success'));
  } catch (error) {
    Sentry.captureException(error);

    const errors = errorsToArrayString(error?.response?.data);

    if (errors && errors.isErrorArray) {
      dispatch(setAlert(errors.errorText, 'error'));
    }

    dispatch({
      type: MASSEUSE_RATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const rateMasseuseEmail = (values) => async (dispatch) => {
  try {
    dispatch({
      type: MASSEUSE_RATE_REQUEST,
      payload: values.masseuse,
    });

    const config = {
      headers: {
        'Content-Type': 'Application/json',
        Authorization: `${values.token}`,
      },
    };

    const { data } = await axios.post(
      `https://mydynasty.ca/ext/api/v1/surveys/answers/vip/${values.session_id}/?token=${values.token}`,
      values.payload,
      config
    );

    dispatch({
      type: MASSEUSE_RATE_SUCCESS,
      payload: data,
    });

    dispatch(setAlert('Thank you for submitting the survey!', 'success'));
  } catch (error) {
    Sentry.captureException(error);

    const errors = errorsToArrayString(error?.response?.data);

    if (errors && errors.isErrorArray) {
      dispatch(setAlert(errors.errorText, 'error'));
    }

    dispatch({
      type: MASSEUSE_RATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
