import {
  AppBar,
  Avatar,
  Button,
  CardContent,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { format } from 'date-fns';
import SessionMasseuse from './SessionMasseuse';
import SessionMasseuses from './SessionMasseuses';
import ReceiptPDF from './ReceiptPDF';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import useSessionReceiptDetails from '../hooks/useSessionReceiptDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionCard = ({ session, token, ownRatings }) => {
  const [open, setOpen] = React.useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { sessionReceiptDetails, isLoading, error } = useSessionReceiptDetails(
    session.id,
    token
  );

  return (
    <>
      {session.masseuses.length === 1 && (
        <SessionMasseuse
          masseuse={session.masseuses[0]}
          token={token}
          ownRating={ownRatings?.find(
            (rating) => rating.id === session.masseuses[0]
          )}
          session_id={session.id}
        />
      )}
      {session.masseuses.length > 1 &&
        session.masseuses.map((masseuse, index) => (
          <div key={index}>
            <SessionMasseuses
              masseuse={masseuse}
              token={token}
              ownRating={ownRatings?.find((rating) => rating.id === masseuse)}
              session_id={session.id}
            />
            {index < session.masseuses.length - 1 && <Divider />}
          </div>
        ))}

      <CardContent
        sx={{
          paddingTop: '0px !important',
        }}
      >
        <Divider />
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            color: '#C48A2D',
            fontFamily: 'Barlow',
            fontWeight: '600',
            fontSize: '1.5rem',
            marginBottom: '0px',
            marginTop: '10px',
          }}
        >
          Session: {session.session_type.name}
        </Typography>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            marginBottom: '10px',
          }}
        >
          <ListItem
            sx={{
              padding: '0px',
              marginBottom: '10px',
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: '#F3E8D7',
                }}
              >
                <EmojiEventsIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={session.points} secondary="Points" />
          </ListItem>
          <ListItem
            sx={{
              padding: '0px',
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: '#F3E8D7',
                }}
              >
                <AccessTimeIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={format(new Date(session.start_time), 'PP')}
              secondary={`${format(new Date(session.start_time), 'p')} - 
                    ${format(new Date(session.end_time), 'p')}`}
            />
          </ListItem>
          {/* {(userInfo?.user?.name.includes('Ricky') ||
            userInfo?.user?.name.includes('Jordan')) && ( */}
          <Button
            variant="outlined"
            onClick={handleClickOpen}
            size="small"
            sx={{
              marginTop: '10px',
            }}
          >
            Receipt
          </Button>
          {/* )} */}
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                overflow: 'hidden',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Session Receipt
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div style={{ padding: '10px', height: '100%', overflow: 'auto' }}>
              <ReceiptPDF
                session={session}
                sessionReceiptDetails={sessionReceiptDetails?.find(
                  (s) => s.id === session.id
                )}
              />
            </div>
          </Dialog>
        </List>

        <Divider />
      </CardContent>
    </>
  );
};

export default SessionCard;
