import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const BackgroundVideo = ({ src, placeholder }) => {
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handleProgress = (progress) => {
    // Check if at least 50% of the video is loaded
    if (progress.loaded >= 0.5) {
      setIsVideoReady(true);
    }
  };

  return (
    <div className="video-container">
      {/* Placeholder image */}
      {!isVideoReady && (
        <img
          src={placeholder}
          alt="Placeholder"
          className="placeholder-image"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: 1,
          }}
        />
      )}

      <ReactPlayer
        url={src}
        playing={true}
        loop={true}
        muted={true}
        playsinline={true}
        width="100%"
        height="100%"
        onProgress={handleProgress}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />

      <div className="overlay"></div>
    </div>
  );
};

export default BackgroundVideo;
