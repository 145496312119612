import { Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SUBMIT_SURVEY_RESET } from '../constants/surveyConstants';
import { REMOVE_ALERT } from '../constants/alertConstants';
import ThankYou from '../img/thank-you-400px.jpg';

const ThankYouSurveyScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SUBMIT_SURVEY_RESET });
    dispatch({ type: REMOVE_ALERT });
  }, []);

  return (
    <Box
      sx={{
        background: {
          xs: 'url(https://clubdynasty.ca/wp-content/uploads/2025/01/mobile-bg.jpg) center center/cover no-repeat',
          sm: 'url(https://clubdynasty.ca/wp-content/uploads/2025/01/desktop-bg-scaled.jpg) center center/cover  no-repeat',
        },
        height: '100vh',
      }}
    >
      <Container>
        <Box
          pt={{
            xs: 10,
            sm: 30,
          }}
        >
          <Typography
            variant="h1"
            align="center"
            mb={{
              xs: 6,
              sm: 15,
            }}
            sx={{
              fontFamily: '"Great Vibes", serif',
              fontSize: {
                xs: '5rem',
                sm: '10rem',
              },
              lineHeight: {
                xs: '5rem',
              },
            }}
          >
            Thank You
          </Typography>
          <Typography
            align="center"
            style={{
              color: '#FFF',
            }}
          >
            We sincerely appreciate you taking the time to submit the survey and
            share your valuable feedback with us. It is through the insights and
            opinions of our customers that we are able to continuously improve
            our services and exceed expectations. Your feedback will help us
            identify areas where we can improve and ensure that we maintain the
            highest level of customer satisfaction. Thank you again for your
            support and for choosing our services.
          </Typography>{' '}
          <br />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '2rem',
              marginBottom: '10px',
            }}
          >
            <Link to="/login">
              <Button variant="contained" disableElevation>
                Login to members area
              </Button>
            </Link>
          </div>
        </Box>
      </Container>{' '}
    </Box>
  );
};

export default ThankYouSurveyScreen;
