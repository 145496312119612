import { Container, Grid, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { readNotification } from '../actions/notificationActions';
import Meta from '../components/Meta';
import Notification from '../components/Notification';
import useNotifications from '../hooks/useNotifications';
import usePinnedNotifications from '../hooks/usePinnedNotifications';
import useMasseuses from '../hooks/useMasseuses';

const NotificationListScreen = () => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [notificationRead, setNotificationRead] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const notificationReadHandler = (id) => {
    dispatch(readNotification(id, setNotificationRead));
  };

  const {
    data,
    isLoading,
    error: notificationListError,
    fetchNextPage,
  } = useNotifications(userInfo.token, notificationRead);

  // const { pinnedNotifications } = usePinnedNotifications(userInfo.token);

  const { data: masseuses } = useMasseuses({
    page: 1,
    page_size: 1000,
  });

  // console.log(masseuses);

  const readStyle = {
    border: '1px solid #e7d0aa',
    borderRadius: '10px',
    padding: '32px',
    margin: '0px 0 30px 0',
  };

  const unreadStyle = {
    borderRadius: '10px',
    padding: '32px',
    background: '#f9f3ea',
    margin: '0px 0 30px 0',
  };

  return (
    <Container style={{ minHeight: '100vh' }}>
      <Meta
        title={'Notifications'}
        description={'List of your notifications'}
        keywords={
          'notifications, club dynasty notification, club dynasty members area, vip members'
        }
      />
      <Grid container spacing={1} mt={10}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Notifications
          </Typography>
        </Grid>
      </Grid>
      {/* {pinnedNotifications?.results.map((notification, i) => (
        <Notification
          notification={notification}
          key={i}
          readStyle={readStyle}
          unreadStyle={unreadStyle}
          notificationReadHandler={notificationReadHandler}
        />
      ))} */}
      {!isLoading ? (
        <InfiniteScroll
          dataLength={data?.pages?.slice(-1)[0].last ?? 5}
          next={fetchNextPage}
          hasMore={
            data?.pages.slice(-1)[0].total === data?.pages.slice(-1)[0].current
              ? false
              : true
          }
          loader={[...Array(pageSize).keys()].map((l, i) => (
            <Grid item xs={12} style={readStyle} key={i}>
              <Skeleton height={60} />
              <Skeleton height={30} style={{ marginBottom: '10px' }} />
              <Skeleton width="15%" height={40} />
            </Grid>
          ))}
          endMessage={
            <p style={{ textAlign: 'center', marginBottom: '20px' }}>
              You have reached the end of the notifications
            </p>
          }
          style={{ width: '100%' }}
        >
          {data?.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.results.map((notification, i) => {
                // if (
                //   pinnedNotifications &&
                //   pinnedNotifications.results.some(
                //     (pinned) => pinned.id === notification.id
                //   )
                // ) {
                //   return null; // Return nothing if the notification is not in pinnedNotifications
                // } else
                return (
                  <Notification
                    notification={notification}
                    key={i}
                    readStyle={readStyle}
                    unreadStyle={unreadStyle}
                    notificationReadHandler={notificationReadHandler}
                    masseuses={masseuses}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </InfiniteScroll>
      ) : null}
    </Container>
  );
};

export default NotificationListScreen;
