import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchField() {
  return (
    <TextField
      variant="outlined"
      placeholder="Search"
      sx={{
        borderRadius: '50px', // Rounded border
        '& .MuiOutlinedInput-root': {
          borderRadius: '50px',
          '& fieldset': {
            borderRadius: '50px', // Ensures the border is rounded
          },
        },
        width: '280px',
        m: 1,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      size="small"
    />
  );
}

export default SearchField;
