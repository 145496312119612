export const masseusesList = [
  {
    name: 'Michelle',
    age: '1',
    background: 'Chinese',
    language: 'Chinese, English',
    technique: 'Shiatsu',
    tattoos: null,
    height: "5'3",
    measurements: '',
    interests: '',
    strength: 'Medium',
    vaccinated: true,
    id: 133,
    services: 'Men ♂️',
    image: null,
    vacation: false,
    survey_average: 9.3,
    survey_count: 217,
    updated: '2024-07-10T19:55:50.257364-04:00',
    bio: "Michelle's immense popularity as a masseuse at Club Dynasty is well-earned and for excellent reasons. Her exceptional skill set combines deep-tissue expertise with just the perfect touch of sensuality, making her a standout in her field. Additionally, Michelle has gained a reputation for delivering incredible foot massages.",
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 05, Feb 12:00 PM - 10:00 PM',
      'Thu, 06, Feb 11:00 AM - 10:00 PM',
    ],
  },
  {
    name: 'Gigi',
    age: '1',
    background: 'Chinese',
    language: 'Chinese, English',
    technique: 'Shiatsu',
    tattoos: null,
    height: "5'2",
    measurements: '',
    interests: '',
    strength: 'Hard',
    vaccinated: true,
    id: 142,
    services: 'Men ♂️',
    image: null,
    vacation: false,
    survey_average: 9.6,
    survey_count: 332,
    updated: '2024-03-16T18:03:39.183289-04:00',
    bio: 'Gigi is an exceptionally skilled Shiatsu practitioner with surprising strength for her size. Her expert touch can effortlessly unravel all your muscle knots, offering a deeply relaxing and rejuvenating experience.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 11:00 AM - 1:00 AM',
      'Tue, 07, Jan 11:00 AM - 1:00 AM',
    ],
  },
  {
    name: 'Yoyo',
    age: '1',
    background: 'Chinese',
    language: 'Chinese, English',
    technique: 'Shiatsu',
    tattoos: null,
    height: "5'4",
    measurements: '',
    interests: '',
    strength: 'Hard',
    vaccinated: true,
    id: 150,
    services: 'Men ♂️',
    image: null,
    vacation: false,
    survey_average: 9.5,
    survey_count: 316,
    updated: '2024-06-12T16:44:26.367247-04:00',
    bio: "Yoyo's expertise in deep-tissue Shiatsu massage truly sets her apart as a consummate professional. Her exceptional skills have earned her a well-deserved popularity at Club Dynasty, and for good reason. Guests who experience her treatments can anticipate leaving feeling 18 again!",
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 11:00 AM - 1:00 AM',
      'Thu, 09, Jan 12:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Nicole',
    age: '1',
    background: 'Hong Kongese',
    language: 'Cantonese, English',
    technique: 'Shiatsu',
    tattoos: null,
    height: "5'7",
    measurements: '',
    interests: '',
    strength: 'Medium',
    vaccinated: true,
    id: 153,
    services: 'Men ♂️',
    image: null,
    vacation: false,
    survey_average: 9.7,
    survey_count: 349,
    updated: '2024-06-13T12:11:04.051494-04:00',
    bio: 'Nicole offers a unique "Hong Kong" style massage that sets her apart. Her technique features a satisfying medium to strong pressure, and she\'s also known for her very special  sensual touch. Nicole is a beloved favorite among patrons at Club Dynasty.',
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 12:00 PM - 1:00 AM',
      'Tue, 07, Jan 11:00 AM - 1:00 AM',
    ],
  },
  {
    name: 'Tina',
    age: '28',
    background: 'Chinese',
    language: 'Chinese',
    technique: 'Sensual',
    tattoos: null,
    height: "5'2",
    measurements: '32B-26-35',
    interests: 'Cooking, Yoga, Backpacking, Hiking',
    strength: 'Hard',
    vaccinated: true,
    id: 154,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/e3d320919f1e4ffe8d418e763db00903.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 283,
    updated: '2024-11-30T20:06:55.174727-05:00',
    bio: 'Tina is an absolute darling, proficient in the art of Shiatsu, while also being open-minded and affable—truly offering the best of both worlds!',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/735261dde352425faf224f5cab24fc10.webp',
      'https://clubdynasty.ca/media/users/images/55455bb5c30e436a8922b5ff8da2806a.webp',
      'https://clubdynasty.ca/media/users/images/b676e593f3d84f8093937ef9bf60358b.webp',
      'https://clubdynasty.ca/media/users/images/24445d0058ea43218ab3e4ff6fb14ba2.webp',
      'https://clubdynasty.ca/media/users/images/8c257d669afb4b1881aa2f478518a01c.webp',
      'https://clubdynasty.ca/media/users/images/e13707384a8f431796b135a0246103db.webp',
      'https://clubdynasty.ca/media/users/images/0befb0b01aea436c96e46071affe3a8b.webp',
      'https://clubdynasty.ca/media/users/images/45c9890605fa417cbe1824affbf8f124.webp',
      'https://clubdynasty.ca/media/users/images/bd01262b4389403bbc356b274eb1f304.webp',
      'https://clubdynasty.ca/media/users/images/a9b869f346c6489cae4ef27e1dca8986.webp',
      'https://clubdynasty.ca/media/users/images/e3d320919f1e4ffe8d418e763db00903.webp',
      'https://clubdynasty.ca/media/users/images/20b8a13d7ee048db9833c722c72dafbb.webp',
      'https://clubdynasty.ca/media/users/images/9c4e242559f34c4d9d3cb104286de4af.webp',
      'https://clubdynasty.ca/media/users/images/f5d8893ae0464298ba0409affde76907.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/735261dde352425faf224f5cab24fc10.webp',
      'https://clubdynasty.ca/media/users/thumbnails/55455bb5c30e436a8922b5ff8da2806a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b676e593f3d84f8093937ef9bf60358b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/24445d0058ea43218ab3e4ff6fb14ba2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8c257d669afb4b1881aa2f478518a01c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e13707384a8f431796b135a0246103db.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0befb0b01aea436c96e46071affe3a8b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/45c9890605fa417cbe1824affbf8f124.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bd01262b4389403bbc356b274eb1f304.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a9b869f346c6489cae4ef27e1dca8986.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e3d320919f1e4ffe8d418e763db00903.webp',
      'https://clubdynasty.ca/media/users/thumbnails/20b8a13d7ee048db9833c722c72dafbb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9c4e242559f34c4d9d3cb104286de4af.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f5d8893ae0464298ba0409affde76907.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/e3c0c1cfaae6487a9eeb9c4c0ca2b614.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/e3c0c1cfaae6487a9eeb9c4c0ca2b614.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 10:30 AM - 9:00 PM',
      'Wed, 08, Jan 10:30 AM - 7:00 PM',
    ],
  },
  {
    name: 'Crystal',
    age: '29',
    background: 'Hong Kongese',
    language: 'Cantonese, English',
    technique: 'Shiatsu',
    tattoos: null,
    height: "5'6",
    measurements: '34B-28-39',
    interests: 'Animals, Swimming, Movies, Reading',
    strength: 'Medium',
    vaccinated: true,
    id: 161,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/d24ea9600ccf4eb3814ebf968380413b.webp',
    vacation: false,
    survey_average: 9.3,
    survey_count: 73,
    updated: '2024-04-22T16:13:50.175385-04:00',
    bio: 'Crystal is a grounded, amiable individual who excels in delivering deep-tissue massages, blending the perfect amount of sensuality. She is fluent in English and is passionate about ensuring her customers leave feeling joyful and revitalized.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/789c5926e03641329f5467e4da69ab85.webp',
      'https://clubdynasty.ca/media/users/images/e8f4b016274a457fae77cb2dbb4aac00.webp',
      'https://clubdynasty.ca/media/users/images/ea5754028c874cfa90d92246e5609006.webp',
      'https://clubdynasty.ca/media/users/images/36f0f21d244e4ac1a28dc310d1788f58.webp',
      'https://clubdynasty.ca/media/users/images/d62ae8bd0f4b439592589fc7592a32c8.webp',
      'https://clubdynasty.ca/media/users/images/077a5b8c506b491b8153144daa73ac78.webp',
      'https://clubdynasty.ca/media/users/images/d24ea9600ccf4eb3814ebf968380413b.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/789c5926e03641329f5467e4da69ab85.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e8f4b016274a457fae77cb2dbb4aac00.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ea5754028c874cfa90d92246e5609006.webp',
      'https://clubdynasty.ca/media/users/thumbnails/36f0f21d244e4ac1a28dc310d1788f58.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d62ae8bd0f4b439592589fc7592a32c8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/077a5b8c506b491b8153144daa73ac78.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d24ea9600ccf4eb3814ebf968380413b.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Rainbow',
    age: '1',
    background: 'Hong Kongese',
    language: 'Chinese, English',
    technique: 'Shiatsu',
    tattoos: null,
    height: "5'5",
    measurements: '',
    interests: '',
    strength: 'Hard',
    vaccinated: true,
    id: 270,
    services: 'Men ♂️',
    image: null,
    vacation: false,
    survey_average: 9.8,
    survey_count: 304,
    updated: '2024-12-22T16:46:25.900237-05:00',
    bio: "Rainbow is widely recognized as one of the premier masseuses at Club Dynasty. Her approach to massage is truly distinctive, delivering an exceptionally pleasurable experience. Rainbow's strength is also remarkable, capable of relieving any bodily aches and pains with ease.",
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 11:00 AM - 1:00 AM',
      'Fri, 10, Jan 12:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Karla',
    age: '24',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'4",
    measurements: '34B-26-37',
    interests: 'Cooking, Dancing, Animals, Podcasts, Nature',
    strength: '',
    vaccinated: false,
    id: 289,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/1706b77c7c32447e98a2db28f61832da.webp',
    vacation: false,
    survey_average: 9.9,
    survey_count: 140,
    updated: '2024-10-16T13:14:09.948267-04:00',
    bio: 'Karla is among the most sought-after masseuses at Dynasty, combining stunning looks with a charming and affable personality. Articulate, congenial, and entertaining, she epitomizes the allure of the pretty girl-next-door. If that’s your preference, Karla is the ideal choice for you.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/34c04ab03c39485f927464ca2f2e3f2e.webp',
      'https://clubdynasty.ca/media/users/images/6e0a3b0b272d4d9eb1f3012ca7213f0a.webp',
      'https://clubdynasty.ca/media/users/images/6c62962b414b45a5bb7a2960d08b6f0c.webp',
      'https://clubdynasty.ca/media/users/images/6cade17f3eb64f67a04aca8085e94088.webp',
      'https://clubdynasty.ca/media/users/images/1e6ce3b579054babae2a7eca89ea50fb.webp',
      'https://clubdynasty.ca/media/users/images/20427a015e02439ba10f051e3cb77f3e.webp',
      'https://clubdynasty.ca/media/users/images/8a2daa5b80a3454e8a95b6efb26b7b7a.webp',
      'https://clubdynasty.ca/media/users/images/aec560ab77294310a3163b030531dd53.webp',
      'https://clubdynasty.ca/media/users/images/fc700b04c8b44e0f8ec4c66c921c66b9.webp',
      'https://clubdynasty.ca/media/users/images/1706b77c7c32447e98a2db28f61832da.webp',
      'https://clubdynasty.ca/media/users/images/a100563542db400cb1738711cc9dfa92.webp',
      'https://clubdynasty.ca/media/users/images/0a60e2d535544a3198dbfcc4c2b4b64c.webp',
      'https://clubdynasty.ca/media/users/images/4116100ba1e54045902ec08adeee4875.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/34c04ab03c39485f927464ca2f2e3f2e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6e0a3b0b272d4d9eb1f3012ca7213f0a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6c62962b414b45a5bb7a2960d08b6f0c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6cade17f3eb64f67a04aca8085e94088.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1e6ce3b579054babae2a7eca89ea50fb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/20427a015e02439ba10f051e3cb77f3e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8a2daa5b80a3454e8a95b6efb26b7b7a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/aec560ab77294310a3163b030531dd53.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fc700b04c8b44e0f8ec4c66c921c66b9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1706b77c7c32447e98a2db28f61832da.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a100563542db400cb1738711cc9dfa92.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0a60e2d535544a3198dbfcc4c2b4b64c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4116100ba1e54045902ec08adeee4875.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Yara',
    age: '24',
    background: 'Persian',
    language: 'English, Farsi',
    technique: 'Sensual',
    tattoos: false,
    height: "5'7",
    measurements: '34D-27-37',
    interests: 'Outdoors, Sports, Photography, Restaurants, Science',
    strength: '',
    vaccinated: false,
    id: 305,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/837722235da449288ce1a9d2e1c124af.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 483,
    updated: '2025-01-05T21:04:46.034678-05:00',
    bio: 'Yara is a tall, slender, sweet, and completely natural model. She exudes a girl-next-door charm, possesses exceptional massage skills, and enjoys engaging in conversations and meeting new people. Yara holds the distinction of being the most requested masseuse at Club Dynasty.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/2d61ab71105a48d68643c72c85c7d3fd.webp',
      'https://clubdynasty.ca/media/users/images/095d18140a4a46bcb6ffc5ab46d53554.webp',
      'https://clubdynasty.ca/media/users/images/a3395bd80d6d4f7780ce4b16c1bfc520.webp',
      'https://clubdynasty.ca/media/users/images/74564f421bdb45cd9db109727e66964f.webp',
      'https://clubdynasty.ca/media/users/images/3396cb1fd69242d39f10852b3977ed54.webp',
      'https://clubdynasty.ca/media/users/images/19539d0fc69d43f49f5d14466a750b45.webp',
      'https://clubdynasty.ca/media/users/images/8fbdf0dbde444de0aa3a770486645650.webp',
      'https://clubdynasty.ca/media/users/images/837722235da449288ce1a9d2e1c124af.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/2d61ab71105a48d68643c72c85c7d3fd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/095d18140a4a46bcb6ffc5ab46d53554.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a3395bd80d6d4f7780ce4b16c1bfc520.webp',
      'https://clubdynasty.ca/media/users/thumbnails/74564f421bdb45cd9db109727e66964f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3396cb1fd69242d39f10852b3977ed54.webp',
      'https://clubdynasty.ca/media/users/thumbnails/19539d0fc69d43f49f5d14466a750b45.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8fbdf0dbde444de0aa3a770486645650.webp',
      'https://clubdynasty.ca/media/users/thumbnails/837722235da449288ce1a9d2e1c124af.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [
      'https://clubdynasty.ca/media/users/images/Yara_Members_2.webp',
      'https://clubdynasty.ca/media/users/images/Yara_Members_1_wnFtDLu.webp',
    ],
    ma_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/Yara_Members_2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Yara_Members_1_vt6HjID.webp',
    ],
    availabilities: ['Mon, 13, Jan 10:00 AM - 9:00 PM'],
  },
  {
    name: 'Mira',
    age: '24',
    background: 'Canadian',
    language: 'English, North African Arabic',
    technique: 'Sensual',
    tattoos: null,
    height: "5'8",
    measurements: '',
    interests: 'Badminton, Skiing, Festivals, Anime, Camping',
    strength: '',
    vaccinated: false,
    id: 321,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/81513ef18ebd4b47b574660d0e403d83.webp',
    vacation: false,
    survey_average: 9.3,
    survey_count: 241,
    updated: '2024-06-12T15:25:44.406479-04:00',
    bio: 'Mira, with her tall and blonde appearance, is wholly natural and brimming with personality. She thrives on meeting new people and crafting unforgettable experiences. She enjoys widespread popularity at Club Dynasty..',
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/3f94aeb147af4161933b54102df7a8cd.webp',
      'https://clubdynasty.ca/media/users/images/81513ef18ebd4b47b574660d0e403d83.webp',
      'https://clubdynasty.ca/media/users/images/c795b3b2a3f643b9aa6dcfa80375f7ba.webp',
      'https://clubdynasty.ca/media/users/images/60f1e4b56fcd44d4a07ed9db94a37387.webp',
      'https://clubdynasty.ca/media/users/images/75fdd80e409c4afe8ecb2dde71ab1e0d.webp',
      'https://clubdynasty.ca/media/users/images/a89a2e8bb672485484ce9b8aa0a9dccb.webp',
      'https://clubdynasty.ca/media/users/images/96d0303ec2e042248310b6434d30ba21.webp',
      'https://clubdynasty.ca/media/users/images/dc16cbff035d46cf8d1d531b062f6201.webp',
      'https://clubdynasty.ca/media/users/images/116af2ed24364fbfad75b504afd70275.webp',
      'https://clubdynasty.ca/media/users/images/45a61b1fd3634dd995aab38c5e561ae3.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/3f94aeb147af4161933b54102df7a8cd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/81513ef18ebd4b47b574660d0e403d83.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c795b3b2a3f643b9aa6dcfa80375f7ba.webp',
      'https://clubdynasty.ca/media/users/thumbnails/60f1e4b56fcd44d4a07ed9db94a37387.webp',
      'https://clubdynasty.ca/media/users/thumbnails/75fdd80e409c4afe8ecb2dde71ab1e0d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a89a2e8bb672485484ce9b8aa0a9dccb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/96d0303ec2e042248310b6434d30ba21.webp',
      'https://clubdynasty.ca/media/users/thumbnails/dc16cbff035d46cf8d1d531b062f6201.webp',
      'https://clubdynasty.ca/media/users/thumbnails/116af2ed24364fbfad75b504afd70275.webp',
      'https://clubdynasty.ca/media/users/thumbnails/45a61b1fd3634dd995aab38c5e561ae3.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 11:00 AM - 6:00 PM',
      'Wed, 08, Jan 10:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Lucy',
    age: '20',
    background: 'Asian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'2",
    measurements: '',
    interests: 'Music, Rugby,  Philosophy',
    strength: '',
    vaccinated: false,
    id: 322,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/5125a33f9a514ec090526b52b805ef86.webp',
    vacation: false,
    survey_average: 9.3,
    survey_count: 51,
    updated: '2024-04-22T21:53:42.612081-04:00',
    bio: 'Lucy is the quintessential girl-next-door, boasting a natural and athletic appearance. She is adorable and humorous, and her massage skills are notably exceptional.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: false,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/1c1909a82d6b4856a2f899137edd0612.webp',
      'https://clubdynasty.ca/media/users/images/eb089ad105b0445382885dccd6c9bfa9.webp',
      'https://clubdynasty.ca/media/users/images/f7287a2429e34eb3b307382d4cfeabd3.webp',
      'https://clubdynasty.ca/media/users/images/d22ec995b35b43e994f89b61cc1d5bd8.webp',
      'https://clubdynasty.ca/media/users/images/5f81b3684e104630a9a60da200e21697.webp',
      'https://clubdynasty.ca/media/users/images/dc6a93f87e4f479a9701e8392b45fdb2.webp',
      'https://clubdynasty.ca/media/users/images/57f17c0018d344058a25be827e87586a.webp',
      'https://clubdynasty.ca/media/users/images/d45ab8be3b6e467dac5688f7a535bc9d.webp',
      'https://clubdynasty.ca/media/users/images/132d5328da3e4bcc9de48c3ce7eaa785.webp',
      'https://clubdynasty.ca/media/users/images/70ed2dcb07254b75baee586ee1abad8c.webp',
      'https://clubdynasty.ca/media/users/images/a0e26246813f4b9581da18180f3231d6.webp',
      'https://clubdynasty.ca/media/users/images/a2ca22ef2d3945ce88544329623fe363.webp',
      'https://clubdynasty.ca/media/users/images/beeed4085f204eb8bcbe37e55f8fdf21.webp',
      'https://clubdynasty.ca/media/users/images/e4810ac5f64b4231b5ccd0ca1605993e.webp',
      'https://clubdynasty.ca/media/users/images/05276cfaec5343aa96a0571109dc2c83.webp',
      'https://clubdynasty.ca/media/users/images/5125a33f9a514ec090526b52b805ef86.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/1c1909a82d6b4856a2f899137edd0612.webp',
      'https://clubdynasty.ca/media/users/thumbnails/eb089ad105b0445382885dccd6c9bfa9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f7287a2429e34eb3b307382d4cfeabd3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d22ec995b35b43e994f89b61cc1d5bd8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5f81b3684e104630a9a60da200e21697.webp',
      'https://clubdynasty.ca/media/users/thumbnails/dc6a93f87e4f479a9701e8392b45fdb2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/57f17c0018d344058a25be827e87586a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d45ab8be3b6e467dac5688f7a535bc9d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/132d5328da3e4bcc9de48c3ce7eaa785.webp',
      'https://clubdynasty.ca/media/users/thumbnails/70ed2dcb07254b75baee586ee1abad8c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a0e26246813f4b9581da18180f3231d6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a2ca22ef2d3945ce88544329623fe363.webp',
      'https://clubdynasty.ca/media/users/thumbnails/beeed4085f204eb8bcbe37e55f8fdf21.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e4810ac5f64b4231b5ccd0ca1605993e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/05276cfaec5343aa96a0571109dc2c83.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5125a33f9a514ec090526b52b805ef86.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: ['Mon, 06, Jan 7:00 PM - 1:00 AM'],
  },
  {
    name: 'Laura',
    age: '27',
    background: 'Polish',
    language: 'English, Polish',
    technique: 'Sensual',
    tattoos: true,
    height: "5'8",
    measurements: '34C-30-42',
    interests: 'Exploring, Travel, Shopping',
    strength: '',
    vaccinated: false,
    id: 371,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/e2e39fdcc5a84b47a466f844703b2f1f.webp',
    vacation: false,
    survey_average: 9.4,
    survey_count: 115,
    updated: '2024-11-04T10:56:48.658705-05:00',
    bio: 'Laura exudes sweetness and sophistication, tinged with just the right amount of sass. Her allure is undeniable, with curves that come naturally, a face that captivates, and a personality that draws you in like a magnet. With her expert hands, she offers massages of the utmost quality, ensuring an experience that will have you yearning for another encounter.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: false,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/e2e39fdcc5a84b47a466f844703b2f1f.webp',
      'https://clubdynasty.ca/media/users/images/82c3428488024ef8b7b5a65237f47151.webp',
      'https://clubdynasty.ca/media/users/images/631617c4efb24ee6ab82513936342d45.webp',
      'https://clubdynasty.ca/media/users/images/cc899144ca804cdca5d999a1696ef056.webp',
      'https://clubdynasty.ca/media/users/images/b85605c9afeb4fddba8c5c458894493e.webp',
      'https://clubdynasty.ca/media/users/images/4752579b219847538d0bc30788a8ab8f.webp',
      'https://clubdynasty.ca/media/users/images/51f823eb97b24c879a118971ce9eae9a.webp',
      'https://clubdynasty.ca/media/users/images/0bd0d4010f844562a88ba17b72a8c0d2.webp',
      'https://clubdynasty.ca/media/users/images/11afd8333ef54d01890449f5bbe09c03.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/e2e39fdcc5a84b47a466f844703b2f1f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/82c3428488024ef8b7b5a65237f47151.webp',
      'https://clubdynasty.ca/media/users/thumbnails/631617c4efb24ee6ab82513936342d45.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cc899144ca804cdca5d999a1696ef056.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b85605c9afeb4fddba8c5c458894493e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4752579b219847538d0bc30788a8ab8f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/51f823eb97b24c879a118971ce9eae9a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0bd0d4010f844562a88ba17b72a8c0d2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/11afd8333ef54d01890449f5bbe09c03.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/382e216513a946298cf75594e986c94b.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/382e216513a946298cf75594e986c94b.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Lilian',
    age: '29',
    background: 'Chinese',
    language: 'English, Mandarin',
    technique: 'Sensual',
    tattoos: true,
    height: "5'5",
    measurements: '36DD-26-26',
    interests:
      'Playing Piano, Hiking, Swimming, Cooking, Singing, Playing Guitar.',
    strength: '',
    vaccinated: false,
    id: 381,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/0283f6693b3440308c8cc4a6ad4437af.webp',
    vacation: false,
    survey_average: 9.7,
    survey_count: 255,
    updated: '2024-11-10T23:18:15.491441-05:00',
    bio: 'Lilian is an all-natural, seasoned masseuse, embodying sweetness and sensuality. She maintains the utmost professionalism and has received exceptional feedback for both her Shiatsu and Sensual services.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/Lilian_2.webp',
      'https://clubdynasty.ca/media/users/images/Lilian_1.webp',
      'https://clubdynasty.ca/media/users/images/Lilian_5.webp',
      'https://clubdynasty.ca/media/users/images/Lilian_7.webp',
      'https://clubdynasty.ca/media/users/images/50ab40a6d4234a8da99b73b9ab92c086.webp',
      'https://clubdynasty.ca/media/users/images/0283f6693b3440308c8cc4a6ad4437af.webp',
      'https://clubdynasty.ca/media/users/images/Lilian_6.webp',
      'https://clubdynasty.ca/media/users/images/Lilian_3.webp',
      'https://clubdynasty.ca/media/users/images/Lilian_4.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/50ab40a6d4234a8da99b73b9ab92c086.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0283f6693b3440308c8cc4a6ad4437af.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Lilian_4.webp',
    ],
    tv_images: ['https://clubdynasty.ca/media/users/images/LilianTV.webp'],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/LilianTV.webp',
    ],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 10:30 AM - 6:00 PM',
      'Fri, 10, Jan 10:30 AM - 6:00 PM',
    ],
  },
  {
    name: 'Sasha',
    age: '26',
    background: 'South Asian',
    language: 'English, Hindi',
    technique: 'Sensual',
    tattoos: true,
    height: "5'9",
    measurements: '36D-28-39',
    interests: 'Movies, Music, Gym, Food',
    strength: '',
    vaccinated: false,
    id: 434,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/ba28251ecae14884b6e88890d2ff2ae6.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 397,
    updated: '2024-11-16T19:25:05.712184-05:00',
    bio: "Sasha, with her tall and curvaceous frame, is full of fun and joviality. She enjoys bringing laughter as much as she loves to satisfy.  Sasha is one of Dynasty's most popular masseuses.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/d2e006c299ad4cae8e472305e5483f64.webp',
      'https://clubdynasty.ca/media/users/images/2dc31f44bdc249d39627346528b174ec.webp',
      'https://clubdynasty.ca/media/users/images/dab67cf48f724a1cb833efc0727e070b.webp',
      'https://clubdynasty.ca/media/users/images/4f0da86fdedc475a8f589c48291ca378.webp',
      'https://clubdynasty.ca/media/users/images/eb385e7419ab42a8b694a9518760be81.webp',
      'https://clubdynasty.ca/media/users/images/a455b18a8417454dbd31cfde22822e91.webp',
      'https://clubdynasty.ca/media/users/images/1313edb6d4f449c2a496345377fbd1e4.webp',
      'https://clubdynasty.ca/media/users/images/1ab7928c00f045c98fdba8ae65709825.webp',
      'https://clubdynasty.ca/media/users/images/4db47c973c3240e98fb51f08abcb55a2.webp',
      'https://clubdynasty.ca/media/users/images/b9e07293fb1d4dc49d507bdc723d36e5.webp',
      'https://clubdynasty.ca/media/users/images/26f7086ce13b43a182246863412ff29a.webp',
      'https://clubdynasty.ca/media/users/images/af87d681ffee4455bdfe35db2a6063b0.webp',
      'https://clubdynasty.ca/media/users/images/ba28251ecae14884b6e88890d2ff2ae6.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/d2e006c299ad4cae8e472305e5483f64.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2dc31f44bdc249d39627346528b174ec.webp',
      'https://clubdynasty.ca/media/users/thumbnails/dab67cf48f724a1cb833efc0727e070b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4f0da86fdedc475a8f589c48291ca378.webp',
      'https://clubdynasty.ca/media/users/thumbnails/eb385e7419ab42a8b694a9518760be81.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a455b18a8417454dbd31cfde22822e91.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1313edb6d4f449c2a496345377fbd1e4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1ab7928c00f045c98fdba8ae65709825.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4db47c973c3240e98fb51f08abcb55a2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b9e07293fb1d4dc49d507bdc723d36e5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/26f7086ce13b43a182246863412ff29a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/af87d681ffee4455bdfe35db2a6063b0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ba28251ecae14884b6e88890d2ff2ae6.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 6:00 PM - 1:00 AM',
      'Fri, 10, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Jenny',
    age: '1',
    background: 'Chinese',
    language: 'English, Cantonese',
    technique: 'Shiatsu',
    tattoos: null,
    height: '',
    measurements: '',
    interests: '',
    strength: '',
    vaccinated: false,
    id: 787,
    services: 'Men ♂️ & Women ♀️',
    image: null,
    vacation: false,
    survey_average: 10.0,
    survey_count: 2,
    updated: '2023-10-24T11:10:08.596453-04:00',
    bio: '',
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: false,
    allow_lingam: false,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Hailey',
    age: '22',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'4",
    measurements: '32B-27-34',
    interests: 'Gym, Snowboarding, The Outdoors',
    strength: '',
    vaccinated: false,
    id: 890,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/ff80e2867a994facacab0fbe1537a9b8.webp',
    vacation: false,
    survey_average: 9.2,
    survey_count: 125,
    updated: '2024-12-30T14:00:36.249592-05:00',
    bio: 'Back by popular demand, Hailey is a gorgeous girl who loves being active, connecting with new people, and learning about almost anything. She is very sweet and guests will connect with her immediately.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/2be1107ed5e24e2184c3bf3bfd4f915c.webp',
      'https://clubdynasty.ca/media/users/images/902a2f2e14b94ccc93ac65bf8770a770.webp',
      'https://clubdynasty.ca/media/users/images/695ab641f45d43e488d1d523547f7e39.webp',
      'https://clubdynasty.ca/media/users/images/5ebfbe3fd1074598bc3b507d8d6404f1.webp',
      'https://clubdynasty.ca/media/users/images/da81562d255f48aa8c01c6c8863b3d08.webp',
      'https://clubdynasty.ca/media/users/images/8df4290c3c0045369aa45a3992fafdbe.webp',
      'https://clubdynasty.ca/media/users/images/ff80e2867a994facacab0fbe1537a9b8.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/2be1107ed5e24e2184c3bf3bfd4f915c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/902a2f2e14b94ccc93ac65bf8770a770.webp',
      'https://clubdynasty.ca/media/users/thumbnails/695ab641f45d43e488d1d523547f7e39.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5ebfbe3fd1074598bc3b507d8d6404f1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/da81562d255f48aa8c01c6c8863b3d08.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8df4290c3c0045369aa45a3992fafdbe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ff80e2867a994facacab0fbe1537a9b8.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Anna',
    age: '26',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'8",
    measurements: '36C-29-39',
    interests: 'Art, Animals, Travelling, Cooking',
    strength: '',
    vaccinated: false,
    id: 983,
    services: 'Men ♂️',
    image: 'https://clubdynasty.ca/media/users/images/Annna4.webp',
    vacation: false,
    survey_average: 9.7,
    survey_count: 480,
    updated: '2024-07-01T18:13:50.960749-04:00',
    bio: 'Anna is extremely sociable, extroverted, receptive, and has a penchant for making new acquaintances. Being new to the realm of massage, she is enthusiastic about acquiring knowledge and aspires to become a premier masseuse at Club Dynasty.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/Annna_1.webp',
      'https://clubdynasty.ca/media/users/images/Annna_2.webp',
      'https://clubdynasty.ca/media/users/images/Annna_5.webp',
      'https://clubdynasty.ca/media/users/images/Annna_4.webp',
      'https://clubdynasty.ca/media/users/images/Annna_3.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_10.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_2.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_3.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_6.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_4.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_9.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_5.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_8.webp',
      'https://clubdynasty.ca/media/users/images/Annanew_1.webp',
      'https://clubdynasty.ca/media/users/images/Annna4.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/Annna_1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annna_2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annna_5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annna_4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annna_3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_10.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annanew_1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Annna4.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/e810985cfa0d4f9ba420e4306dd8f920.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/e810985cfa0d4f9ba420e4306dd8f920.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 12:00 PM - 8:00 PM',
      'Thu, 09, Jan 10:00 AM - 10:00 PM',
    ],
  },
  {
    name: 'Victoria',
    age: '27',
    background: 'European',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'5",
    measurements: '32C-26-36',
    interests: 'Yoga, Travel, Nature, Art',
    strength: '',
    vaccinated: false,
    id: 1036,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/46ac9de5e2444e06b218ec4aa9e80823.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 416,
    updated: '2024-11-26T17:02:22.818800-05:00',
    bio: 'Victoria is inherently creative and passionate. She is adventurous and mindful, eagerly awaiting the opportunity to provide Dynasty guests with highly enjoyable experiences.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/victor1ia_8.webp',
      'https://clubdynasty.ca/media/users/images/victor1ia_5.webp',
      'https://clubdynasty.ca/media/users/images/9df3f5cb164947f693a47c01f27bd822.webp',
      'https://clubdynasty.ca/media/users/images/46ac9de5e2444e06b218ec4aa9e80823.webp',
      'https://clubdynasty.ca/media/users/images/76f9ec24217744e7b7ea23fbfd589a1a.webp',
      'https://clubdynasty.ca/media/users/images/b0f31fb0c37140a58710f329a4101e24.webp',
      'https://clubdynasty.ca/media/users/images/c61b1aeda2254ee39425f279fc140fea.webp',
      'https://clubdynasty.ca/media/users/images/0883ef0d458e41caa468a8c836583814.webp',
      'https://clubdynasty.ca/media/users/images/9ce84ccfb0124a199d0f04e8af08986b.webp',
      'https://clubdynasty.ca/media/users/images/89bd881271db4a24a74a831dda3e2566.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/victor1ia_8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/victor1ia_5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9df3f5cb164947f693a47c01f27bd822.webp',
      'https://clubdynasty.ca/media/users/thumbnails/46ac9de5e2444e06b218ec4aa9e80823.webp',
      'https://clubdynasty.ca/media/users/thumbnails/76f9ec24217744e7b7ea23fbfd589a1a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b0f31fb0c37140a58710f329a4101e24.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c61b1aeda2254ee39425f279fc140fea.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0883ef0d458e41caa468a8c836583814.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9ce84ccfb0124a199d0f04e8af08986b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/89bd881271db4a24a74a831dda3e2566.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/657e4d0edccf43ef9c3bf9411a641dee.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/657e4d0edccf43ef9c3bf9411a641dee.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 4:30 PM - 1:00 AM',
      'Tue, 07, Jan 5:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Daisy',
    age: '26',
    background: 'Afghan',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'5",
    measurements: '34B-28-37',
    interests: 'Reading, Exercise, Cooking',
    strength: '',
    vaccinated: false,
    id: 1103,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/c17ef0e0ae204a14973b094ca362be80.webp',
    vacation: false,
    survey_average: 9.4,
    survey_count: 139,
    updated: '2024-10-18T14:54:55.606493-04:00',
    bio: 'Daisy is a gentle, caring, and passionate young lady. She relishes meeting new people and forging unforgettable connections. Daisy’s smile is infectious, and her personality is effervescent! She cherishes her time, spent laughing and embracing every moment with utmost joy and vivacity!',
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/5153cafe5dc04227bc92cd4af03dac8f.webp',
      'https://clubdynasty.ca/media/users/images/c17ef0e0ae204a14973b094ca362be80.webp',
      'https://clubdynasty.ca/media/users/images/f7338175bf194b578efe7e914e7ad074.webp',
      'https://clubdynasty.ca/media/users/images/ce06d59e3f1048efb4d95648d59d8727.webp',
      'https://clubdynasty.ca/media/users/images/33b60c44c5fe465e9ae8613fd4f9a569.webp',
      'https://clubdynasty.ca/media/users/images/6e25a16a5cbc49d5b61b39355259f67a.webp',
      'https://clubdynasty.ca/media/users/images/3cf53870a02c4ec3879816632065e249.webp',
      'https://clubdynasty.ca/media/users/images/6289473ecaad44a391e6ee4d11ff6e15.webp',
      'https://clubdynasty.ca/media/users/images/d25ffd125bc7477d8b1e80981779733c.webp',
      'https://clubdynasty.ca/media/users/images/bdc4f18b0a954cab95d269d386680da7.webp',
      'https://clubdynasty.ca/media/users/images/3fa4097c00b5456ba42e6aac4afc42df.webp',
      'https://clubdynasty.ca/media/users/images/90899b3a29e44a4b99da0648c7a9359e.webp',
      'https://clubdynasty.ca/media/users/images/e6ca83e38afd4a49993c331edac09cc1.webp',
      'https://clubdynasty.ca/media/users/images/d06640360f4b4819b7d33543c31258ac.webp',
      'https://clubdynasty.ca/media/users/images/0cd39ddac0dc44278300e3fa2abfff48.webp',
      'https://clubdynasty.ca/media/users/images/6333fbb204d747228df6a1fc276a9e02.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/5153cafe5dc04227bc92cd4af03dac8f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c17ef0e0ae204a14973b094ca362be80.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f7338175bf194b578efe7e914e7ad074.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ce06d59e3f1048efb4d95648d59d8727.webp',
      'https://clubdynasty.ca/media/users/thumbnails/33b60c44c5fe465e9ae8613fd4f9a569.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6e25a16a5cbc49d5b61b39355259f67a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3cf53870a02c4ec3879816632065e249.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6289473ecaad44a391e6ee4d11ff6e15.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d25ffd125bc7477d8b1e80981779733c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bdc4f18b0a954cab95d269d386680da7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3fa4097c00b5456ba42e6aac4afc42df.webp',
      'https://clubdynasty.ca/media/users/thumbnails/90899b3a29e44a4b99da0648c7a9359e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e6ca83e38afd4a49993c331edac09cc1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d06640360f4b4819b7d33543c31258ac.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0cd39ddac0dc44278300e3fa2abfff48.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6333fbb204d747228df6a1fc276a9e02.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Naomi',
    age: '23',
    background: 'Icelandic',
    language: 'English',
    technique: 'Sensual',
    tattoos: false,
    height: "5'5",
    measurements: '34D-27-38',
    interests: 'Fitness, Baking, Fashion, Travel',
    strength: '',
    vaccinated: false,
    id: 1108,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/078246854aef4719829356c3192bd366.webp',
    vacation: false,
    survey_average: 9.3,
    survey_count: 256,
    updated: '2024-07-12T15:53:17.424445-04:00',
    bio: 'Naomi is a laid-back and adventurous spirit with a fantastic sense of humor. She enjoys spreading joy and making others feel welcomed!',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/Naomi_1.webp',
      'https://clubdynasty.ca/media/users/images/Naomi_8.webp',
      'https://clubdynasty.ca/media/users/images/b24a4be9d4364525b600a1068c36077f.webp',
      'https://clubdynasty.ca/media/users/images/3ee1e97050fa455882b7165418ff2b19.webp',
      'https://clubdynasty.ca/media/users/images/0a63ae50892d4c2e88ba75e19fa62c49.webp',
      'https://clubdynasty.ca/media/users/images/904249b385564c33a7c4ef29313b3885.webp',
      'https://clubdynasty.ca/media/users/images/13702b3e16ad493c82cb8fa71b2f5654.webp',
      'https://clubdynasty.ca/media/users/images/Naomi_4.webp',
      'https://clubdynasty.ca/media/users/images/Naomi_5.webp',
      'https://clubdynasty.ca/media/users/images/Naomi_2.webp',
      'https://clubdynasty.ca/media/users/images/078246854aef4719829356c3192bd366.webp',
      'https://clubdynasty.ca/media/users/images/Naomin.webp',
      'https://clubdynasty.ca/media/users/images/336494378aef4f7f9f630864b0d68c51.webp',
      'https://clubdynasty.ca/media/users/images/4ad5c65ebe494b0195e4d16c029c7187.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/Naomi_1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Naomi_8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b24a4be9d4364525b600a1068c36077f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3ee1e97050fa455882b7165418ff2b19.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0a63ae50892d4c2e88ba75e19fa62c49.webp',
      'https://clubdynasty.ca/media/users/thumbnails/904249b385564c33a7c4ef29313b3885.webp',
      'https://clubdynasty.ca/media/users/thumbnails/13702b3e16ad493c82cb8fa71b2f5654.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Naomi_4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Naomi_5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Naomi_2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/078246854aef4719829356c3192bd366.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Naomin.webp',
      'https://clubdynasty.ca/media/users/thumbnails/336494378aef4f7f9f630864b0d68c51.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4ad5c65ebe494b0195e4d16c029c7187.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/d6fcabe2555e45b7bd5b92423c10e4bd.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/d6fcabe2555e45b7bd5b92423c10e4bd.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 6:00 PM - 1:00 AM',
      'Fri, 10, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Jessie',
    age: '33',
    background: 'Canadian / Irish',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'4",
    measurements: '32DD-26-36',
    interests:
      'Swimming, Yoga, Cars, Planes, Dogs, MMA/Boxing, Working Out , Music, Cooking, Gardening',
    strength: '',
    vaccinated: false,
    id: 2170,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/ad4d17823d3a423cb2e72e19927a3f59.webp',
    vacation: true,
    survey_average: 9.8,
    survey_count: 210,
    updated: '2025-01-02T10:59:37.518322-05:00',
    bio: 'Jessie possesses a warm and affectionate nature, characterized by her sweet and caring personality. She effortlessly engages in conversations, swiftly putting others at ease in her presence. Jessie also exudes sensuality and consistently strives to ensure the satisfaction of those she interacts with. Jessie loves doing 4-hand sessions with her bestie Victoria.',
    new: false,
    bust_enhanced: true,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/18d0c2dafaea4fd48f569241aedcc4de.webp',
      'https://clubdynasty.ca/media/users/images/0fe495d68eef4dafa28c11b5cf9de3ec.webp',
      'https://clubdynasty.ca/media/users/images/18c9ea23bcf146a6be21c904fcaffb74.webp',
      'https://clubdynasty.ca/media/users/images/ea4c4f0db47d4cb89761a14c1505fda0.webp',
      'https://clubdynasty.ca/media/users/images/1309e9e0f4f44de7958586bdde28d40d.webp',
      'https://clubdynasty.ca/media/users/images/3ef81ad78cbd4aa1852bcd142b11d7d5.webp',
      'https://clubdynasty.ca/media/users/images/0f20a19b88ac41faa4909d41196ae543.webp',
      'https://clubdynasty.ca/media/users/images/ad4d17823d3a423cb2e72e19927a3f59.webp',
      'https://clubdynasty.ca/media/users/images/583ce9bbe7564567bd536ab7cb496229.webp',
      'https://clubdynasty.ca/media/users/images/0bc6368463da4dc69d529c72593dbad7.webp',
      'https://clubdynasty.ca/media/users/images/e9b2da5f4ef74cc7a359fd3271dd4487.webp',
      'https://clubdynasty.ca/media/users/images/bca4643b640c4789beee7222b23f71c0.webp',
      'https://clubdynasty.ca/media/users/images/ac37d7396527408aabe70d3ed6f3b068.webp',
      'https://clubdynasty.ca/media/users/images/4ffcf5b51e8643848ee5fa052fcb36ea.webp',
      'https://clubdynasty.ca/media/users/images/5d8407cdda144d83bd97926dba9aef8c.webp',
      'https://clubdynasty.ca/media/users/images/57665597005845bf92cbeb0da7fb7866.webp',
      'https://clubdynasty.ca/media/users/images/bb93863148334f2e9efd55cdcfd50730.webp',
      'https://clubdynasty.ca/media/users/images/1e95c7755aee4104922648c0874bc530.webp',
      'https://clubdynasty.ca/media/users/images/20d18e31786a46cca539419ca5e0619f.webp',
      'https://clubdynasty.ca/media/users/images/9128f9b0d8644160b674e633f25ea2f1.webp',
      'https://clubdynasty.ca/media/users/images/9da51eb5a4a64254bf0a6a5e779c1f01.webp',
      'https://clubdynasty.ca/media/users/images/5c06fcb28d094a5497584f3d88d52a87.webp',
      'https://clubdynasty.ca/media/users/images/4e755b1c0a1e4106ae0df688b795fadf.webp',
      'https://clubdynasty.ca/media/users/images/af4a920aaab1470da8bf996839605e22.webp',
      'https://clubdynasty.ca/media/users/images/5b07066023484bd0ade0dcd2a7f96ca3.webp',
      'https://clubdynasty.ca/media/users/images/59a904958afb4c4aa157ae5be377e910.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/18d0c2dafaea4fd48f569241aedcc4de.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0fe495d68eef4dafa28c11b5cf9de3ec.webp',
      'https://clubdynasty.ca/media/users/thumbnails/18c9ea23bcf146a6be21c904fcaffb74.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ea4c4f0db47d4cb89761a14c1505fda0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1309e9e0f4f44de7958586bdde28d40d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3ef81ad78cbd4aa1852bcd142b11d7d5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0f20a19b88ac41faa4909d41196ae543.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ad4d17823d3a423cb2e72e19927a3f59.webp',
      'https://clubdynasty.ca/media/users/thumbnails/583ce9bbe7564567bd536ab7cb496229.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0bc6368463da4dc69d529c72593dbad7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e9b2da5f4ef74cc7a359fd3271dd4487.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bca4643b640c4789beee7222b23f71c0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ac37d7396527408aabe70d3ed6f3b068.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4ffcf5b51e8643848ee5fa052fcb36ea.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5d8407cdda144d83bd97926dba9aef8c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/57665597005845bf92cbeb0da7fb7866.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bb93863148334f2e9efd55cdcfd50730.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1e95c7755aee4104922648c0874bc530.webp',
      'https://clubdynasty.ca/media/users/thumbnails/20d18e31786a46cca539419ca5e0619f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9128f9b0d8644160b674e633f25ea2f1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9da51eb5a4a64254bf0a6a5e779c1f01.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5c06fcb28d094a5497584f3d88d52a87.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4e755b1c0a1e4106ae0df688b795fadf.webp',
      'https://clubdynasty.ca/media/users/thumbnails/af4a920aaab1470da8bf996839605e22.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5b07066023484bd0ade0dcd2a7f96ca3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/59a904958afb4c4aa157ae5be377e910.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Breanna',
    age: '32',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'7",
    measurements: '34C-27-36',
    interests: 'Napa Valley, Hockey, Rare Steak, Hot Yoga, Netflix & Chill',
    strength: '',
    vaccinated: false,
    id: 3869,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/5f4fe4ee105c49aab10be913cab17356.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 232,
    updated: '2024-12-30T20:53:40.999251-05:00',
    bio: 'Breanna is an energetic, fun and outgoing girl. She is self-described as "thriving on new connections and intimacy". Breanna is a great fit for Club Dynasty as she is very sensual, passionate and caring.',
    new: false,
    bust_enhanced: true,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/bb4990db76994c03a51ad050704eca79.webp',
      'https://clubdynasty.ca/media/users/images/026b7b775f8b4dac8fca94dce982956c.webp',
      'https://clubdynasty.ca/media/users/images/7de5de0929c84329accd0e9e53c09eae.webp',
      'https://clubdynasty.ca/media/users/images/353711fb004f4d3a94a21ba3e29c800e.webp',
      'https://clubdynasty.ca/media/users/images/592d5223621c4737b4d695311c26595c.webp',
      'https://clubdynasty.ca/media/users/images/379f74eb9e084385a2b17aeebbeb8cfe.webp',
      'https://clubdynasty.ca/media/users/images/a1328821ae9a4ca4a8e6f3120cecbd06.webp',
      'https://clubdynasty.ca/media/users/images/5f4fe4ee105c49aab10be913cab17356.webp',
      'https://clubdynasty.ca/media/users/images/80b39c6cc99941999fd8716a3f5b6676.webp',
      'https://clubdynasty.ca/media/users/images/473cf8efef194f7a829aa185b6e7e292.webp',
      'https://clubdynasty.ca/media/users/images/e37ba27f75084e1c8889aabd758f3175.webp',
      'https://clubdynasty.ca/media/users/images/6d627856939547a2a51d734f0b629faf.webp',
      'https://clubdynasty.ca/media/users/images/9d566f244efd4058873d372af1a20076.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/bb4990db76994c03a51ad050704eca79.webp',
      'https://clubdynasty.ca/media/users/thumbnails/026b7b775f8b4dac8fca94dce982956c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7de5de0929c84329accd0e9e53c09eae.webp',
      'https://clubdynasty.ca/media/users/thumbnails/353711fb004f4d3a94a21ba3e29c800e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/592d5223621c4737b4d695311c26595c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/379f74eb9e084385a2b17aeebbeb8cfe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a1328821ae9a4ca4a8e6f3120cecbd06.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5f4fe4ee105c49aab10be913cab17356.webp',
      'https://clubdynasty.ca/media/users/thumbnails/80b39c6cc99941999fd8716a3f5b6676.webp',
      'https://clubdynasty.ca/media/users/thumbnails/473cf8efef194f7a829aa185b6e7e292.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e37ba27f75084e1c8889aabd758f3175.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6d627856939547a2a51d734f0b629faf.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9d566f244efd4058873d372af1a20076.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 3:00 PM - 12:00 AM',
      'Thu, 09, Jan 3:00 PM - 12:00 AM',
    ],
  },
  {
    name: 'Eliza',
    age: '26',
    background: 'African',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'3",
    measurements: '34DD-27-37',
    interests: 'Music, Food, Nightlife, Beaches',
    strength: '',
    vaccinated: false,
    id: 4184,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/9be10ee3518f4145bea4ca90c6a540a6.webp',
    vacation: false,
    survey_average: 9.7,
    survey_count: 139,
    updated: '2024-10-25T14:37:22.905357-04:00',
    bio: 'Eliza is a curvy extrovert with a very outgoing personality and a genuine passion for unlocking sensuality. Eliza excels at creating a space where guests can embrace relaxation and rediscover euphoria.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/1f0aafffdd9e475897623486229d65f5.webp',
      'https://clubdynasty.ca/media/users/images/9be10ee3518f4145bea4ca90c6a540a6.webp',
      'https://clubdynasty.ca/media/users/images/5cc3e582b2e94ac59a07992ebc33bb8a.webp',
      'https://clubdynasty.ca/media/users/images/dd702cb1b6d04cecb7bcf20824502d8f.webp',
      'https://clubdynasty.ca/media/users/images/2206d2e9f0db48aca34467ee164e7abe.webp',
      'https://clubdynasty.ca/media/users/images/21c374d3d4ee4bf78cb3b173d4bdfb2d.webp',
      'https://clubdynasty.ca/media/users/images/646b07db9fb443ecb9541f2d25df7a69.webp',
      'https://clubdynasty.ca/media/users/images/5d963c92055d44f58c728a1ff8152ebc.webp',
      'https://clubdynasty.ca/media/users/images/865a594b068141929158b6c80e248c94.webp',
      'https://clubdynasty.ca/media/users/images/08da330fe76f4f23b8086c36de97e58a.webp',
      'https://clubdynasty.ca/media/users/images/127474dfbd54426cbfac8709a972ac46.webp',
      'https://clubdynasty.ca/media/users/images/333fa7dbea624d7ab6d4a66f9acfd4c9.webp',
      'https://clubdynasty.ca/media/users/images/93e3df2ecabf454aa95fb0d288aae3fb.webp',
      'https://clubdynasty.ca/media/users/images/6ccb1dc3bc184de591b336abe53b8932.webp',
      'https://clubdynasty.ca/media/users/images/088d1ffcf8714afbbe41d734b6cf2bf4.webp',
      'https://clubdynasty.ca/media/users/images/fa20ecbca1f74759bf86f4eb1c53c21c.webp',
      'https://clubdynasty.ca/media/users/images/a08b28456988408491b7fe8b2ee9bacc.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/1f0aafffdd9e475897623486229d65f5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9be10ee3518f4145bea4ca90c6a540a6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5cc3e582b2e94ac59a07992ebc33bb8a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/dd702cb1b6d04cecb7bcf20824502d8f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2206d2e9f0db48aca34467ee164e7abe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/21c374d3d4ee4bf78cb3b173d4bdfb2d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/646b07db9fb443ecb9541f2d25df7a69.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5d963c92055d44f58c728a1ff8152ebc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/865a594b068141929158b6c80e248c94.webp',
      'https://clubdynasty.ca/media/users/thumbnails/08da330fe76f4f23b8086c36de97e58a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/127474dfbd54426cbfac8709a972ac46.webp',
      'https://clubdynasty.ca/media/users/thumbnails/333fa7dbea624d7ab6d4a66f9acfd4c9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/93e3df2ecabf454aa95fb0d288aae3fb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6ccb1dc3bc184de591b336abe53b8932.webp',
      'https://clubdynasty.ca/media/users/thumbnails/088d1ffcf8714afbbe41d734b6cf2bf4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fa20ecbca1f74759bf86f4eb1c53c21c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a08b28456988408491b7fe8b2ee9bacc.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 6:00 PM - 1:00 AM',
      'Sat, 11, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Stefany',
    age: '19',
    background: 'Spanish',
    language: 'English, Spanish',
    technique: 'Sensual',
    tattoos: true,
    height: "5'6",
    measurements: '36DD-27-36',
    interests: 'Sports, Music, Yoga, Food, Animals',
    strength: '',
    vaccinated: false,
    id: 4215,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/23213e9ef8c04eefba12b96409fd562b.webp',
    vacation: true,
    survey_average: 9.1,
    survey_count: 242,
    updated: '2024-11-29T17:44:52.172256-05:00',
    bio: 'Stefany is a fit and busty newbie to the massage world.  She loves meeting new people, exploring new opportunities and strives to challenge herself everyday.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/36a253c8925e4535907405c13d801427.webp',
      'https://clubdynasty.ca/media/users/images/23213e9ef8c04eefba12b96409fd562b.webp',
      'https://clubdynasty.ca/media/users/images/1beae1262c1c48999c5d1cec95f9c855.webp',
      'https://clubdynasty.ca/media/users/images/25940575435e4fc5baf039029a9ddd5d.webp',
      'https://clubdynasty.ca/media/users/images/4c6f49de5db445c5b973e52ac2112240.webp',
      'https://clubdynasty.ca/media/users/images/2e66b6f7c94141beb80e756053f4ad6a.webp',
      'https://clubdynasty.ca/media/users/images/63cc7015fe3a486d8c4d7a6de2137550.webp',
      'https://clubdynasty.ca/media/users/images/70690693c34249808198709f2deb0ebf.webp',
      'https://clubdynasty.ca/media/users/images/84da5f8da7f646e2ac10f4e2bf8f13e1.webp',
      'https://clubdynasty.ca/media/users/images/45faf27443484f3fb79f6c013c6d0197.webp',
      'https://clubdynasty.ca/media/users/images/e72a063dcd8e4cbda4db716e62b6eb7d.webp',
      'https://clubdynasty.ca/media/users/images/1d4d7c771f1a4f519d0980e1734226b7.webp',
      'https://clubdynasty.ca/media/users/images/67de46a0073141f8b8b0960e1c755c5b.webp',
      'https://clubdynasty.ca/media/users/images/7ef102b130084cc49e1a784bbb9f54bc.webp',
      'https://clubdynasty.ca/media/users/images/907355a428514998b7b91e8d62398f2e.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/36a253c8925e4535907405c13d801427.webp',
      'https://clubdynasty.ca/media/users/thumbnails/23213e9ef8c04eefba12b96409fd562b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1beae1262c1c48999c5d1cec95f9c855.webp',
      'https://clubdynasty.ca/media/users/thumbnails/25940575435e4fc5baf039029a9ddd5d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4c6f49de5db445c5b973e52ac2112240.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2e66b6f7c94141beb80e756053f4ad6a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/63cc7015fe3a486d8c4d7a6de2137550.webp',
      'https://clubdynasty.ca/media/users/thumbnails/70690693c34249808198709f2deb0ebf.webp',
      'https://clubdynasty.ca/media/users/thumbnails/84da5f8da7f646e2ac10f4e2bf8f13e1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/45faf27443484f3fb79f6c013c6d0197.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e72a063dcd8e4cbda4db716e62b6eb7d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1d4d7c771f1a4f519d0980e1734226b7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/67de46a0073141f8b8b0960e1c755c5b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7ef102b130084cc49e1a784bbb9f54bc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/907355a428514998b7b91e8d62398f2e.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Shay',
    age: '24',
    background: 'Filipina',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'4",
    measurements: '32C-23-30',
    interests: 'Yoga, Pilates, Books, Culinary, Hiking, Surfing',
    strength: '',
    vaccinated: false,
    id: 5851,
    services: 'Men ♂️',
    image: 'https://clubdynasty.ca/media/users/images/Shay.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 118,
    updated: '2024-10-20T02:01:55.677128-04:00',
    bio: "Shay, a vibrant and health-conscious Filipina, brings her energy and expertise as a former Pilates instructor to Club Dynasty. Her dedication to a healthy lifestyle is evident in her fitness level and overall well-being. Shay's primary goal at Club Dynasty is to provide guests with a deeply relaxing and sensuous massage experience, ensuring their complete satisfaction.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: false,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/Shay_10.webp',
      'https://clubdynasty.ca/media/users/images/Shay_8.webp',
      'https://clubdynasty.ca/media/users/images/Shay_6.webp',
      'https://clubdynasty.ca/media/users/images/Shay_1.webp',
      'https://clubdynasty.ca/media/users/images/Shay_7.webp',
      'https://clubdynasty.ca/media/users/images/Shay_9.webp',
      'https://clubdynasty.ca/media/users/images/Shay.webp',
      'https://clubdynasty.ca/media/users/images/Shay_2.webp',
      'https://clubdynasty.ca/media/users/images/Shay_3.webp',
      'https://clubdynasty.ca/media/users/images/Shay_5.webp',
      'https://clubdynasty.ca/media/users/images/Shay_4.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/Shay_10.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/Shay_4.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: ['Fri, 10, Jan 12:00 PM - 6:00 PM'],
  },
  {
    name: 'Carolina',
    age: '22',
    background: '',
    language: '',
    technique: '',
    tattoos: null,
    height: '',
    measurements: '',
    interests: '',
    strength: '',
    vaccinated: false,
    id: 6238,
    services: '',
    image: null,
    vacation: false,
    survey_average: 9.7,
    survey_count: 21,
    updated: '2023-12-17T22:00:07.428847-05:00',
    bio: '',
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Alice',
    age: '28',
    background: 'Russian',
    language: 'English, Russian',
    technique: 'Sensual',
    tattoos: null,
    height: "5'7",
    measurements: '36B-28-37',
    interests:
      'Fitness, Painting/Art, Music, Cooking, Hiking, Boating, Reading',
    strength: '',
    vaccinated: false,
    id: 8059,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/cbe24e7405954cf8a883420a8b1766af.webp',
    vacation: false,
    survey_average: 9.7,
    survey_count: 366,
    updated: '2024-10-01T20:55:01.652239-04:00',
    bio: 'Alice is sweet and sexy artist by trade and a natural thrill-seeker. Born in Russia, she takes pride in staying connected to her Slavic heritage. In her free time, she enjoys visiting the gym and spending quality time with family and friends.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/b5d3db3772ac4f898b144b1f3fe86916.webp',
      'https://clubdynasty.ca/media/users/images/d663d9cf8c1c4e38ab35ec41d733f730.webp',
      'https://clubdynasty.ca/media/users/images/cd9e24a63aa54dab84c9e5992ee1fdc1.webp',
      'https://clubdynasty.ca/media/users/images/e50e46453db849598087e0e6ffa34cd2.webp',
      'https://clubdynasty.ca/media/users/images/381067c380d0417e98bd441a769ca3f3.webp',
      'https://clubdynasty.ca/media/users/images/fc203d92f6324d18b5a21b27b3939757.webp',
      'https://clubdynasty.ca/media/users/images/63fdf8bffef84972ad2902312cfdc40c.webp',
      'https://clubdynasty.ca/media/users/images/6670db6a82e449cf92f3e6ffd9ecba48.webp',
      'https://clubdynasty.ca/media/users/images/16113c1442e54266851858fc2902a6f6.webp',
      'https://clubdynasty.ca/media/users/images/a70c0abe93194a79be045d78438cf473.webp',
      'https://clubdynasty.ca/media/users/images/6d9a173909784e35abb600fc6072f22e.webp',
      'https://clubdynasty.ca/media/users/images/190161adc0db4838a432fe16d99dbf0c.webp',
      'https://clubdynasty.ca/media/users/images/5580783325c847c59d5c5df72d507b14.webp',
      'https://clubdynasty.ca/media/users/images/62bca0f037704a0384d2e1d5c9ad14d9.webp',
      'https://clubdynasty.ca/media/users/images/8aa5878f9558407599d059c1349916ca.webp',
      'https://clubdynasty.ca/media/users/images/f43674e2f49d44d39181f0a4ca19d261.webp',
      'https://clubdynasty.ca/media/users/images/89f50ecf842347dd9696e4c9a8254b0d.webp',
      'https://clubdynasty.ca/media/users/images/b05ac4c8119f493c9d3fd79bf379e22d.webp',
      'https://clubdynasty.ca/media/users/images/7841cc9f74554896b8278fdda877da86.webp',
      'https://clubdynasty.ca/media/users/images/8068fc23476f4f3f9b58b3dbfa5f08a2.webp',
      'https://clubdynasty.ca/media/users/images/cbe24e7405954cf8a883420a8b1766af.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/b5d3db3772ac4f898b144b1f3fe86916.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d663d9cf8c1c4e38ab35ec41d733f730.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cd9e24a63aa54dab84c9e5992ee1fdc1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e50e46453db849598087e0e6ffa34cd2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/381067c380d0417e98bd441a769ca3f3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fc203d92f6324d18b5a21b27b3939757.webp',
      'https://clubdynasty.ca/media/users/thumbnails/63fdf8bffef84972ad2902312cfdc40c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6670db6a82e449cf92f3e6ffd9ecba48.webp',
      'https://clubdynasty.ca/media/users/thumbnails/16113c1442e54266851858fc2902a6f6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a70c0abe93194a79be045d78438cf473.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6d9a173909784e35abb600fc6072f22e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/190161adc0db4838a432fe16d99dbf0c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5580783325c847c59d5c5df72d507b14.webp',
      'https://clubdynasty.ca/media/users/thumbnails/62bca0f037704a0384d2e1d5c9ad14d9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8aa5878f9558407599d059c1349916ca.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f43674e2f49d44d39181f0a4ca19d261.webp',
      'https://clubdynasty.ca/media/users/thumbnails/89f50ecf842347dd9696e4c9a8254b0d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b05ac4c8119f493c9d3fd79bf379e22d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7841cc9f74554896b8278fdda877da86.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8068fc23476f4f3f9b58b3dbfa5f08a2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cbe24e7405954cf8a883420a8b1766af.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Carmen',
    age: '25',
    background: 'Asian',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'2",
    measurements: '32B-24-34',
    interests: 'Swimming, Yoga, Photography, Traveling, Culinary Experiences',
    strength: '',
    vaccinated: false,
    id: 10542,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/2ec6f45f27ab4890be7e8079f3762ae8.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 180,
    updated: '2024-09-28T21:13:22.583321-04:00',
    bio: "Carmen's is a sweet and bubbly bombshell who carries a very warm and compassionate demeanor.  Anyone seeking a relaxing and rewarding sensual experience will definitely enjoy Carmen's touch and tease.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/caa76dee9c594a39b0255349096e8b9b.webp',
      'https://clubdynasty.ca/media/users/images/fc81c496721e4ebfac7b0cb80c36af43.webp',
      'https://clubdynasty.ca/media/users/images/2b62e32269304fd3aba8d31ad04544b0.webp',
      'https://clubdynasty.ca/media/users/images/d5605ab180044ef99e2c177d4b862d94.webp',
      'https://clubdynasty.ca/media/users/images/7637e37625324473bf7bcf6d4967fef9.webp',
      'https://clubdynasty.ca/media/users/images/f4f73c7b2e8c4dd494d7564984ae027b.webp',
      'https://clubdynasty.ca/media/users/images/dbe34877153f495abe0290f5750135fe.webp',
      'https://clubdynasty.ca/media/users/images/6ffe3cb81e7f4c07a776b61b71550e27.webp',
      'https://clubdynasty.ca/media/users/images/0a08ac82fe254becad9dbe575d4196f9.webp',
      'https://clubdynasty.ca/media/users/images/336145e61c384bc3ae1c789716ac9d5e.webp',
      'https://clubdynasty.ca/media/users/images/c7454d098c78491280388477364ef09c.webp',
      'https://clubdynasty.ca/media/users/images/45fe4fac917c40bcac84a2a7b8db670b.webp',
      'https://clubdynasty.ca/media/users/images/1c251c93d7154a97bd4da4a00be802fa.webp',
      'https://clubdynasty.ca/media/users/images/e355b01b26f04a6ca60ef39cf5b9897f.webp',
      'https://clubdynasty.ca/media/users/images/29e87f839c40418f8521b06a437ef751.webp',
      'https://clubdynasty.ca/media/users/images/99603bd738814eda9149133f84b3b71c.webp',
      'https://clubdynasty.ca/media/users/images/63644a3aeb4d45f8b2b0b27733bc3db5.webp',
      'https://clubdynasty.ca/media/users/images/3d498eef7b35422d93a7b6cba79ebdb7.webp',
      'https://clubdynasty.ca/media/users/images/9bdb06f6dac541deb7f835c1626bb9ee.webp',
      'https://clubdynasty.ca/media/users/images/e22f6693229949dc92b8c0547d2a97f4.webp',
      'https://clubdynasty.ca/media/users/images/a93d7b632b53462ca72e1dee984ebfde.webp',
      'https://clubdynasty.ca/media/users/images/2ec6f45f27ab4890be7e8079f3762ae8.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/caa76dee9c594a39b0255349096e8b9b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fc81c496721e4ebfac7b0cb80c36af43.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2b62e32269304fd3aba8d31ad04544b0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d5605ab180044ef99e2c177d4b862d94.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7637e37625324473bf7bcf6d4967fef9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f4f73c7b2e8c4dd494d7564984ae027b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/dbe34877153f495abe0290f5750135fe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6ffe3cb81e7f4c07a776b61b71550e27.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0a08ac82fe254becad9dbe575d4196f9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/336145e61c384bc3ae1c789716ac9d5e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c7454d098c78491280388477364ef09c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/45fe4fac917c40bcac84a2a7b8db670b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1c251c93d7154a97bd4da4a00be802fa.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e355b01b26f04a6ca60ef39cf5b9897f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/29e87f839c40418f8521b06a437ef751.webp',
      'https://clubdynasty.ca/media/users/thumbnails/99603bd738814eda9149133f84b3b71c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/63644a3aeb4d45f8b2b0b27733bc3db5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3d498eef7b35422d93a7b6cba79ebdb7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9bdb06f6dac541deb7f835c1626bb9ee.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e22f6693229949dc92b8c0547d2a97f4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a93d7b632b53462ca72e1dee984ebfde.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2ec6f45f27ab4890be7e8079f3762ae8.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/6bbe743e215b46afa1ff6229da50d513.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/6bbe743e215b46afa1ff6229da50d513.webp',
    ],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 1:00 PM - 11:30 PM',
      'Wed, 08, Jan 4:00 PM - 11:30 PM',
    ],
  },
  {
    name: 'Annika',
    age: '35',
    background: 'Polish / Palestinian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'2",
    measurements: '34D-30-42',
    interests: 'Long Hikes, Dance, Reading, Travel, Camping, Hot Yoga',
    strength: '',
    vaccinated: false,
    id: 10836,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/88a9a5d0c9e1457ab7d753e28cbbc74d.webp',
    vacation: false,
    survey_average: 9.7,
    survey_count: 164,
    updated: '2024-12-26T14:09:14.922909-05:00',
    bio: "Annika is a captivating newcomer to the world of Club Dynasty, known for her dynamic and genuine beauty. She frequently receives compliments for her boundless open-mindedness and emotional intelligence. Annika's enchanting presence combines sweetness and sassiness in just the right proportions, leaving a lasting impression on all who encounter her. Her gentle disposition and innate femininity are sure to leave a lasting impact on anyone fortunate enough to meet her.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/811f6d1550bb41798a7323170d8e7b92.webp',
      'https://clubdynasty.ca/media/users/images/a83460ee82e440248486684902ce59dd.webp',
      'https://clubdynasty.ca/media/users/images/ef588abe777a45109e49e4d81554223b.webp',
      'https://clubdynasty.ca/media/users/images/6d16997c328444ec97f8bbbf7d65fa24.webp',
      'https://clubdynasty.ca/media/users/images/e4cbb550e7cb4344a1fba76eac44d488.webp',
      'https://clubdynasty.ca/media/users/images/b681481b3cc64286b2d183c2fe226d14.webp',
      'https://clubdynasty.ca/media/users/images/e688462721e04f96ae2de104def0266c.webp',
      'https://clubdynasty.ca/media/users/images/fb1703d8bdd245b8a1ad641960ecda4f.webp',
      'https://clubdynasty.ca/media/users/images/a95e9cc66cf44577a9fb1134807c4a19.webp',
      'https://clubdynasty.ca/media/users/images/88a9a5d0c9e1457ab7d753e28cbbc74d.webp',
      'https://clubdynasty.ca/media/users/images/828534a34be9412e9d8a7f0974108b60.webp',
      'https://clubdynasty.ca/media/users/images/3698040b3b6d4d2f89482fb977b40378.webp',
      'https://clubdynasty.ca/media/users/images/7d8f9bfd9f6b43a1879926f52f92580a.webp',
      'https://clubdynasty.ca/media/users/images/65beda21634c4a59bc58ebf7239a42d8.webp',
      'https://clubdynasty.ca/media/users/images/8b6b7e9378114117979b15bb34dfe4ff.webp',
      'https://clubdynasty.ca/media/users/images/38b14cbca50c4283bea560ac1841e8b7.webp',
      'https://clubdynasty.ca/media/users/images/68155f5f2f3f4257b2ed488236f65373.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/811f6d1550bb41798a7323170d8e7b92.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a83460ee82e440248486684902ce59dd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ef588abe777a45109e49e4d81554223b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6d16997c328444ec97f8bbbf7d65fa24.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e4cbb550e7cb4344a1fba76eac44d488.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b681481b3cc64286b2d183c2fe226d14.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e688462721e04f96ae2de104def0266c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fb1703d8bdd245b8a1ad641960ecda4f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a95e9cc66cf44577a9fb1134807c4a19.webp',
      'https://clubdynasty.ca/media/users/thumbnails/88a9a5d0c9e1457ab7d753e28cbbc74d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/828534a34be9412e9d8a7f0974108b60.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3698040b3b6d4d2f89482fb977b40378.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7d8f9bfd9f6b43a1879926f52f92580a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/65beda21634c4a59bc58ebf7239a42d8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8b6b7e9378114117979b15bb34dfe4ff.webp',
      'https://clubdynasty.ca/media/users/thumbnails/38b14cbca50c4283bea560ac1841e8b7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/68155f5f2f3f4257b2ed488236f65373.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 12:00 PM - 5:00 PM',
      'Thu, 09, Jan 7:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Lyla',
    age: '23',
    background: 'Chinese / Italian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'5",
    measurements: '34C-24-34',
    interests: 'Outdoor Scenery, Cars, Animals, Aesthetics, Business',
    strength: '',
    vaccinated: false,
    id: 12411,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/e6234137346b4db7ae13620728b0433a.webp',
    vacation: false,
    survey_average: 9.3,
    survey_count: 123,
    updated: '2024-07-29T11:21:49.712641-04:00',
    bio: "Lyla is a captivating blend of natural beauty and vibrant personality. With her striking mix of Chinese and Italian heritage, she possesses an allure that's impossible to ignore. It's not just her physical appearance that draws people in; Lyla's warmth and friendliness make her instantly approachable and beloved by all who meet her.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/e661436e8852469485e8221f5db20b6a.webp',
      'https://clubdynasty.ca/media/users/images/f738f0ef2b964f3791fe1ead9b4c2e72.webp',
      'https://clubdynasty.ca/media/users/images/214c16115ac849b29de2a5b53dd0d4c1.webp',
      'https://clubdynasty.ca/media/users/images/d693a800ef314abbbb7c6e0ddc6b414d.webp',
      'https://clubdynasty.ca/media/users/images/77a5070c52604945927e0ada72ff243a.webp',
      'https://clubdynasty.ca/media/users/images/03e4ac8b516d49d884c641c95849c2d6.webp',
      'https://clubdynasty.ca/media/users/images/1856ad1a2051490fb3c36d882ec388a7.webp',
      'https://clubdynasty.ca/media/users/images/d21493dea5d24a7d96f6bbc516f5519c.webp',
      'https://clubdynasty.ca/media/users/images/07984aae9f4d453bb686a30248b7b455.webp',
      'https://clubdynasty.ca/media/users/images/e7e9955f31014126b6e166ab70d86435.webp',
      'https://clubdynasty.ca/media/users/images/0e662511bf2a49d6b33a0ad2ca528e73.webp',
      'https://clubdynasty.ca/media/users/images/7cd0b6a8926746afbabdccb1e8af9bcd.webp',
      'https://clubdynasty.ca/media/users/images/e6234137346b4db7ae13620728b0433a.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/e661436e8852469485e8221f5db20b6a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f738f0ef2b964f3791fe1ead9b4c2e72.webp',
      'https://clubdynasty.ca/media/users/thumbnails/214c16115ac849b29de2a5b53dd0d4c1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d693a800ef314abbbb7c6e0ddc6b414d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/77a5070c52604945927e0ada72ff243a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/03e4ac8b516d49d884c641c95849c2d6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1856ad1a2051490fb3c36d882ec388a7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d21493dea5d24a7d96f6bbc516f5519c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/07984aae9f4d453bb686a30248b7b455.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e7e9955f31014126b6e166ab70d86435.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0e662511bf2a49d6b33a0ad2ca528e73.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7cd0b6a8926746afbabdccb1e8af9bcd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e6234137346b4db7ae13620728b0433a.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Vanessa',
    age: '35',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'7",
    measurements: '32F-28-36',
    interests:
      'Nature, Meditation, Writing, Travelling, Working Out, Learning New Skills, Fine Dining, Shopping',
    strength: '',
    vaccinated: false,
    id: 14368,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/5aaabaee6b21471598b6592268233ef2.webp',
    vacation: false,
    survey_average: 9.9,
    survey_count: 521,
    updated: '2024-06-12T15:28:19.879297-04:00',
    bio: 'Vanessa, a fresh face in the realm of massage therapy, embodies the essence of a natural brunette beauty.  She exudes a nurturing and compassionate aura, complemented by an innate intuition and a confident, feminine energy. Vanessa finds joy in connecting with new people, attentively tuning in to their needs, wants, and desires. She is passionate about continual learning and prioritizes the care of both her body and mind.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/5f9f0b23b53e476986ef42a4b71176cd.webp',
      'https://clubdynasty.ca/media/users/images/03c6f94432784a8db0e5ae430a89ae8f.webp',
      'https://clubdynasty.ca/media/users/images/a9d4042f7bd340049da75d845650abee.webp',
      'https://clubdynasty.ca/media/users/images/9f8ad13a090c4fa3b4e658307503eb64.webp',
      'https://clubdynasty.ca/media/users/images/3d90fd737bd944b6b3427784e304c4d7.webp',
      'https://clubdynasty.ca/media/users/images/6acb7406f6184cc9ae81d4a7d4613171.webp',
      'https://clubdynasty.ca/media/users/images/47d920da4c064c3cb1b1c5f949c4272d.webp',
      'https://clubdynasty.ca/media/users/images/58ccfad091e1426187e40c6d979a5947.webp',
      'https://clubdynasty.ca/media/users/images/081cbf43d7f14db89a275d19addb356c.webp',
      'https://clubdynasty.ca/media/users/images/5aaabaee6b21471598b6592268233ef2.webp',
      'https://clubdynasty.ca/media/users/images/cf4eeeccb33c4ed9b2eb5f50fb5cb2ab.webp',
      'https://clubdynasty.ca/media/users/images/620477b69c9040ceb8fa15e2fbb57a85.webp',
      'https://clubdynasty.ca/media/users/images/1e91c0b0d8d147a5a7340a7a6858334d.webp',
      'https://clubdynasty.ca/media/users/images/d7306e3a1fc8415f92e7b579e51d06d2.webp',
      'https://clubdynasty.ca/media/users/images/0cbbe20a9a294a5a871daf93c5fd940e.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/5f9f0b23b53e476986ef42a4b71176cd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/03c6f94432784a8db0e5ae430a89ae8f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a9d4042f7bd340049da75d845650abee.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9f8ad13a090c4fa3b4e658307503eb64.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3d90fd737bd944b6b3427784e304c4d7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6acb7406f6184cc9ae81d4a7d4613171.webp',
      'https://clubdynasty.ca/media/users/thumbnails/47d920da4c064c3cb1b1c5f949c4272d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/58ccfad091e1426187e40c6d979a5947.webp',
      'https://clubdynasty.ca/media/users/thumbnails/081cbf43d7f14db89a275d19addb356c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5aaabaee6b21471598b6592268233ef2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cf4eeeccb33c4ed9b2eb5f50fb5cb2ab.webp',
      'https://clubdynasty.ca/media/users/thumbnails/620477b69c9040ceb8fa15e2fbb57a85.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1e91c0b0d8d147a5a7340a7a6858334d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d7306e3a1fc8415f92e7b579e51d06d2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0cbbe20a9a294a5a871daf93c5fd940e.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 2:00 PM - 10:00 PM',
      'Tue, 07, Jan 10:00 AM - 8:00 PM',
    ],
  },
  {
    name: 'Melina',
    age: '24',
    background: 'Vietnamese',
    language: 'English, Vietnamese',
    technique: 'Sensual',
    tattoos: true,
    height: "5'1",
    measurements: '32B-27-38',
    interests: 'Yoga, Travel, Cooking, Pottery, Cocktail Making',
    strength: '',
    vaccinated: false,
    id: 17136,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/082f91694d1047f5b722c76942fb413a.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 149,
    updated: '2024-07-31T19:44:42.526362-04:00',
    bio: 'Melina exudes a captivating mix of sweetness and allure, her warm demeanor effortlessly drawing others toward her. With a passion for both the arts and fitness, she leaves an indelible mark on all who encounter her, bewitching them with her irresistible charm.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/ad8292b8bd9f42b3ad504f0e046056b1.webp',
      'https://clubdynasty.ca/media/users/images/082f91694d1047f5b722c76942fb413a.webp',
      'https://clubdynasty.ca/media/users/images/bb7a0e5cdbd14391bba4a5c15803800d.webp',
      'https://clubdynasty.ca/media/users/images/5704e518f6f64480a57edeb9cf52d2b3.webp',
      'https://clubdynasty.ca/media/users/images/a8ca9887da2543a3b30b8d11c329456f.webp',
      'https://clubdynasty.ca/media/users/images/411b2a1c24a04f7a8d925e2939d605dc.webp',
      'https://clubdynasty.ca/media/users/images/3cab3ed913d14aaaa227ada93b56bfdb.webp',
      'https://clubdynasty.ca/media/users/images/0252e1857ae8423681eb7422dd6434e3.webp',
      'https://clubdynasty.ca/media/users/images/d047e4dccd4f4411aed65ab743cefd2c.webp',
      'https://clubdynasty.ca/media/users/images/e4ad87fe3dd44654a55a89602cafe996.webp',
      'https://clubdynasty.ca/media/users/images/dab49d326e14421d8b245cc53a76d703.webp',
      'https://clubdynasty.ca/media/users/images/12207e3ca4764d309e4f38041806d52d.webp',
      'https://clubdynasty.ca/media/users/images/457ccfd57bc642e38be7b885f5d0152e.webp',
      'https://clubdynasty.ca/media/users/images/bf376326e92641f8a05b2d687c36468f.webp',
      'https://clubdynasty.ca/media/users/images/1894376e1f574215bba265032beab4a3.webp',
      'https://clubdynasty.ca/media/users/images/af60f4c07c7844f6a69785d70dfb4cbb.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/ad8292b8bd9f42b3ad504f0e046056b1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/082f91694d1047f5b722c76942fb413a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bb7a0e5cdbd14391bba4a5c15803800d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5704e518f6f64480a57edeb9cf52d2b3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a8ca9887da2543a3b30b8d11c329456f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/411b2a1c24a04f7a8d925e2939d605dc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3cab3ed913d14aaaa227ada93b56bfdb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0252e1857ae8423681eb7422dd6434e3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d047e4dccd4f4411aed65ab743cefd2c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e4ad87fe3dd44654a55a89602cafe996.webp',
      'https://clubdynasty.ca/media/users/thumbnails/dab49d326e14421d8b245cc53a76d703.webp',
      'https://clubdynasty.ca/media/users/thumbnails/12207e3ca4764d309e4f38041806d52d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/457ccfd57bc642e38be7b885f5d0152e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bf376326e92641f8a05b2d687c36468f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1894376e1f574215bba265032beab4a3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/af60f4c07c7844f6a69785d70dfb4cbb.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 6:00 PM - 1:00 AM',
      'Tue, 07, Jan 2:00 PM - 10:00 PM',
    ],
  },
  {
    name: 'Naina',
    age: '26',
    background: 'Indian / Arabic',
    language: 'English, Hindi, Punjabi',
    technique: 'Sensual',
    tattoos: null,
    height: "5'1",
    measurements: '34C-28-36',
    interests: 'Travel, Food, Hiking, Cooking, Fitness',
    strength: '',
    vaccinated: false,
    id: 23069,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/7e9553d3f4fc40c6b9dd71ea53bc33ff.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 164,
    updated: '2024-12-30T14:54:59.986589-05:00',
    bio: "Naina is a petite sweetheart who initially appears reserved but has a vibrant, wild side waiting to be discovered. Known for her softness and sensuality, she is excited about meeting new clients and creating memorable experiences. Naina's gentle demeanor and captivating presence make her an enchanting masseuse, ready to delight and connect.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/2c621dea2a1f4b4fac1401e837226228.webp',
      'https://clubdynasty.ca/media/users/images/5af21ea506e649f8be309adb83a19409.webp',
      'https://clubdynasty.ca/media/users/images/922e1a3f072c4570ab8b51dc6b64b08e.webp',
      'https://clubdynasty.ca/media/users/images/45a9e3426de842048a4fa8568497b562.webp',
      'https://clubdynasty.ca/media/users/images/9aeaf46237ac40b291c2e6d9ac121aae.webp',
      'https://clubdynasty.ca/media/users/images/8b7d7e6fcaf94ec6880e42d45f67c0b3.webp',
      'https://clubdynasty.ca/media/users/images/7de736dfab464a169ea9dcdbdfbcd419.webp',
      'https://clubdynasty.ca/media/users/images/2c1ba3118534406a9e3b172008e32e35.webp',
      'https://clubdynasty.ca/media/users/images/66e1b64853474ff4863b9ae71d5f10cd.webp',
      'https://clubdynasty.ca/media/users/images/7b52775213984c069519c1b91a1513cb.webp',
      'https://clubdynasty.ca/media/users/images/d900ca9d5fc3488cbb71760fddb3db5f.webp',
      'https://clubdynasty.ca/media/users/images/d99e92c8ff104aa79e80f3f558180edb.webp',
      'https://clubdynasty.ca/media/users/images/7e9553d3f4fc40c6b9dd71ea53bc33ff.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/2c621dea2a1f4b4fac1401e837226228.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5af21ea506e649f8be309adb83a19409.webp',
      'https://clubdynasty.ca/media/users/thumbnails/922e1a3f072c4570ab8b51dc6b64b08e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/45a9e3426de842048a4fa8568497b562.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9aeaf46237ac40b291c2e6d9ac121aae.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8b7d7e6fcaf94ec6880e42d45f67c0b3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7de736dfab464a169ea9dcdbdfbcd419.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2c1ba3118534406a9e3b172008e32e35.webp',
      'https://clubdynasty.ca/media/users/thumbnails/66e1b64853474ff4863b9ae71d5f10cd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7b52775213984c069519c1b91a1513cb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d900ca9d5fc3488cbb71760fddb3db5f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d99e92c8ff104aa79e80f3f558180edb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7e9553d3f4fc40c6b9dd71ea53bc33ff.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Sat, 11, Jan 12:00 PM - 8:00 PM',
      'Sun, 12, Jan 12:00 PM - 8:00 PM',
    ],
  },
  {
    name: 'Jodie',
    age: '23',
    background: 'Vietnamese',
    language: 'English, Vietnamese',
    technique: 'Sensual',
    tattoos: null,
    height: "5'3",
    measurements: '32C-29-39',
    interests: 'Music, Food, Friends, Family, Music',
    strength: '',
    vaccinated: false,
    id: 23975,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/3f258870276b4195a05d1619012a5979.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 60,
    updated: '2024-12-17T18:03:19.815215-05:00',
    bio: "Meet Jodie, a new addition to Club Dynasty! This stunning Vietnamese beauty is not only sexy but also incredibly passionate about music and connecting with people. Jodie is eager to create unforgettable moments and leave you feeling refreshed and revitalized. With her charm and enthusiasm, she's ready to make your time at Club Dynasty truly special. Don't miss out on experiencing Jodie's magic!",
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/fa06a27e47ef417e916ca1482eaa8a25.webp',
      'https://clubdynasty.ca/media/users/images/248a937c9aca4046afc2daa68175f8e9.webp',
      'https://clubdynasty.ca/media/users/images/fff7093fd4134fbe9d87745a20612b29.webp',
      'https://clubdynasty.ca/media/users/images/3f13ec821c7a4dc6ae99c4aa0331a87e.webp',
      'https://clubdynasty.ca/media/users/images/1c4ee6d6ce034d2682c09f2cafe3b034.webp',
      'https://clubdynasty.ca/media/users/images/59891ceb44ed43abbf759d2c1f5457f2.webp',
      'https://clubdynasty.ca/media/users/images/75fde4b352e94b12956b911ee696e8a6.webp',
      'https://clubdynasty.ca/media/users/images/61d607e8ab8945e78d98458dbfcdacd0.webp',
      'https://clubdynasty.ca/media/users/images/32b8f135784f4d2fa918003fd12af3fc.webp',
      'https://clubdynasty.ca/media/users/images/de24a6a462a94824b7c3cb2e29790c6b.webp',
      'https://clubdynasty.ca/media/users/images/3a0b5b970f9048a5aee0c584b2a1a2fc.webp',
      'https://clubdynasty.ca/media/users/images/5dd99b1a325a4b1fa6b8824022aae0b9.webp',
      'https://clubdynasty.ca/media/users/images/3f258870276b4195a05d1619012a5979.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/fa06a27e47ef417e916ca1482eaa8a25.webp',
      'https://clubdynasty.ca/media/users/thumbnails/248a937c9aca4046afc2daa68175f8e9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fff7093fd4134fbe9d87745a20612b29.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3f13ec821c7a4dc6ae99c4aa0331a87e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1c4ee6d6ce034d2682c09f2cafe3b034.webp',
      'https://clubdynasty.ca/media/users/thumbnails/59891ceb44ed43abbf759d2c1f5457f2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/75fde4b352e94b12956b911ee696e8a6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/61d607e8ab8945e78d98458dbfcdacd0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/32b8f135784f4d2fa918003fd12af3fc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/de24a6a462a94824b7c3cb2e29790c6b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3a0b5b970f9048a5aee0c584b2a1a2fc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5dd99b1a325a4b1fa6b8824022aae0b9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3f258870276b4195a05d1619012a5979.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/7a11f4531024485d92c751256a2e5ffc.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/7a11f4531024485d92c751256a2e5ffc.webp',
    ],
    foot_massage: true,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 6:00 PM - 1:00 AM',
      'Sat, 11, Jan 10:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Nika',
    age: '27',
    background: 'Turkish',
    language: 'English, Turkish',
    technique: 'Sensual',
    tattoos: true,
    height: "5'7",
    measurements: '34E-28-39',
    interests: 'Cars, Swimming, Dancing, Gym, Traveling',
    strength: '',
    vaccinated: false,
    id: 24220,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/fe251866ecae4ee09936daf9d0bc61df.webp',
    vacation: false,
    survey_average: 9.5,
    survey_count: 129,
    updated: '2024-12-26T14:21:04.518135-05:00',
    bio: "Nika is an all-natural beauty with a sensual and electrifying touch that's irresistibly pleasing. With boundless energy, a playful spirit, and an open mind, she's the fun and excitement you've been seeking. Soon to be an RMT, Nika combines her soothing touch with a sweet, sultry voice that enchants, while her curves leave you seeing stars. Get ready for an unforgettable, serene experience with Nika!",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/1affb9948a27440a817ed656ce56ac72.webp',
      'https://clubdynasty.ca/media/users/images/28dcb5c9c63f4f559d6c8a1862cf6fe6.webp',
      'https://clubdynasty.ca/media/users/images/5b49697331804ea185735c1419a882f5.webp',
      'https://clubdynasty.ca/media/users/images/d07b15a97e3b48ecaa92a2c726b19949.webp',
      'https://clubdynasty.ca/media/users/images/df31549ac44c4aa78096d6b12cafae49.webp',
      'https://clubdynasty.ca/media/users/images/9a1ed514c06d46dba57fe38b09d644e4.webp',
      'https://clubdynasty.ca/media/users/images/daa4e0a6f32d4a2e9c41696eb094e59b.webp',
      'https://clubdynasty.ca/media/users/images/f88388a781ae42e18b7eb813e03f54b8.webp',
      'https://clubdynasty.ca/media/users/images/e755ca2ab1cd46cc9d3b0054d97bb292.webp',
      'https://clubdynasty.ca/media/users/images/d61c7d6a1e7c4237be6444626d669270.webp',
      'https://clubdynasty.ca/media/users/images/00442f71b963477e8303e2d023983159.webp',
      'https://clubdynasty.ca/media/users/images/eb177bf8435545c5a46d2f18daf20263.webp',
      'https://clubdynasty.ca/media/users/images/5d5069adddec4c668732d45a8e497676.webp',
      'https://clubdynasty.ca/media/users/images/63443334253c45ec90da66ae27c570d0.webp',
      'https://clubdynasty.ca/media/users/images/fe251866ecae4ee09936daf9d0bc61df.webp',
      'https://clubdynasty.ca/media/users/images/2d12a8c119164fe2993fd52f068afe74.webp',
      'https://clubdynasty.ca/media/users/images/e189b6e655544f4093e69606f6d6b068.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/1affb9948a27440a817ed656ce56ac72.webp',
      'https://clubdynasty.ca/media/users/thumbnails/28dcb5c9c63f4f559d6c8a1862cf6fe6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5b49697331804ea185735c1419a882f5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d07b15a97e3b48ecaa92a2c726b19949.webp',
      'https://clubdynasty.ca/media/users/thumbnails/df31549ac44c4aa78096d6b12cafae49.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9a1ed514c06d46dba57fe38b09d644e4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/daa4e0a6f32d4a2e9c41696eb094e59b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f88388a781ae42e18b7eb813e03f54b8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e755ca2ab1cd46cc9d3b0054d97bb292.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d61c7d6a1e7c4237be6444626d669270.webp',
      'https://clubdynasty.ca/media/users/thumbnails/00442f71b963477e8303e2d023983159.webp',
      'https://clubdynasty.ca/media/users/thumbnails/eb177bf8435545c5a46d2f18daf20263.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5d5069adddec4c668732d45a8e497676.webp',
      'https://clubdynasty.ca/media/users/thumbnails/63443334253c45ec90da66ae27c570d0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fe251866ecae4ee09936daf9d0bc61df.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2d12a8c119164fe2993fd52f068afe74.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e189b6e655544f4093e69606f6d6b068.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 6:00 PM - 1:00 AM',
      'Thu, 09, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Roxanne',
    age: '25',
    background: 'Asian',
    language: 'English, Tagalog',
    technique: 'Sensual',
    tattoos: true,
    height: "5'1",
    measurements: '32B-26-35',
    interests: 'Travelling, Gym, Books, Food, Podcasts',
    strength: '',
    vaccinated: false,
    id: 24653,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/63e2112cc2394770ac122da09b07e5f9.webp',
    vacation: false,
    survey_average: 9.7,
    survey_count: 119,
    updated: '2025-01-04T17:33:45.346470-05:00',
    bio: "Roxanne is a bubbly and adventurous spirit who loves exploring new places and embracing life's experiences. Passionate about health, wellness, and new connections, Roxanne enjoys meeting and inspiring others to live vibrantly.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/c6b0cd9fdb6640a9ab7386d335bf9cc3.webp',
      'https://clubdynasty.ca/media/users/images/5c5fae0ca2bd4b588ae14ccd38268665.webp',
      'https://clubdynasty.ca/media/users/images/1371c0e91634473c8b254302020061c4.webp',
      'https://clubdynasty.ca/media/users/images/f8902e5a5a5940b8a28b1cfd58c367d9.webp',
      'https://clubdynasty.ca/media/users/images/a92483b230034c968e221a3f5f90d151.webp',
      'https://clubdynasty.ca/media/users/images/236676af9dc3470f887cdb76f67d19a3.webp',
      'https://clubdynasty.ca/media/users/images/d28ab13ff76845e4a61410d2b1849212.webp',
      'https://clubdynasty.ca/media/users/images/63e2112cc2394770ac122da09b07e5f9.webp',
      'https://clubdynasty.ca/media/users/images/54bbbfaae60849dc83d1b3a3ca167151.webp',
      'https://clubdynasty.ca/media/users/images/9ac637e65a644543b15afbc2230197c2.webp',
      'https://clubdynasty.ca/media/users/images/162f4c221dc4499f829d074d97bd2551.webp',
      'https://clubdynasty.ca/media/users/images/21d2245037514f39826051592eba51dd.webp',
      'https://clubdynasty.ca/media/users/images/38a145b7d2e2428fb4b1a51d2a1cd2d0.webp',
      'https://clubdynasty.ca/media/users/images/4dcda7b6b1a4498e871d1c685ddf93c1.webp',
      'https://clubdynasty.ca/media/users/images/b1f4800034504e77a9a34c87015f2c91.webp',
      'https://clubdynasty.ca/media/users/images/f5502f4a68ce408a97ff8250fb5491fc.webp',
      'https://clubdynasty.ca/media/users/images/682ef30083c04c47bc5d00bf3e734fb4.webp',
      'https://clubdynasty.ca/media/users/images/bdeeafe885574d958914d3a206675068.webp',
      'https://clubdynasty.ca/media/users/images/a0abbbce98754674a92f2bca564ee9da.webp',
      'https://clubdynasty.ca/media/users/images/88e7098d2ce34748acc80289d87c4a2e.webp',
      'https://clubdynasty.ca/media/users/images/f3bc9b224ff74a2891e9a5bcf45bd0fb.webp',
      'https://clubdynasty.ca/media/users/images/9a55c301f9474655966c6d65ae2dd063.webp',
      'https://clubdynasty.ca/media/users/images/daa4d621c69e46abab5aed9b27d80a26.webp',
      'https://clubdynasty.ca/media/users/images/0bcff7e77b14400d8a5fab2c5602ae41.webp',
      'https://clubdynasty.ca/media/users/images/4f548a6ef1ca48ce8b3abbbfdf414a2a.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/c6b0cd9fdb6640a9ab7386d335bf9cc3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5c5fae0ca2bd4b588ae14ccd38268665.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1371c0e91634473c8b254302020061c4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f8902e5a5a5940b8a28b1cfd58c367d9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a92483b230034c968e221a3f5f90d151.webp',
      'https://clubdynasty.ca/media/users/thumbnails/236676af9dc3470f887cdb76f67d19a3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d28ab13ff76845e4a61410d2b1849212.webp',
      'https://clubdynasty.ca/media/users/thumbnails/63e2112cc2394770ac122da09b07e5f9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/54bbbfaae60849dc83d1b3a3ca167151.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9ac637e65a644543b15afbc2230197c2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/162f4c221dc4499f829d074d97bd2551.webp',
      'https://clubdynasty.ca/media/users/thumbnails/21d2245037514f39826051592eba51dd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/38a145b7d2e2428fb4b1a51d2a1cd2d0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4dcda7b6b1a4498e871d1c685ddf93c1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b1f4800034504e77a9a34c87015f2c91.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f5502f4a68ce408a97ff8250fb5491fc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/682ef30083c04c47bc5d00bf3e734fb4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bdeeafe885574d958914d3a206675068.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a0abbbce98754674a92f2bca564ee9da.webp',
      'https://clubdynasty.ca/media/users/thumbnails/88e7098d2ce34748acc80289d87c4a2e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f3bc9b224ff74a2891e9a5bcf45bd0fb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9a55c301f9474655966c6d65ae2dd063.webp',
      'https://clubdynasty.ca/media/users/thumbnails/daa4d621c69e46abab5aed9b27d80a26.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0bcff7e77b14400d8a5fab2c5602ae41.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4f548a6ef1ca48ce8b3abbbfdf414a2a.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/b7192c4b03c34ba69331996c13b6e37e.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/b7192c4b03c34ba69331996c13b6e37e.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 10:00 AM - 6:00 PM',
      'Tue, 07, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Divya',
    age: '28',
    background: 'Indian / Pakistani',
    language: 'English, Urdu',
    technique: 'Sensual',
    tattoos: true,
    height: "5'0",
    measurements: '34B-25-34',
    interests:
      'Self Care, Gym, Amusement Parks, Bowling, Karaoke, Shopping, Restaurants',
    strength: '',
    vaccinated: false,
    id: 26877,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/ad5c55a420cd4307b6a7cd154039a756.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 94,
    updated: '2024-12-27T19:43:53.831236-05:00',
    bio: "Meet Divya, the enchanting beauty of Indian/Pakistani descent. Petite yet strikingly gorgeous, she captivates with her eloquence and intelligence. Whether engaging in deep conversations or delivering an unforgettable sensual massage, Divya's charm and skill leave a lasting impression.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/e0f417b9e4734e04bcc8932f45bc9f51.webp',
      'https://clubdynasty.ca/media/users/images/8f8d69ae8083456782298c51969ff3ea.webp',
      'https://clubdynasty.ca/media/users/images/ba6c839d42ae4be493b8416d7ea8bd76.webp',
      'https://clubdynasty.ca/media/users/images/e68d832f9aeb4cfe9d35d8a70d3a6bf0.webp',
      'https://clubdynasty.ca/media/users/images/9f599fcafa32490e907e57fa959e8326.webp',
      'https://clubdynasty.ca/media/users/images/7110d7676f7e465a8d660c083badad7f.webp',
      'https://clubdynasty.ca/media/users/images/1c877a0a933f44329c07769576a637df.webp',
      'https://clubdynasty.ca/media/users/images/cb0bcfc03f5240a0bec22553626a1073.webp',
      'https://clubdynasty.ca/media/users/images/73ff8533f9de43c0b0ccf77e00ea4afe.webp',
      'https://clubdynasty.ca/media/users/images/bff78e210f8a45d8917fa9ed065ff3be.webp',
      'https://clubdynasty.ca/media/users/images/626231b2e5924fe980ea975e6748e32f.webp',
      'https://clubdynasty.ca/media/users/images/b30bcaf946574370972eade03820d8d6.webp',
      'https://clubdynasty.ca/media/users/images/df5276e63a4a4ffab9895ffd8473255a.webp',
      'https://clubdynasty.ca/media/users/images/74375c1a9fb7457fa7f5f8e80bb700e0.webp',
      'https://clubdynasty.ca/media/users/images/398a720a1f684e8ea809fc399c51dece.webp',
      'https://clubdynasty.ca/media/users/images/ad5c55a420cd4307b6a7cd154039a756.webp',
      'https://clubdynasty.ca/media/users/images/a5dc18a726b545408b562aa5e7836035.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/e0f417b9e4734e04bcc8932f45bc9f51.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8f8d69ae8083456782298c51969ff3ea.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ba6c839d42ae4be493b8416d7ea8bd76.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e68d832f9aeb4cfe9d35d8a70d3a6bf0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9f599fcafa32490e907e57fa959e8326.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7110d7676f7e465a8d660c083badad7f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1c877a0a933f44329c07769576a637df.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cb0bcfc03f5240a0bec22553626a1073.webp',
      'https://clubdynasty.ca/media/users/thumbnails/73ff8533f9de43c0b0ccf77e00ea4afe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bff78e210f8a45d8917fa9ed065ff3be.webp',
      'https://clubdynasty.ca/media/users/thumbnails/626231b2e5924fe980ea975e6748e32f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b30bcaf946574370972eade03820d8d6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/df5276e63a4a4ffab9895ffd8473255a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/74375c1a9fb7457fa7f5f8e80bb700e0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/398a720a1f684e8ea809fc399c51dece.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ad5c55a420cd4307b6a7cd154039a756.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a5dc18a726b545408b562aa5e7836035.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 6:00 PM - 1:00 AM',
      'Thu, 09, Jan 10:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Courtney',
    age: '20',
    background: 'European',
    language: 'English, Russian, Ukrainian',
    technique: 'Sensual',
    tattoos: null,
    height: "5'1",
    measurements: '34C-26-35',
    interests: 'Dancing, Crypto, Cooking, Flooreo',
    strength: '',
    vaccinated: false,
    id: 29905,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/f2b66a522b24406db5679d59c8a5e4c1.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 166,
    updated: '2024-11-21T16:27:25.323189-05:00',
    bio: 'Courtney is a sweet, blonde Slavic girl with a passion for pole dancing. Though new to Club Dynasty, her charm and talent are already making waves. At this elegant venue, she is poised to create unforgettable moments as an erotic massage therapist.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/377ebf2ad81f4321b711644294953c7e.webp',
      'https://clubdynasty.ca/media/users/images/f2b66a522b24406db5679d59c8a5e4c1.webp',
      'https://clubdynasty.ca/media/users/images/23eef9f9c7cd4bad87dd2c7cef98fa69.webp',
      'https://clubdynasty.ca/media/users/images/b62179deb148489f9a296230a5617461.webp',
      'https://clubdynasty.ca/media/users/images/1b2f53fddc2d49f8b2c745a38698af9a.webp',
      'https://clubdynasty.ca/media/users/images/301e4937820f4e349f8de07293f965fe.webp',
      'https://clubdynasty.ca/media/users/images/6e1c7e1449314d8da9f9d03c88bb6229.webp',
      'https://clubdynasty.ca/media/users/images/d18a912363d6411b9e8da4623cf38c3e.webp',
      'https://clubdynasty.ca/media/users/images/c4f926e0ae26406f9f1d17b11b5152a1.webp',
      'https://clubdynasty.ca/media/users/images/17ca2e7406b645df80f02c47f07f4610.webp',
      'https://clubdynasty.ca/media/users/images/25d1f7021e754d64881c8c09c84be1f5.webp',
      'https://clubdynasty.ca/media/users/images/a69991cd704a4d028d2236398ec10b94.webp',
      'https://clubdynasty.ca/media/users/images/8500bfe9e97c4aed937b151aca7f07ac.webp',
      'https://clubdynasty.ca/media/users/images/b1b30db42ae84fee940b9ab1074b762d.webp',
      'https://clubdynasty.ca/media/users/images/50a220450a2f49d5b0874f741479f1f9.webp',
      'https://clubdynasty.ca/media/users/images/ea2c1585af234cdaa53171f7f2d7a5bb.webp',
      'https://clubdynasty.ca/media/users/images/cccd68d2610a4672b92fd879218e23b2.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/377ebf2ad81f4321b711644294953c7e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f2b66a522b24406db5679d59c8a5e4c1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/23eef9f9c7cd4bad87dd2c7cef98fa69.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b62179deb148489f9a296230a5617461.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1b2f53fddc2d49f8b2c745a38698af9a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/301e4937820f4e349f8de07293f965fe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6e1c7e1449314d8da9f9d03c88bb6229.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d18a912363d6411b9e8da4623cf38c3e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c4f926e0ae26406f9f1d17b11b5152a1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/17ca2e7406b645df80f02c47f07f4610.webp',
      'https://clubdynasty.ca/media/users/thumbnails/25d1f7021e754d64881c8c09c84be1f5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a69991cd704a4d028d2236398ec10b94.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8500bfe9e97c4aed937b151aca7f07ac.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b1b30db42ae84fee940b9ab1074b762d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/50a220450a2f49d5b0874f741479f1f9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ea2c1585af234cdaa53171f7f2d7a5bb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cccd68d2610a4672b92fd879218e23b2.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 4:00 PM - 1:00 AM',
      'Wed, 08, Jan 5:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Khloe',
    age: '27',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'4",
    measurements: '32C-25-30',
    interests: 'Yoga, Pole Dancing, Shibari, Cats, Travelling',
    strength: '',
    vaccinated: false,
    id: 30067,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/f24740746310453891418c2a4e9ed663.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 96,
    updated: '2024-12-26T15:34:34.119144-05:00',
    bio: 'Khloe is an open minded and open hearted girl who loves to explore the pleasures of the body and mind. She loves enjoying yoga, pole dancing and exploring her sensuality with shibari and hypnosis. Khloe shares her passion of sensual massage through her mesmerizing eyes, deep breathing, and soft alluring voice, inviting you to join her on this intimate journey together. With a soft, slow, and mindful touch, she creates a raw and erotic experience that entices all of your senses.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/4625b741735f40599420d154506d1f6e.webp',
      'https://clubdynasty.ca/media/users/images/3374ed15522a4d7c9ce4cd5e6c57bbe7.webp',
      'https://clubdynasty.ca/media/users/images/5acc11ccdec6414db6cf5538b6b51512.webp',
      'https://clubdynasty.ca/media/users/images/f24740746310453891418c2a4e9ed663.webp',
      'https://clubdynasty.ca/media/users/images/90c60abbdd174d6180507a4e8e06925e.webp',
      'https://clubdynasty.ca/media/users/images/567cb657f88e4f2ebe02088324ea61a3.webp',
      'https://clubdynasty.ca/media/users/images/2c5f6ce4bbcd41bcb89d473606d2901d.webp',
      'https://clubdynasty.ca/media/users/images/9295759d28b2421a8fb2f2eba29d56dd.webp',
      'https://clubdynasty.ca/media/users/images/1e55becf57f549e1a8f5aac897302f8b.webp',
      'https://clubdynasty.ca/media/users/images/35dfabcfc7554b27857f3390dda344a5.webp',
      'https://clubdynasty.ca/media/users/images/8d0c250ea37a43ddb443391624c79674.webp',
      'https://clubdynasty.ca/media/users/images/8478d99829ea4b408109767b03a2b093.webp',
      'https://clubdynasty.ca/media/users/images/d0d7295cdd624dff97c70997d80bac3c.webp',
      'https://clubdynasty.ca/media/users/images/cf6230300ed44c25b4d4d516b4d9ef11.webp',
      'https://clubdynasty.ca/media/users/images/9728214d89964aceaaf1650ebdc0758a.webp',
      'https://clubdynasty.ca/media/users/images/ab74d999e3234586b1a8725c484ecacf.webp',
      'https://clubdynasty.ca/media/users/images/fd61f984a35448388a697a8d8b4bf837.webp',
      'https://clubdynasty.ca/media/users/images/fe42c53b88c940469f8cf0234d40b9e3.webp',
      'https://clubdynasty.ca/media/users/images/bfe18f57c5f94e558d790868b1e88f7f.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/4625b741735f40599420d154506d1f6e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3374ed15522a4d7c9ce4cd5e6c57bbe7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5acc11ccdec6414db6cf5538b6b51512.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f24740746310453891418c2a4e9ed663.webp',
      'https://clubdynasty.ca/media/users/thumbnails/90c60abbdd174d6180507a4e8e06925e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/567cb657f88e4f2ebe02088324ea61a3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2c5f6ce4bbcd41bcb89d473606d2901d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9295759d28b2421a8fb2f2eba29d56dd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1e55becf57f549e1a8f5aac897302f8b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/35dfabcfc7554b27857f3390dda344a5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8d0c250ea37a43ddb443391624c79674.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8478d99829ea4b408109767b03a2b093.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d0d7295cdd624dff97c70997d80bac3c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cf6230300ed44c25b4d4d516b4d9ef11.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9728214d89964aceaaf1650ebdc0758a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ab74d999e3234586b1a8725c484ecacf.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fd61f984a35448388a697a8d8b4bf837.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fe42c53b88c940469f8cf0234d40b9e3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/bfe18f57c5f94e558d790868b1e88f7f.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Thu, 09, Jan 10:00 AM - 7:00 PM',
      'Sun, 12, Jan 12:00 PM - 8:00 PM',
    ],
  },
  {
    name: 'Selena',
    age: '26',
    background: 'Peruvian',
    language: 'English, Spanish',
    technique: 'Sensual',
    tattoos: null,
    height: "5'4",
    measurements: '36C-28-36',
    interests: 'Hiking, Reading, Acting, Boating, Travelling',
    strength: '',
    vaccinated: false,
    id: 31064,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/85f37595269a4cdaa5f8e153f3889ea8.webp',
    vacation: false,
    survey_average: 9.6,
    survey_count: 64,
    updated: '2025-01-04T17:32:27.232446-05:00',
    bio: "Selena's zest for life is as vibrant as her passion for outdoor adventures, acting, and modeling. She thrives on meeting people from all walks of life, and while she might come across as a bit reserved initially, she quickly melts into warm, engaging conversation. She's always excited to learn, connect, and charm.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/c9c38d92c91f4e91bac562838af0220e.webp',
      'https://clubdynasty.ca/media/users/images/25a6363bdf274d8a982c8ee4a9ab6dc1.webp',
      'https://clubdynasty.ca/media/users/images/acbe70f4c1944630ab306cdfc4aa3a56.webp',
      'https://clubdynasty.ca/media/users/images/d5da35dfd7104ccd90608fb91a9af0a1.webp',
      'https://clubdynasty.ca/media/users/images/275f43ce0852493a8588ac1eb8870b53.webp',
      'https://clubdynasty.ca/media/users/images/40fdc8eabd5843358c97c5abca8bbbc3.webp',
      'https://clubdynasty.ca/media/users/images/ea3e7b4ce41d4da5991e9022e115c5c8.webp',
      'https://clubdynasty.ca/media/users/images/107ffddfbe1541a091c09c0d0257569d.webp',
      'https://clubdynasty.ca/media/users/images/a4ecd7734541463ca4a01a0b75ccdfa2.webp',
      'https://clubdynasty.ca/media/users/images/0ea1c97dc8224ecb9c840541c7f454c5.webp',
      'https://clubdynasty.ca/media/users/images/2066ce58e1b747c4897ff82e85941578.webp',
      'https://clubdynasty.ca/media/users/images/d00b787ef443404dbac2919fdee01cfa.webp',
      'https://clubdynasty.ca/media/users/images/b5b431fd5fc5468faf3fa1444445ed10.webp',
      'https://clubdynasty.ca/media/users/images/6d15ae3719d543478a0781444e7320e2.webp',
      'https://clubdynasty.ca/media/users/images/c902084f09db40b08e8d33f8a562ca70.webp',
      'https://clubdynasty.ca/media/users/images/843967d52f93425db496e84d5b06e1ab.webp',
      'https://clubdynasty.ca/media/users/images/c8ea400ac5d74e9ab718cf2621848264.webp',
      'https://clubdynasty.ca/media/users/images/5c4c2a746b644daaabc0fb5e2d082640.webp',
      'https://clubdynasty.ca/media/users/images/c47f0bf816ce49bd80a45077f5cd52be.webp',
      'https://clubdynasty.ca/media/users/images/231e295735b4462d9bf3251c2d06beb5.webp',
      'https://clubdynasty.ca/media/users/images/85f37595269a4cdaa5f8e153f3889ea8.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/c9c38d92c91f4e91bac562838af0220e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/25a6363bdf274d8a982c8ee4a9ab6dc1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/acbe70f4c1944630ab306cdfc4aa3a56.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d5da35dfd7104ccd90608fb91a9af0a1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/275f43ce0852493a8588ac1eb8870b53.webp',
      'https://clubdynasty.ca/media/users/thumbnails/40fdc8eabd5843358c97c5abca8bbbc3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ea3e7b4ce41d4da5991e9022e115c5c8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/107ffddfbe1541a091c09c0d0257569d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a4ecd7734541463ca4a01a0b75ccdfa2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0ea1c97dc8224ecb9c840541c7f454c5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2066ce58e1b747c4897ff82e85941578.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d00b787ef443404dbac2919fdee01cfa.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b5b431fd5fc5468faf3fa1444445ed10.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6d15ae3719d543478a0781444e7320e2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c902084f09db40b08e8d33f8a562ca70.webp',
      'https://clubdynasty.ca/media/users/thumbnails/843967d52f93425db496e84d5b06e1ab.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c8ea400ac5d74e9ab718cf2621848264.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5c4c2a746b644daaabc0fb5e2d082640.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c47f0bf816ce49bd80a45077f5cd52be.webp',
      'https://clubdynasty.ca/media/users/thumbnails/231e295735b4462d9bf3251c2d06beb5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/85f37595269a4cdaa5f8e153f3889ea8.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 12:00 PM - 7:00 PM',
      'Thu, 09, Jan 11:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Melanie',
    age: '28',
    background: 'Filipina',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'4",
    measurements: '32B-25-35',
    interests:
      'Music, Shopping, Thriller Movies, Fine Dining, Adventures, Photography',
    strength: '',
    vaccinated: false,
    id: 32619,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/e85d21217d9b488b8faa7ddd943cdce4.webp',
    vacation: false,
    survey_average: 9.5,
    survey_count: 20,
    updated: '2024-12-30T20:53:49.369826-05:00',
    bio: "Meet Melanie: a petite Filipina sweetheart who's always ready for an adventure. With her playful spirit and love for cheeky conversations, she brings excitement and charm to every moment. Get ready for some fun!",
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/f8a751ca06eb42209dccd7d95cbba118.webp',
      'https://clubdynasty.ca/media/users/images/e85d21217d9b488b8faa7ddd943cdce4.webp',
      'https://clubdynasty.ca/media/users/images/fb3c1cbb86b54458a319f87abe291457.webp',
      'https://clubdynasty.ca/media/users/images/80346dfda4394f5b879a408089e3bf79.webp',
      'https://clubdynasty.ca/media/users/images/75664b0de3c64b47b09a32ffb03776de.webp',
      'https://clubdynasty.ca/media/users/images/7d891b05a8d648b89f1624d3b3547b2e.webp',
      'https://clubdynasty.ca/media/users/images/3ef10bfe1ecf44acb24a35c72dcd2f00.webp',
      'https://clubdynasty.ca/media/users/images/627e01b999e649f082d99e18218b6e9b.webp',
      'https://clubdynasty.ca/media/users/images/171123e6e10743e5bcf0f52de34f90c0.webp',
      'https://clubdynasty.ca/media/users/images/d61a111cc6844706b34ca324f64b931e.webp',
      'https://clubdynasty.ca/media/users/images/69465bf79dbe497ea2e39fee27e310d6.webp',
      'https://clubdynasty.ca/media/users/images/0a452753392d4708b4fd6fe5179c32d5.webp',
      'https://clubdynasty.ca/media/users/images/fb74b9c8d1e348a69ef952c0bbdf4bcb.webp',
      'https://clubdynasty.ca/media/users/images/74ce00a621b34e95a713da9e5aa1b7ea.webp',
      'https://clubdynasty.ca/media/users/images/d6e7572a3e7345e8afffe5bbf22281c5.webp',
      'https://clubdynasty.ca/media/users/images/897a12e129624801aa3c7c38c50281c2.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/f8a751ca06eb42209dccd7d95cbba118.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e85d21217d9b488b8faa7ddd943cdce4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fb3c1cbb86b54458a319f87abe291457.webp',
      'https://clubdynasty.ca/media/users/thumbnails/80346dfda4394f5b879a408089e3bf79.webp',
      'https://clubdynasty.ca/media/users/thumbnails/75664b0de3c64b47b09a32ffb03776de.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7d891b05a8d648b89f1624d3b3547b2e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3ef10bfe1ecf44acb24a35c72dcd2f00.webp',
      'https://clubdynasty.ca/media/users/thumbnails/627e01b999e649f082d99e18218b6e9b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/171123e6e10743e5bcf0f52de34f90c0.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d61a111cc6844706b34ca324f64b931e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/69465bf79dbe497ea2e39fee27e310d6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0a452753392d4708b4fd6fe5179c32d5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fb74b9c8d1e348a69ef952c0bbdf4bcb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/74ce00a621b34e95a713da9e5aa1b7ea.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d6e7572a3e7345e8afffe5bbf22281c5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/897a12e129624801aa3c7c38c50281c2.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: ['Mon, 06, Jan 6:00 PM - 12:00 AM'],
  },
  {
    name: 'Miley',
    age: '27',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'8",
    measurements: '34C-31-37',
    interests: 'Dancing, Mental Health, Photography, Modelling, Baking',
    strength: '',
    vaccinated: false,
    id: 32903,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/4a0e7fd7571b4cf784ea1d77fdbae34d.webp',
    vacation: false,
    survey_average: 9.2,
    survey_count: 17,
    updated: '2024-12-30T20:53:56.595495-05:00',
    bio: "Miley is a captivating tall blonde with a natural allure. She's not just a beauty but a heart full of empathy and compassion. Creative to the core, Miley loves helping others, dancing like nobody’s watching, and chasing thrills that set her free. Always ready to embrace the wild side, she’s the perfect partner for an adventure.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/fd08ca3c08ce4d94b6e1d4d29e52969d.webp',
      'https://clubdynasty.ca/media/users/images/4a0e7fd7571b4cf784ea1d77fdbae34d.webp',
      'https://clubdynasty.ca/media/users/images/420403cb6bbb4125b3ec11e5de9b15aa.webp',
      'https://clubdynasty.ca/media/users/images/aecfe32b237a42dda26d5166ffc4cdfe.webp',
      'https://clubdynasty.ca/media/users/images/fd519e3b6956464bbf82e89e095264fb.webp',
      'https://clubdynasty.ca/media/users/images/05a4c21da0bb49a7acf02a03c1d8b42b.webp',
      'https://clubdynasty.ca/media/users/images/2273450d741d492091e18ea6cdab82d7.webp',
      'https://clubdynasty.ca/media/users/images/ab82da80adeb45c686ee6dc39a17a0c2.webp',
      'https://clubdynasty.ca/media/users/images/80b55576844c43adacdda19734d2a6ec.webp',
      'https://clubdynasty.ca/media/users/images/cb0c5aede4204479b2106f269e35c37e.webp',
      'https://clubdynasty.ca/media/users/images/9c0a70e56c0f441fb586a94e9180ff79.webp',
      'https://clubdynasty.ca/media/users/images/67039d0103b64a0eb99f04a04a462c35.webp',
      'https://clubdynasty.ca/media/users/images/a55f40c6e46e4804af6b56016b31ae78.webp',
      'https://clubdynasty.ca/media/users/images/f358a7e670784e4fb735af11a922a710.webp',
      'https://clubdynasty.ca/media/users/images/e1d2a180aab94a7cb285d5d239bac3c4.webp',
      'https://clubdynasty.ca/media/users/images/58c16377d2194de4b86d9331ceee50f7.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/fd08ca3c08ce4d94b6e1d4d29e52969d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4a0e7fd7571b4cf784ea1d77fdbae34d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/420403cb6bbb4125b3ec11e5de9b15aa.webp',
      'https://clubdynasty.ca/media/users/thumbnails/aecfe32b237a42dda26d5166ffc4cdfe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fd519e3b6956464bbf82e89e095264fb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/05a4c21da0bb49a7acf02a03c1d8b42b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2273450d741d492091e18ea6cdab82d7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ab82da80adeb45c686ee6dc39a17a0c2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/80b55576844c43adacdda19734d2a6ec.webp',
      'https://clubdynasty.ca/media/users/thumbnails/cb0c5aede4204479b2106f269e35c37e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9c0a70e56c0f441fb586a94e9180ff79.webp',
      'https://clubdynasty.ca/media/users/thumbnails/67039d0103b64a0eb99f04a04a462c35.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a55f40c6e46e4804af6b56016b31ae78.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f358a7e670784e4fb735af11a922a710.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e1d2a180aab94a7cb285d5d239bac3c4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/58c16377d2194de4b86d9331ceee50f7.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 6:00 PM - 1:00 AM',
      'Sat, 11, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Layla',
    age: '32',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'6",
    measurements: '36C-26-34',
    interests: 'Food, Music, Sports, Cooking, Travel, Poetry',
    strength: '',
    vaccinated: false,
    id: 32904,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/fee45d2c15cf4c1484c66b02ee497faa.webp',
    vacation: false,
    survey_average: 9.4,
    survey_count: 34,
    updated: '2024-12-27T20:05:57.939646-05:00',
    bio: "Layla radiates positivity and an inviting energy that instantly makes you feel at ease. With her sensual and magical touch, you'll be left wanting more. She has a gorgeous golden skin tone, long flowing brown hair, and a fit, busty figure complemented by her irresistibly soft skin.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/71de1af6cab249f4865ccb7b33b33b04.webp',
      'https://clubdynasty.ca/media/users/images/4b8de5ba90924323b1c120f42a880e4e.webp',
      'https://clubdynasty.ca/media/users/images/ba84ce5b001642018b08054b4e6fc660.webp',
      'https://clubdynasty.ca/media/users/images/fc32a18c378b4857b52a9d65a181b51c.webp',
      'https://clubdynasty.ca/media/users/images/f5ada5033db64805a1869ba0cfb784aa.webp',
      'https://clubdynasty.ca/media/users/images/c45c0a4f569441f98d9343a8ddd289f2.webp',
      'https://clubdynasty.ca/media/users/images/7343595aba2d48acafa21b0c637e985d.webp',
      'https://clubdynasty.ca/media/users/images/f05fa0a864e54e9ba0deeacfc78c4f6a.webp',
      'https://clubdynasty.ca/media/users/images/7f4a367e55734a4caf08ca3beb61c688.webp',
      'https://clubdynasty.ca/media/users/images/c326febfb3774ca9b29f6ad3a98101fb.webp',
      'https://clubdynasty.ca/media/users/images/5bff991593f641d6a2f802796127152c.webp',
      'https://clubdynasty.ca/media/users/images/2c78397d907e499ea325ecf1cb39bd74.webp',
      'https://clubdynasty.ca/media/users/images/fee45d2c15cf4c1484c66b02ee497faa.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/71de1af6cab249f4865ccb7b33b33b04.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4b8de5ba90924323b1c120f42a880e4e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ba84ce5b001642018b08054b4e6fc660.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fc32a18c378b4857b52a9d65a181b51c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f5ada5033db64805a1869ba0cfb784aa.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c45c0a4f569441f98d9343a8ddd289f2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7343595aba2d48acafa21b0c637e985d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f05fa0a864e54e9ba0deeacfc78c4f6a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7f4a367e55734a4caf08ca3beb61c688.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c326febfb3774ca9b29f6ad3a98101fb.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5bff991593f641d6a2f802796127152c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2c78397d907e499ea325ecf1cb39bd74.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fee45d2c15cf4c1484c66b02ee497faa.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 6:00 PM - 1:00 AM',
      'Thu, 09, Jan 12:00 PM - 6:00 PM',
    ],
  },
  {
    name: 'Gemma',
    age: '25',
    background: 'Italian',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'7",
    measurements: '34B-26-32',
    interests: 'Dancing, Meeting People, Travel, Animals, Movies',
    strength: '',
    vaccinated: false,
    id: 34141,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/f9fa2cb616e148458bf4c17ebcab8343.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 24,
    updated: '2024-12-23T18:04:47.784989-05:00',
    bio: "Meet Gemma, a delightful presence with a radiant smile and a bubbly personality. If you're seeking a genuine connection, Gemma is the perfect choice. She’s not only a great listener but also a friend, offering a soothing touch that will leave you smiling every time. Gemma looks forward to the opportunity to meet you and create an unforgettable experience.",
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/3e6fd398c2ef40459493ca41d0b78aac.webp',
      'https://clubdynasty.ca/media/users/images/e68cb8371f31477ea7f75307d03af46d.webp',
      'https://clubdynasty.ca/media/users/images/3cb31eabc54940d6862a865e9a744f79.webp',
      'https://clubdynasty.ca/media/users/images/ff919135fc6f4b09a1c24d7178e0b2f5.webp',
      'https://clubdynasty.ca/media/users/images/961a3d451d8f4bb7a3ad2151e9685e97.webp',
      'https://clubdynasty.ca/media/users/images/f9fa2cb616e148458bf4c17ebcab8343.webp',
      'https://clubdynasty.ca/media/users/images/956c4521c253464da3352c69e108b2b1.webp',
      'https://clubdynasty.ca/media/users/images/0bc8d154faec44e2959b187f99901579.webp',
      'https://clubdynasty.ca/media/users/images/149517345da24ae2955927ab4ed9cb27.webp',
      'https://clubdynasty.ca/media/users/images/1071225acbad4442881982f484c5e92b.webp',
      'https://clubdynasty.ca/media/users/images/5500653c99e24318bf78b67ebfd96939.webp',
      'https://clubdynasty.ca/media/users/images/d64a84cb472e43518a899be67bb54a62.webp',
      'https://clubdynasty.ca/media/users/images/1d1db6e5bb504f039fa1f36c708808fe.webp',
      'https://clubdynasty.ca/media/users/images/22a8ec682ab549a9a68f6daa687b20ef.webp',
      'https://clubdynasty.ca/media/users/images/290fd62ed5ea44169029b66ca5b0ad44.webp',
      'https://clubdynasty.ca/media/users/images/850fee23c556433cbc291113f94901fd.webp',
      'https://clubdynasty.ca/media/users/images/42f6ed6c331f4284a58335b70e2efb11.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/3e6fd398c2ef40459493ca41d0b78aac.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e68cb8371f31477ea7f75307d03af46d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3cb31eabc54940d6862a865e9a744f79.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ff919135fc6f4b09a1c24d7178e0b2f5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/961a3d451d8f4bb7a3ad2151e9685e97.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f9fa2cb616e148458bf4c17ebcab8343.webp',
      'https://clubdynasty.ca/media/users/thumbnails/956c4521c253464da3352c69e108b2b1.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0bc8d154faec44e2959b187f99901579.webp',
      'https://clubdynasty.ca/media/users/thumbnails/149517345da24ae2955927ab4ed9cb27.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1071225acbad4442881982f484c5e92b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5500653c99e24318bf78b67ebfd96939.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d64a84cb472e43518a899be67bb54a62.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1d1db6e5bb504f039fa1f36c708808fe.webp',
      'https://clubdynasty.ca/media/users/thumbnails/22a8ec682ab549a9a68f6daa687b20ef.webp',
      'https://clubdynasty.ca/media/users/thumbnails/290fd62ed5ea44169029b66ca5b0ad44.webp',
      'https://clubdynasty.ca/media/users/thumbnails/850fee23c556433cbc291113f94901fd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/42f6ed6c331f4284a58335b70e2efb11.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 4:00 PM - 1:00 AM',
      'Wed, 08, Jan 6:00 PM - 1:00 AM',
    ],
  },
  {
    name: 'Pixy',
    age: '34',
    background: 'Colombian',
    language: 'English, Spanish',
    technique: 'Sensual',
    tattoos: true,
    height: "5'5",
    measurements: '34DD-26-39',
    interests: 'Nature, Mediation, Hiking, Reading, Gym',
    strength: '',
    vaccinated: false,
    id: 34871,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/11c94a16f59e4c6aa0d13c326e4954f4.webp',
    vacation: false,
    survey_average: 9.5,
    survey_count: 37,
    updated: '2024-12-17T18:03:37.410117-05:00',
    bio: 'Pixy is a little mysterious, with charm that pulls you in without trying. Sweet and effortlessly captivating, she’s easy to talk to and has a sharp mind that surprises and delights. Her real, down-to-earth charisma makes every moment spent with her unforgettable. Pixy is the kind of companion whose natural allure leaves you enchanted, and whose genuine spirit invites you to stay just a little longer. Get ready to experience the kind of connection that’s both playful and profound.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Red',
    profile_visible: true,
    allow_lingam: true,
    sensual90: false,
    images: [
      'https://clubdynasty.ca/media/users/images/2f707cbe04c04421835777eb3e5e2fec.webp',
      'https://clubdynasty.ca/media/users/images/8d8e1780342e4fc6a8a821c03824a008.webp',
      'https://clubdynasty.ca/media/users/images/eb8d113afe6f47509ad55722e7df2f75.webp',
      'https://clubdynasty.ca/media/users/images/11c94a16f59e4c6aa0d13c326e4954f4.webp',
      'https://clubdynasty.ca/media/users/images/2dd17285a1424499bd95d86bc88395dd.webp',
      'https://clubdynasty.ca/media/users/images/9af791ce9d0c46e290c7ff6c62172a33.webp',
      'https://clubdynasty.ca/media/users/images/9ba09e1ba7b04af9bffb62a6470e7950.webp',
      'https://clubdynasty.ca/media/users/images/a4bdae0a9e0c4e84bcf172fdddb01229.webp',
      'https://clubdynasty.ca/media/users/images/0c54fc7e383248548b8c4c9d3bdb08a2.webp',
      'https://clubdynasty.ca/media/users/images/7a4231f2f83f4136a765ef055e48b9e5.webp',
      'https://clubdynasty.ca/media/users/images/3880b790aa5d42b9be22bd863b26afb1.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/2f707cbe04c04421835777eb3e5e2fec.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8d8e1780342e4fc6a8a821c03824a008.webp',
      'https://clubdynasty.ca/media/users/thumbnails/eb8d113afe6f47509ad55722e7df2f75.webp',
      'https://clubdynasty.ca/media/users/thumbnails/11c94a16f59e4c6aa0d13c326e4954f4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2dd17285a1424499bd95d86bc88395dd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9af791ce9d0c46e290c7ff6c62172a33.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9ba09e1ba7b04af9bffb62a6470e7950.webp',
      'https://clubdynasty.ca/media/users/thumbnails/a4bdae0a9e0c4e84bcf172fdddb01229.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0c54fc7e383248548b8c4c9d3bdb08a2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7a4231f2f83f4136a765ef055e48b9e5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3880b790aa5d42b9be22bd863b26afb1.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 10:00 AM - 6:00 PM',
      'Tue, 07, Jan 10:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Iris',
    age: '24',
    background: 'Irish / Filipina',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'2",
    measurements: '32B-25-34',
    interests: 'Yoga, Music, Movies, Cooking,Sports',
    strength: '',
    vaccinated: false,
    id: 35289,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/2d5e78df5c624d06980e31b709c57fd5.webp',
    vacation: false,
    survey_average: 10.0,
    survey_count: 6,
    updated: '2024-12-15T13:11:34.086253-05:00',
    bio: 'Iris is a fun-loving, adventurous, and free-spirited soul who brings a joyful energy wherever she goes. She loves to laugh and ensures everyone around her is having a great time and feeling their absolute best. With her caring nature, Iris is always there to listen, offering genuine warmth and understanding. Whether it’s sharing a laugh or lending an empathetic ear, Iris knows how to make every moment memorable and uplifting.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown / Blonde',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/0a7d98aa715e445298d4209ce8e7d3af.webp',
      'https://clubdynasty.ca/media/users/images/c88dad8072f44bdf99bdb40f2a51c4b9.webp',
      'https://clubdynasty.ca/media/users/images/2d5e78df5c624d06980e31b709c57fd5.webp',
      'https://clubdynasty.ca/media/users/images/0f80ca7456af4dbebf8396f127cc3ea3.webp',
      'https://clubdynasty.ca/media/users/images/75f6ba0608a24d82b7d9934c5a93e8dc.webp',
      'https://clubdynasty.ca/media/users/images/7363ae942241454cb5fb32d391d98f09.webp',
      'https://clubdynasty.ca/media/users/images/4edd8c93be614405b7c08ed13bdb33f6.webp',
      'https://clubdynasty.ca/media/users/images/6ea499df52854c818d401b592db194e5.webp',
      'https://clubdynasty.ca/media/users/images/9b039e8e428f41968678facecd6a64f4.webp',
      'https://clubdynasty.ca/media/users/images/3aaa6bdfdb0f4871b0483fd4c962e62a.webp',
      'https://clubdynasty.ca/media/users/images/3d60b57404d047ae9af5eb5f2fdc2363.webp',
      'https://clubdynasty.ca/media/users/images/e092faf4711541c68fa1a80b8d3283b2.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/0a7d98aa715e445298d4209ce8e7d3af.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c88dad8072f44bdf99bdb40f2a51c4b9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/2d5e78df5c624d06980e31b709c57fd5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0f80ca7456af4dbebf8396f127cc3ea3.webp',
      'https://clubdynasty.ca/media/users/thumbnails/75f6ba0608a24d82b7d9934c5a93e8dc.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7363ae942241454cb5fb32d391d98f09.webp',
      'https://clubdynasty.ca/media/users/thumbnails/4edd8c93be614405b7c08ed13bdb33f6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6ea499df52854c818d401b592db194e5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9b039e8e428f41968678facecd6a64f4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3aaa6bdfdb0f4871b0483fd4c962e62a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3d60b57404d047ae9af5eb5f2fdc2363.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e092faf4711541c68fa1a80b8d3283b2.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: ['Thu, 09, Jan 6:00 PM - 1:00 AM'],
  },
  {
    name: 'Kassy',
    age: '28',
    background: 'Canadian',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'0",
    measurements: '32B-30-46',
    interests: 'Yoga, Cooking, Painting, Dance, Video Games, Learning',
    strength: '',
    vaccinated: false,
    id: 35420,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/54d1c4c6ec7049bc8d6ca248c3d7addd.webp',
    vacation: false,
    survey_average: 9.8,
    survey_count: 12,
    updated: '2024-12-30T15:46:57.626650-05:00',
    bio: "Magnetic and Alluring, Kassy radiates a charm that's simply irresistible. Her captivating energy and attentive nature will leave you enchanted, while her magical touch is nothing short of mesmerizing. Bold yet down-to-earth, Kassy is the perfect mix of sexy and sweet—you'll find yourself coming back for more!",
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/a2cf988f06f04515a4d684c98950da74.webp',
      'https://clubdynasty.ca/media/users/images/93271cdf859c4369bdeb9f43d7702031.webp',
      'https://clubdynasty.ca/media/users/images/ac851ff5995a4188a2d308ccc33a6584.webp',
      'https://clubdynasty.ca/media/users/images/b1db924eb4ed4c628473ee410799009d.webp',
      'https://clubdynasty.ca/media/users/images/54d1c4c6ec7049bc8d6ca248c3d7addd.webp',
      'https://clubdynasty.ca/media/users/images/63866654b2f2488a919b4d64c4b5e068.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/a2cf988f06f04515a4d684c98950da74.webp',
      'https://clubdynasty.ca/media/users/thumbnails/93271cdf859c4369bdeb9f43d7702031.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ac851ff5995a4188a2d308ccc33a6584.webp',
      'https://clubdynasty.ca/media/users/thumbnails/b1db924eb4ed4c628473ee410799009d.webp',
      'https://clubdynasty.ca/media/users/thumbnails/54d1c4c6ec7049bc8d6ca248c3d7addd.webp',
      'https://clubdynasty.ca/media/users/thumbnails/63866654b2f2488a919b4d64c4b5e068.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Mon, 06, Jan 6:00 PM - 1:00 AM',
      'Sun, 12, Jan 10:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Ava',
    age: '25',
    background: 'Chinese',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'1",
    measurements: '32C-27-34',
    interests: 'Rock Climbing, Gaming, Movies, Raving, Travelling',
    strength: '',
    vaccinated: false,
    id: 35725,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/1365af16ecd0454cb790289f51f1ee10.webp',
    vacation: false,
    survey_average: 9.9,
    survey_count: 25,
    updated: '2025-01-02T22:30:54.878612-05:00',
    bio: 'Ava is as sweet as she is petite, with a surprising strength and a knack for connecting over her love of movies, climbing, and gaming. Her playful humor and sparkling personality ensure she’s always the life of the conversation.',
    new: false,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: true,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/d1f364961918478682a67b5559787006.webp',
      'https://clubdynasty.ca/media/users/images/d6beeecad61d4061b18f957e0986140f.webp',
      'https://clubdynasty.ca/media/users/images/1365af16ecd0454cb790289f51f1ee10.webp',
      'https://clubdynasty.ca/media/users/images/f4a19e98760d471cbebb4744b10c132b.webp',
      'https://clubdynasty.ca/media/users/images/60ae986f10d241d1a6993954caabab84.webp',
      'https://clubdynasty.ca/media/users/images/649796de464b46f988d1b514ee714053.webp',
      'https://clubdynasty.ca/media/users/images/7dcb4527223043d28e76e47c6ed6ce27.webp',
      'https://clubdynasty.ca/media/users/images/9d14ad533fdb4c299e984c24894cf090.webp',
      'https://clubdynasty.ca/media/users/images/1654dbfdce97403bbb51cd83767d43f7.webp',
      'https://clubdynasty.ca/media/users/images/f2e6738afc0a46a38f3eb2b093dbe8bb.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/d1f364961918478682a67b5559787006.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d6beeecad61d4061b18f957e0986140f.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1365af16ecd0454cb790289f51f1ee10.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f4a19e98760d471cbebb4744b10c132b.webp',
      'https://clubdynasty.ca/media/users/thumbnails/60ae986f10d241d1a6993954caabab84.webp',
      'https://clubdynasty.ca/media/users/thumbnails/649796de464b46f988d1b514ee714053.webp',
      'https://clubdynasty.ca/media/users/thumbnails/7dcb4527223043d28e76e47c6ed6ce27.webp',
      'https://clubdynasty.ca/media/users/thumbnails/9d14ad533fdb4c299e984c24894cf090.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1654dbfdce97403bbb51cd83767d43f7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/f2e6738afc0a46a38f3eb2b093dbe8bb.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Tue, 07, Jan 4:00 PM - 1:00 AM',
      'Wed, 08, Jan 10:00 AM - 5:00 PM',
    ],
  },
  {
    name: 'Angel',
    age: '25',
    background: 'Canadian / Irish',
    language: 'English',
    technique: 'Sensual',
    tattoos: true,
    height: "5'7",
    measurements: '36DD-29-35',
    interests: 'Surf & Turf, Boxing, Travelling, Shopping, Painting, Macaroons',
    strength: '',
    vaccinated: false,
    id: 36438,
    services: 'Men ♂️ & Women ♀️',
    image:
      'https://clubdynasty.ca/media/users/images/8ceed346aa5b43068c075c38b07a798c.webp',
    vacation: false,
    survey_average: 10.0,
    survey_count: 1,
    updated: '2025-01-06T17:17:46.572265-05:00',
    bio: 'Angel grew up on the East Coast, blending her small-town charm with a passion for the energy of the city. A traveler at heart, she’s developed a foodie’s palate and a love for shopping, but she’s just as happy staying in for a show-binge night. In her downtime, Angel expresses her creativity through painting and enjoys peaceful hikes with her loyal pup by her side',
    new: false,
    bust_enhanced: false,
    hair_color: 'Black',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/fa03335351554d2ca7da0dff1258738a.webp',
      'https://clubdynasty.ca/media/users/images/984b7ec4d5314f91bbf5bbcd7c4b5e87.webp',
      'https://clubdynasty.ca/media/users/images/adb673776b524039884b7647317edbc6.webp',
      'https://clubdynasty.ca/media/users/images/991e9a9652b745f686b0ee591423b857.webp',
      'https://clubdynasty.ca/media/users/images/ff4c43a3aa744955b002dd089938ea75.webp',
      'https://clubdynasty.ca/media/users/images/e02797f30877470a9468c957175f6f59.webp',
      'https://clubdynasty.ca/media/users/images/376ba79a8f1845489a141f643c0644aa.webp',
      'https://clubdynasty.ca/media/users/images/e85734ded6ac4d74891bcd36f2c82a61.webp',
      'https://clubdynasty.ca/media/users/images/69b9f3868e364caa9c69e99028e1a1f9.webp',
      'https://clubdynasty.ca/media/users/images/3405aeeaadd741c59ba465f9078b538e.webp',
      'https://clubdynasty.ca/media/users/images/51d9a630f46c48a19baf4371130b47a5.webp',
      'https://clubdynasty.ca/media/users/images/00ace8bb76ee46faacf10bbafa05ffca.webp',
      'https://clubdynasty.ca/media/users/images/18659ddefd0c4f95a1d79df30f1d7f77.webp',
      'https://clubdynasty.ca/media/users/images/8ceed346aa5b43068c075c38b07a798c.webp',
      'https://clubdynasty.ca/media/users/images/6cdede3ddf644f1d83ad922430f7b81e.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/fa03335351554d2ca7da0dff1258738a.webp',
      'https://clubdynasty.ca/media/users/thumbnails/984b7ec4d5314f91bbf5bbcd7c4b5e87.webp',
      'https://clubdynasty.ca/media/users/thumbnails/adb673776b524039884b7647317edbc6.webp',
      'https://clubdynasty.ca/media/users/thumbnails/991e9a9652b745f686b0ee591423b857.webp',
      'https://clubdynasty.ca/media/users/thumbnails/ff4c43a3aa744955b002dd089938ea75.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e02797f30877470a9468c957175f6f59.webp',
      'https://clubdynasty.ca/media/users/thumbnails/376ba79a8f1845489a141f643c0644aa.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e85734ded6ac4d74891bcd36f2c82a61.webp',
      'https://clubdynasty.ca/media/users/thumbnails/69b9f3868e364caa9c69e99028e1a1f9.webp',
      'https://clubdynasty.ca/media/users/thumbnails/3405aeeaadd741c59ba465f9078b538e.webp',
      'https://clubdynasty.ca/media/users/thumbnails/51d9a630f46c48a19baf4371130b47a5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/00ace8bb76ee46faacf10bbafa05ffca.webp',
      'https://clubdynasty.ca/media/users/thumbnails/18659ddefd0c4f95a1d79df30f1d7f77.webp',
      'https://clubdynasty.ca/media/users/thumbnails/8ceed346aa5b43068c075c38b07a798c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/6cdede3ddf644f1d83ad922430f7b81e.webp',
    ],
    tv_images: [
      'https://clubdynasty.ca/media/users/images/5dee8f389c034067b9485d9da35ef675.webp',
    ],
    tv_thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/5dee8f389c034067b9485d9da35ef675.webp',
    ],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 11:00 AM - 6:00 PM',
      'Fri, 10, Jan 11:00 AM - 6:00 PM',
    ],
  },
  {
    name: 'Petra',
    age: '24',
    background: 'European',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'3",
    measurements: '32C-26-34',
    interests: 'Food, Yoga, Reading, Gym, Travel',
    strength: '',
    vaccinated: false,
    id: 37393,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/def8b6ca66f148c9b687cb3a6357bcdc.webp',
    vacation: false,
    survey_average: 10.0,
    survey_count: 1,
    updated: '2024-12-17T16:55:54.494123-05:00',
    bio: 'Petra is an all-natural beauty with a bubbly, sociable personality that instantly puts others at ease. New to the massage world, she brings fresh energy and enthusiasm to every session. With her fit physique, gorgeous olive skin, and flowing brown hair, Petra creates a captivating and relaxing atmosphere. She takes pride in offering an unforgettable escape from the everyday, making her touch the perfect choice for anyone seeking a soothing and sensual experience.',
    new: true,
    bust_enhanced: false,
    hair_color: 'Brown',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/e378f6cf9a064426a222701d505ff6c4.webp',
      'https://clubdynasty.ca/media/users/images/5448dbf0b0044da2945e05907c28d326.webp',
      'https://clubdynasty.ca/media/users/images/44e34ad06908472685f29bb7f99a4de5.webp',
      'https://clubdynasty.ca/media/users/images/025093ad4df44e55a5ae8b5ffca93127.webp',
      'https://clubdynasty.ca/media/users/images/fa3d8306d1b2435a905aae0e12d45b99.webp',
      'https://clubdynasty.ca/media/users/images/34d44a3730d54d54bb65d70d2b2857a8.webp',
      'https://clubdynasty.ca/media/users/images/fbff8d8419ff4df98c757f983a03fb15.webp',
      'https://clubdynasty.ca/media/users/images/d1ae24cca742418ea09ecce4e4b04477.webp',
      'https://clubdynasty.ca/media/users/images/c76d0a66422541b78d87d8566afd9f1c.webp',
      'https://clubdynasty.ca/media/users/images/022f505f45c44b609e67573a8db15270.webp',
      'https://clubdynasty.ca/media/users/images/def8b6ca66f148c9b687cb3a6357bcdc.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/e378f6cf9a064426a222701d505ff6c4.webp',
      'https://clubdynasty.ca/media/users/thumbnails/5448dbf0b0044da2945e05907c28d326.webp',
      'https://clubdynasty.ca/media/users/thumbnails/44e34ad06908472685f29bb7f99a4de5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/025093ad4df44e55a5ae8b5ffca93127.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fa3d8306d1b2435a905aae0e12d45b99.webp',
      'https://clubdynasty.ca/media/users/thumbnails/34d44a3730d54d54bb65d70d2b2857a8.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fbff8d8419ff4df98c757f983a03fb15.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d1ae24cca742418ea09ecce4e4b04477.webp',
      'https://clubdynasty.ca/media/users/thumbnails/c76d0a66422541b78d87d8566afd9f1c.webp',
      'https://clubdynasty.ca/media/users/thumbnails/022f505f45c44b609e67573a8db15270.webp',
      'https://clubdynasty.ca/media/users/thumbnails/def8b6ca66f148c9b687cb3a6357bcdc.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [
      'Wed, 08, Jan 6:00 PM - 1:00 AM',
      'Fri, 10, Jan 5:00 PM - 11:00 PM',
    ],
  },
  {
    name: 'MasseuseTest',
    age: '22',
    background: '',
    language: '',
    technique: '',
    tattoos: null,
    height: '',
    measurements: '',
    interests: '',
    strength: '',
    vaccinated: false,
    id: 38140,
    services: '',
    image: null,
    vacation: false,
    survey_average: 0.0,
    survey_count: 0,
    updated: '2024-12-29T12:04:33.354637-05:00',
    bio: '',
    new: false,
    bust_enhanced: false,
    hair_color: '',
    profile_visible: false,
    allow_lingam: false,
    sensual90: true,
    images: [],
    thumbnails: [],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
  {
    name: 'Addison',
    age: '23',
    background: 'Turkish',
    language: 'English',
    technique: 'Sensual',
    tattoos: null,
    height: "5'4",
    measurements: '32C-24-32',
    interests:
      'Aviation, Hot Pilates, Nightlife, Swimming, Nature Hiking, Shopping',
    strength: '',
    vaccinated: false,
    id: 38624,
    services: 'Men ♂️',
    image:
      'https://clubdynasty.ca/media/users/images/1c4878fd5e814293a5fae04c39eb5c4d.webp',
    vacation: false,
    survey_average: 0.0,
    survey_count: 0,
    updated: '2025-01-06T17:17:26.546758-05:00',
    bio: 'Addison is a very petite, blonde, girl-next-door type with a warm and soft-hearted nature that instantly puts others at ease. Open-minded and equally comfortable being social or shy, she thrives on meeting new people and forming genuine bonds that foster positivity and comfort. With her compassionate spirit and great listening skills, Addison is a supportive friend and a true help to others, bringing a sense of passion and warmth to every interaction.',
    new: true,
    bust_enhanced: false,
    hair_color: 'Blonde',
    profile_visible: true,
    allow_lingam: false,
    sensual90: true,
    images: [
      'https://clubdynasty.ca/media/users/images/512fe8ec8d90453fb2965ef3384f62e7.webp',
      'https://clubdynasty.ca/media/users/images/286c9a3305ec440d8f8db05aa573e0c5.webp',
      'https://clubdynasty.ca/media/users/images/0cc351720ee0460897c0c380f2c63a60.webp',
      'https://clubdynasty.ca/media/users/images/95869562d2a74892a26dd6731a34f8e5.webp',
      'https://clubdynasty.ca/media/users/images/e059be60e11843278e45fd81eabb0de2.webp',
      'https://clubdynasty.ca/media/users/images/d30065444da445d78205c2c810f00250.webp',
      'https://clubdynasty.ca/media/users/images/fddcd5883aca4c73a5765a5fb806f183.webp',
      'https://clubdynasty.ca/media/users/images/1c4878fd5e814293a5fae04c39eb5c4d.webp',
    ],
    thumbnails: [
      'https://clubdynasty.ca/media/users/thumbnails/512fe8ec8d90453fb2965ef3384f62e7.webp',
      'https://clubdynasty.ca/media/users/thumbnails/286c9a3305ec440d8f8db05aa573e0c5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/0cc351720ee0460897c0c380f2c63a60.webp',
      'https://clubdynasty.ca/media/users/thumbnails/95869562d2a74892a26dd6731a34f8e5.webp',
      'https://clubdynasty.ca/media/users/thumbnails/e059be60e11843278e45fd81eabb0de2.webp',
      'https://clubdynasty.ca/media/users/thumbnails/d30065444da445d78205c2c810f00250.webp',
      'https://clubdynasty.ca/media/users/thumbnails/fddcd5883aca4c73a5765a5fb806f183.webp',
      'https://clubdynasty.ca/media/users/thumbnails/1c4878fd5e814293a5fae04c39eb5c4d.webp',
    ],
    tv_images: [],
    tv_thumbnails: [],
    foot_massage: false,
    ma_images: [],
    ma_thumbnails: [],
    availabilities: [],
  },
];
