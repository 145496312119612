import React from 'react';
import Conversations from '../components/Conversations';
import { Box } from '@mui/material';
import ConversationsSource from '../components/ConversationsSource';

const ConversationScreen = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: {
            xs: '64px',
            sm: '70px',
          },
          height: '100vh',
        }}
      >
        {/* <Conversations /> */}
        <ConversationsSource />
      </Box>
    </Box>
  );
};

export default ConversationScreen;
