import React from 'react';
import { Avatar, Stack, Box, Typography, styled, Badge } from '@mui/material';
import { masseusesList } from '../data/masseusesList';

function ConversationOnline() {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const avatars = [
    { id: 1, name: 'Alice', src: 'https://i.pravatar.cc/150?img=1' },
    { id: 2, name: 'Bob', src: 'https://i.pravatar.cc/150?img=2' },
    { id: 3, name: 'Charlie', src: 'https://i.pravatar.cc/150?img=3' },
    { id: 4, name: 'Diana', src: 'https://i.pravatar.cc/150?img=4' },
  ];

  return (
    <Box
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        p: 2,
      }}
    >
      <Stack direction="row" spacing={2}>
        {masseusesList.map(
          (masseuse) =>
            masseuse.technique !== 'Shiatsu' && (
              <Box key={masseuse.id} textAlign="center">
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <Avatar
                    alt={masseuse.name}
                    src={masseuse.image}
                    sx={{ width: 56, height: 56 }}
                  />
                </StyledBadge>
                <Typography variant="caption" display="block">
                  {masseuse.name}
                </Typography>
              </Box>
            )
        )}
      </Stack>
    </Box>
  );
}

export default ConversationOnline;
