import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CD_LOGO from '../img/club-dynasty-logo.png';
import RECEIPT_HEADER_BG from '../img/receipt_header_bg_ma.jpg';
import { format } from 'date-fns';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

export default function ReceiptPDF({ session, sessionReceiptDetails }) {
  const userDetails = useSelector((state) => state.userDetails);
  const { user, loading: loadingDetails } = userDetails;

  const pdfRef = useRef(null);

  const handleDownload = async () => {
    const content = pdfRef.current;

    // Convert the HTML to a canvas
    const canvas = await html2canvas(content, {
      scale: window.devicePixelRatio, // Scale based on device pixel ratio
      useCORS: true, // Handle cross-origin images
    });

    // Convert the canvas to an image
    const imgData = canvas.toDataURL('image/png');

    // Create a new PDF and add the canvas as an image
    const doc = new jsPDF({
      unit: 'mm',
      format: 'letter',
    });

    const pdfWidth = 215.9; // Letter width in mm
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Scale height proportionally

    doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    doc.save(`CD_RECEIPT_${sessionReceiptDetails?.transaction_key}.pdf`);
  };

  const {
    counter_tip_card,
    counter_tip_cash,
    fee_card,
    fee_cash,
    masseuse1_tip_card,
    masseuse1_tip_cash,
    masseuse2_tip_card,
    masseuse2_tip_cash,
    masseuse3_tip_card,
    masseuse3_tip_cash,
    masseuse4_tip_card,
    masseuse4_tip_cash,
  } = session;

  let total = sessionReceiptDetails
    ? sessionReceiptDetails.total
    : counter_tip_card +
      counter_tip_cash +
      fee_card +
      fee_cash +
      masseuse1_tip_card +
      masseuse1_tip_cash +
      masseuse2_tip_card +
      masseuse2_tip_cash +
      masseuse3_tip_card +
      masseuse3_tip_cash +
      masseuse4_tip_card +
      masseuse4_tip_cash;

  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <div style={{ marginLeft: '-16px' }}>
        <Button
          variant="contained"
          disableElevation
          startIcon={<SaveAltIcon />}
          onClick={handleDownload}
        >
          Download
        </Button>
      </div>

      <Box mt={3}>
        <Grid
          container
          spacing={2}
          ref={pdfRef}
          className="receipt-content"
          style={{
            minWidth: '215.9mm',
            maxWidth: '215.9mm',
            minHeight: '279.4mm',
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                width: '215.9mm',
                background: `url(${RECEIPT_HEADER_BG}) no-repeat center center`,
                backgroundSize: 'cover',
              }}
            >
              <Grid item xs={6} p={2}>
                <Link to="http://www.clubdynasty.ca/" target="_blank">
                  <img
                    src={CD_LOGO}
                    alt="Club Dynasty Spa"
                    width={'120'}
                    height={'auto'}
                  />
                </Link>
                <List
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  <Link
                    to="https://maps.app.goo.gl/enntJ3Y2ttVyTbfW8"
                    target="_blank"
                  >
                    <ListItem
                      dense={true}
                      sx={{
                        padding: '0',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '30px',
                        }}
                      >
                        <LocationOnIcon color="white" />
                      </ListItemIcon>
                      <ListItemText
                        primary="7850 Woodbine Avenue, Markham"
                        sx={{
                          color: '#FFF',
                        }}
                      />
                    </ListItem>
                  </Link>
                  <Link to="tel:905-477-0099" target="_blank">
                    <ListItem
                      dense={true}
                      sx={{
                        padding: '0',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '30px',
                        }}
                      >
                        <LocalPhoneIcon color="white" />
                      </ListItemIcon>
                      <ListItemText
                        primary="905-477-0099"
                        sx={{
                          color: '#FFF',
                        }}
                      />
                    </ListItem>
                  </Link>
                  <Link to="http://www.clubdynasty.ca/" target="_blank">
                    <ListItem
                      dense={true}
                      sx={{
                        padding: '0',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: '30px',
                        }}
                      >
                        <LanguageIcon color="white" />
                      </ListItemIcon>
                      <ListItemText
                        primary="www.clubdynasty.ca"
                        sx={{
                          color: '#FFF',
                        }}
                      />
                    </ListItem>
                  </Link>
                </List>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: '#FFF',
                    fontWeight: 'bold',
                  }}
                >
                  Club Dynasty Spa
                </Typography>
                <Typography variant="h1">RECEIPT</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Customer name:
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                marginTop: '10px',
              }}
            >
              {user?.name}
            </Typography>
            <table style={{ marginTop: '5px' }}>
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      marginTop: '5px',
                    }}
                  >
                    Phone:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      marginTop: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {user?.phone}
                    </span>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    Email:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {user?.email}
                    </span>
                  </Typography>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={4}>
            <table style={{ marginTop: '5px' }}>
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      marginTop: '5px',
                    }}
                  >
                    Date:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      marginTop: '5px',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {format(new Date(session.start_time), 'PP')}
                    </span>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    Invoice:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '400',
                      }}
                    >
                      {sessionReceiptDetails?.transaction_key}
                    </span>
                  </Typography>
                </td>
              </tr>
            </table>

            <div
              style={{
                backgroundColor: '#c3892b',
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'left',
                alignItems: 'center',
                gap: '10px',
                padding: '10px',
                marginTop: '10px',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '400',
                  color: '#FFF',
                }}
              >
                Total Amount
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#FFF',
                }}
              >
                ${total.toFixed(2)}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              padding: '40px',
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 700 }} variant="outlined">
                <TableHead
                  sx={{
                    backgroundColor: '#efefef',
                  }}
                >
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        SL
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Service
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Price
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Amount
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      1
                    </TableCell>
                    <TableCell align="left">Massage</TableCell>
                    <TableCell align="right">
                      $&nbsp;{sessionReceiptDetails.spa.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      $&nbsp;{sessionReceiptDetails.spa.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        border: '0',
                      }}
                    ></TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Discount
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      $&nbsp;{sessionReceiptDetails?.discount.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        border: '0',
                      }}
                    ></TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Tax
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      $&nbsp;{sessionReceiptDetails?.tax.toFixed(2)}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        border: '0',
                      }}
                    ></TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Tip
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      $&nbsp;{sessionReceiptDetails?.tip.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        border: '0',
                      }}
                    ></TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        $&nbsp;{total.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              padding: '0px !important',
            }}
          >
            <div
              style={{
                backgroundColor: '#c3892b',
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                padding: '10px',
                marginTop: '10px',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: '#FFF',
                }}
              >
                Payment Method
              </Typography>
            </div>
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                marginTop: '5px',
              }}
            >
              {sessionReceiptDetails &&
                sessionReceiptDetails.payment_methods.map(
                  (payment_method, index) =>
                    `${payment_method} ${
                      index < sessionReceiptDetails.payment_methods.length - 1
                        ? ', '
                        : ''
                    }`
                )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: '40px',
              marginBottom: '40px',
              padding: '0px !important',
            }}
          >
            <div
              style={{
                borderBottom: '3px solid #c3892b',
              }}
            ></div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
