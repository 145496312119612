import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Suspense, lazy, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import './App.css';
import { logout } from './actions/userActions';
import Footer from './components/Footer';
import ResponsiveAppBar from './components/Header';
import theme from './components/Theme';
import { GA_TRACKING_ID } from './constants/appConstants';
import AppointmentBookingScreen from './screens/AppointmentBookingScreen.js';
import LoadingIndicatorBackdrop from './loadingIndicators/AvailableMasseusesTodayLI.js';
import EmailVerifyScreen from './screens/EmailVerifyScreen';
// import FeedScreen from './screens/FeedScreen.js';
import FeedbackScreen from './screens/FeedbackScreen';
import GuestNotificationScreen from './screens/GuestNotificationScreen';
import LoginScreen from './screens/LoginScreen';
import MasseuseDetailsScreen from './screens/MasseuseDetailsScreen';
// import MasseuseScreen from './screens/MasseuseScreen';
import PopupManager from './components/PopupManager.jsx';
import MorphScreen from './screens/MorphScreen.js';
import NotificationListScreen from './screens/NotificationListScreen';
import PhoneVerifyScreen from './screens/PhoneVerifyScreen';
import PreferencesScreen from './screens/PreferencesScreen.js';
import ProfilePasswordEditScreen from './screens/ProfilePasswordEditScreen';
import ProfileScreen from './screens/ProfileScreen';
import ProfileScreenNew from './screens/ProfileScreenNew';
import ProfileUpdateScreen from './screens/ProfileUpdateScreen';
import RegisterScreen from './screens/RegisterScreen';
import ResetPasswordConfirmScreen from './screens/ResetPasswordConfirmScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import ResetPasswordVerifyScreen from './screens/ResetPasswordVerifyScreen';
import ReviewUsScreen from './screens/ReviewUsScreen.js';
import RewardsScreen from './screens/RewardsScreen';
import ScheduleScreen from './screens/ScheduleScreen.js';
import SessionScreen from './screens/SessionScreen.js';
import SurveyScreen from './screens/SurveyScreen';
import TransactionListScreen from './screens/TransactionListScreen';
import isTokenValid from './utils/isTokenValid';
import setAuthToken from './utils/setAuthToken';
import EmailSurveyScreen from './screens/EmailSurveyScreen.jsx';
import CookieConsent from './components/CookieConsent.jsx';
import { useCookies } from 'react-cookie';
import ServicesRatesScreen from './screens/ServicesRatesScreen.jsx';
import ThankYouSurveyScreen from './screens/ThankYouSurveyScreen.js';
import ConversationScreen from './screens/ConversationScreen.jsx';
import LoginScreenRedesign from './screens/LoginScreenRedesign.jsx';

const AvailableMasseusesToday = lazy(() =>
  import('./screens/AvailableMasseusesToday')
);

const FeedScreen = lazy(() => import('./screens/FeedScreen'));

const MasseuseScreen = lazy(() => import('./screens/MasseuseScreen'));

ReactGA.initialize(GA_TRACKING_ID);

if (localStorage.userInfo) {
  setAuthToken(JSON.parse(localStorage.userInfo).token);
}

function App() {
  const dispatch = useDispatch();
  const queryClient = new QueryClient();

  // const [modalOpen, setModalOpen] = useState(false);
  const [redirect, setRedirect] = useState('/feed');
  const [logOutClicked, setLogOutClicked] = useState(false);
  const [cookies] = useCookies(['cookieConsent']);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading } = userLogin;

  // const popupDetails = useSelector((state) => state.popupDetails);
  // const { popup, loadingPopup } = popupDetails;

  useEffect(() => {
    if (localStorage.userInfo) {
      setAuthToken(JSON.parse(localStorage.userInfo).token);
    }

    const queryParams = new URLSearchParams(window.location.search);
    const redirectParam = queryParams.get('redirect');
    if (redirectParam) {
      setRedirect(redirectParam);
    }
  }, []);

  useEffect(() => {
    if (!isTokenValid()) {
      dispatch(logout());
    }

    if (localStorage.userInfo) {
      setAuthToken(JSON.parse(localStorage.userInfo).token);
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: `Viewed page: ${
        window.location.pathname + window.location.search
      }`,
    });
  }, []);

  /*
  useEffect(() => {
    // Show popup every week if the user's email or phone is not verified
    const lastPopUpTime = localStorage.getItem('popUpTime'); // Retrieve the last popup time

    if (
      userInfo &&
      userInfo.user &&
      !(
        userInfo.user.email_verified === true ||
        userInfo.user.phone_verified === true
      ) &&
      shouldShowPopup(lastPopUpTime)
    ) {
      dispatch(getPopupDetails(setModalOpen));
      localStorage.setItem('popUpTime', new Date().toISOString());
    }
  }, [userInfo, dispatch, setModalOpen]);
  */

  const requestNotificationPermission = async () => {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.error('Notification permission received.');
        // const registration = await navigator.serviceWorker.ready;
        // const subscription = await registration.pushManager.subscribe({
        //   userVisibleOnly: true,
        //   applicationServerKey: '<YOUR_PUBLIC_VAPID_KEY>', // Replace with your VAPID public key
        // });
        // console.log('Push Subscription:', subscription);
        // // Send this subscription object to your backend
        // await fetch('https://your-backend-url/subscribe/', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(subscription),
        // });
      } else {
        console.error('Notification permission denied.');
      }
    } else {
      console.error('Push notifications are not supported in this browser.');
    }
  };

  useEffect(() => {
    requestNotificationPermission();
    if (process.env.NODE_ENV === 'production') {
      const handleContextmenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextmenu);
      return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu);
      };
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {/* {userInfo && (
          <Popup popup={popup} open={modalOpen} setOpen={setModalOpen} />
        )} */}
        <Router>
          <PopupManager />
          {userInfo && (
            <ResponsiveAppBar
              theme={theme}
              setLogOutClicked={setLogOutClicked}
            />
          )}
          <Routes>
            <Route path="/survey" element={<EmailSurveyScreen />}></Route>
            <Route
              path="/survey/thank-you"
              element={<ThankYouSurveyScreen />}
            ></Route>
            {!userInfo && (
              <>
                {/* <Route path="/login" element={<LoginScreen />}></Route>{' '} */}
                <Route path="/login" element={<LoginScreenRedesign />}></Route>{' '}
                <Route path="/morph" element={<MorphScreen />}></Route>{' '}
                {/* <Route path="/survey" element={<EmailSurveyScreen />}></Route>
                <Route
                  path="/survey/thank-you"
                  element={<ThankYouSurveyScreen />}
                ></Route> */}
                {/* TV Slider Start */}
                <Route
                  path="/available"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <AvailableMasseusesToday />
                    </Suspense>
                  }
                ></Route>
                {/* TV Slider End */}
                {/* Spa Track Start */}
                <Route
                  path="/spatrack"
                  element={<GuestNotificationScreen />}
                ></Route>
                {/* Spa Track End */}
                <Route path="/register" element={<RegisterScreen />}></Route>
                <Route path="/reset" element={<ResetPasswordScreen />}></Route>
                <Route
                  path="/reset/verify"
                  element={<ResetPasswordVerifyScreen />}
                ></Route>
                <Route
                  path="/reset/verify?"
                  element={<ResetPasswordVerifyScreen />}
                ></Route>
                <Route
                  path="/reset/confirm"
                  element={<ResetPasswordConfirmScreen />}
                ></Route>
                <Route
                  path="/reset/confirm?"
                  element={<ResetPasswordConfirmScreen />}
                ></Route>
              </>
            )}

            {userInfo && (
              <>
                {/* <Route
                  path="/partner-offers"
                  element={<PartnerOfferScreen />}
                ></Route> */}
                <Route path="/review-us" element={<ReviewUsScreen />}></Route>
                <Route
                  path="/masseuses"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <MasseuseScreen />
                    </Suspense>
                  }
                ></Route>

                <Route path="/" element={<RewardsScreen />}></Route>
                <Route path="/media"></Route>
                <Route
                  path="/masseuse/:id"
                  element={<MasseuseDetailsScreen />}
                ></Route>
                <Route path="/sessions" element={<SessionScreen />}></Route>
                <Route
                  path="/sessions/survey/:id"
                  element={<SurveyScreen />}
                ></Route>
                {/* <Route path="/sessions" element={<SessionScreenOld />}></Route>
                <Route path="/sessions?" element={<SessionScreenOld />}></Route> */}
                {/* <Route
                path="/sessions/:page"
                element={<SessionScreen />}
                exact
              ></Route> */}
                <Route path="/profile" element={<ProfileScreenNew />}></Route>
                <Route path="/profile-old" element={<ProfileScreen />}></Route>
                <Route
                  path="/profile/email_confirm"
                  element={<EmailVerifyScreen />}
                ></Route>
                <Route
                  path="/profile/email_confirm?"
                  element={<EmailVerifyScreen />}
                ></Route>
                <Route
                  path="/profile/phone_confirm"
                  element={<PhoneVerifyScreen />}
                ></Route>
                <Route
                  path="/profile/phone_confirm?"
                  element={<PhoneVerifyScreen />}
                ></Route>
                <Route
                  path="/profile/update"
                  element={<ProfileUpdateScreen />}
                ></Route>
                <Route
                  path="/profile/change-password"
                  element={<ProfilePasswordEditScreen />}
                ></Route>
                <Route
                  path="/transactions"
                  element={<TransactionListScreen />}
                ></Route>
                <Route
                  path="/notifications"
                  element={<NotificationListScreen />}
                ></Route>
                <Route path="/feedbacks" element={<FeedbackScreen />}></Route>
                <Route
                  path="/preferences"
                  element={<PreferencesScreen />}
                ></Route>
                <Route
                  path="/feed"
                  element={
                    <Suspense fallback={<LoadingIndicatorBackdrop />}>
                      <FeedScreen />
                    </Suspense>
                  }
                ></Route>

                <Route path="/schedule" element={<ScheduleScreen />}></Route>
                <Route
                  path="/services-rates"
                  element={<ServicesRatesScreen />}
                ></Route>
                <Route
                  path="/appointment"
                  element={<AppointmentBookingScreen />}
                ></Route>
                <Route
                  path="/conversations"
                  element={<ConversationScreen />}
                ></Route>
              </>
            )}

            <Route
              path="*"
              element={
                <Navigate
                  to={
                    userInfo && userInfo.user?.fresh_login
                      ? '/profile/update?fresh_login=' +
                        userInfo.user?.fresh_login
                      : userInfo && !userInfo.user?.fresh_login
                      ? redirect
                      : logOutClicked || window.location.pathname === '/'
                      ? '/login'
                      : `/login?redirect=${window.location.pathname}`
                  }
                />
              }
            />
          </Routes>
          {userInfo && <Footer theme={theme} />}
          {
            // !cookies.cookieConsent && (
            //   <CookieConsent cookieConsent={cookies.cookieConsent} />
            // )
          }
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default Sentry.withProfiler(App);
